import log from 'loglevel';
import { defineStore } from 'pinia';
import { v4 as uuid } from 'uuid';
import Vue, { computed, ComputedRef, Ref, ref, watch } from 'vue';

import { CallMessage } from '@/data/datatypes/chat/CallMessage';
import { ChatMessage } from '@/data/datatypes/chat/ChatMessage';
import { Member, MemberRole } from '@/data/datatypes/Member';
import { PublishedWorkspaceDetails } from '@/data/datatypes/publishing/publish.types';
import Tenant from '@/data/datatypes/Tenant';
import {
  ActivityType,
  CallMessageType,
  ChatAccessLevel,
  EntryComment,
  GuestTrack,
  Track,
  TrackActivity,
  TrackBatch,
  TrackDetails,
  TrackType,
  TrackWebhook,
} from '@/data/datatypes/Track';
import { isTemplateOrChildOfTemplate } from '@/data/helpers/TrackHelper';
import { extractInitialsFromEmail } from '@/data/helpers/UserHelper';
import ServerData from '@/data/ServerData';
import DataWorker from '@/data/storage/DataWorker';
import { MiniAppView, TaskViewType } from '@/data/tasks/TaskView';
import pinia from '@/stores';
import { useMeetingStore } from '@/stores/Meeting';
import { useRouteStore } from '@/stores/Route';
import { asRecord, setOrPatchObject } from '@/stores/StoreHelper';
import { DEFAULT_COLLABORATION_APP_ID, useTasksStore } from '@/stores/Tasks';
import { useTenantsStore } from '@/stores/Tenants';
import { useTrackMembersStore } from '@/stores/TrackMembers';
import {
  NotificationsState,
  TransferPayload,
  TransferTrackPayload,
  WorkflowAddPayload,
  WorkflowDeletePayload,
  WorkflowInvokePayload,
} from '@/stores/Tracks.types';
import { useUIStateStore } from '@/stores/UIState';
import { useUserStore } from '@/stores/User';
import { TrackPollResponse } from '@/workers/datatypes/TrackPollResponse';
import { NotificationItem } from '@/workers/modules/OnlineStatusWorkerModule';

export const useTracksStore = defineStore('Tracks', () => {
  const trackMembersStore = useTrackMembersStore(pinia);
  const tenantsStore = useTenantsStore(pinia);
  const meetingStore = useMeetingStore(pinia);
  const userStore = useUserStore(pinia);

  const lastTrackDataUpdate: Ref<number> = ref(0);
  const tracks: Ref<Record<string, Track>> = ref({});
  const tracksByTenantId: Ref<Record<string, Track[]>> = ref({});
  const guestTracks: Ref<Record<string, Track>> = ref({}); // trackID -> track
  const lastGuestTrackUpdate: Ref<number> = ref(0);
  const publishDetailsForActiveTrack: Ref<PublishedWorkspaceDetails | null> = ref(null);
  const incomingCallMessage: Ref<CallMessage | null> = ref(null);
  const recentlyViewedTrackIds: Ref<string[]> = ref([]);
  const inProgressRequests: Ref<Record<string, Promise<Track>> | Record<string, Promise<void>>> = ref({});
  const currentlyDisplayedChatSummaryTrack: Ref<Track | null> = ref(null);

  /**
   * activeTrackId is a ref as when this was a computed driven off the route it was recalculating when the route
   * changed. The route changed when tabs were switched for example. That was then causing a big chain of track and
   * data set / data source recalculations which was causing performance issues. See issue #12128.
   */
  const activeTrackId: Ref<string | null> = ref(null);

  const liveActiveTrackId: ComputedRef<string | null> = computed(() => {
    const routeStore = useRouteStore(pinia);
    return routeStore.route?.params.trackId || routeStore.route?.params.privateChatId || null;
  });

  watch(liveActiveTrackId, (newVal: string | null) => {
    if (activeTrackId.value !== newVal) {
      activeTrackId.value = newVal;
    }
  }, { immediate: true });

  const activeAppId: ComputedRef<string | undefined> = computed(() => {
    const routeStore = useRouteStore(pinia);
    return routeStore.route?.params.tenantAppId;
  });

  const trackTemplatesForActiveApp: ComputedRef<Track[]> = computed(() => {
    return Object.values(tracks.value).filter((track) => {
      if (track.type === TrackType.TEMPLATE) {
        if (activeAppId.value === DEFAULT_COLLABORATION_APP_ID) {
          return !track.owningMiniAppId;
        } else {
          return track.owningMiniAppId === activeAppId.value;
        }
      }
      return false;
    });
  });

  const activeTrackTitleInitials: ComputedRef<string | undefined> = computed(() => {
    if (activeTrack.value && activeTrack.value.title) {
      return extractInitialsFromEmail(activeTrack.value.title);
    }
    return undefined;
  });

  const trackOwnerEmail: ComputedRef<string | undefined> = computed(() => {
    const membersForActiveTrack = trackMembersStore.membersForActiveTrack;
    const userDetails: Member | undefined = membersForActiveTrack.find((current: Member) => {
      return activeTrack.value && current.userId === activeTrack.value.owner;
    });
    if (userDetails) {
      return userDetails.email;
    }
    return undefined;
  });

  const trackCreationDate: ComputedRef<string> = computed(() => {
    let date: Date = new Date();
    if (activeTrack.value && activeTrack.value.created) {
      date = new Date(activeTrack.value.created);
    }
    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long', month: 'long', day: 'numeric', year: 'numeric',
    };
    return date.toLocaleDateString(undefined, dateOptions);
  });

  const allTracks: ComputedRef<Track[]> = computed(() => {
    return Object.values(tracks.value);
  });

  const allPrivateChatTracks: ComputedRef<Track[]> = computed(() => {
    return allTracks.value.filter((track) => {
      return [TrackType.PRIVATE_CHAT].includes(track.type);
    });
  });

  /**
   * Checks the list of guest tracks for a track with the provided `trackId`
   */
  const guestTrack: ComputedRef<(trackId: string) => Track | undefined> = computed(() => {
    return (trackId: string) => guestTracks.value[trackId];
  });

  const activeTrack: ComputedRef<Track | undefined> = computed(() => {
    if (activeTrackId.value) {
      // See if it's a track we are *not* a guest of
      if (tracks.value && tracks.value[activeTrackId.value]) {
        return tracks.value[activeTrackId.value];
      }

      // If not, see if it's a track we *are* a guest of
      if (guestTracks.value[activeTrackId.value]) {
        return guestTracks.value[activeTrackId.value];
      }
    }
    return undefined;
  });

  const activeTrackParent: ComputedRef<Track | undefined> = computed(() => {
    if (!activeTrack.value?.parentTrackId) {
      return;
    }
    const id: string = activeTrack.value.parentTrackId;
    let track: Track | undefined = tracks.value?.[id];
    if (!track) {
      track = guestTracks.value?.[id];
    }
    return track;
  });

  const recentlyUpdatedTracks: ComputedRef<Track[]> = computed(() => {
    let tracks: Track[] = allTracks.value.slice();
    tracks = tracks.filter((track) => {
      return [TrackType.STANDARD, TrackType.BREAKOUT, TrackType.CHANNEL_CHAT].includes(track.type);
    });
    tracks.sort((a: Track, b: Track) => {
      const aLastUpdated = a?.lastUpdated ?? 0;
      const bLastUpdated = b?.lastUpdated ?? 0;
      return bLastUpdated - aLastUpdated;
    });

    return tracks;
  });

  // Return all private or track chats, regardless of whether a message has been sent, sorted by last message
  // date (or created date, if there are no messages)
  const allTracksWithChats: ComputedRef<Track[]> = computed(() => {
    return allTracks.value
      .filter(isTrackWithChat)
      .sort(byLastMessageDateOrCreatedDate);
  });

  // Return all private or track chats which have a message and are not hidden for the current user,
  // sorted by last message date
  const chatsByMostRecentMessage: ComputedRef<Track[]> = computed(() => {
    return allTracks.value
      .filter(hasChatMessage)
      .filter((track) => !isChatHiddenForCurrentUser(track))
      .sort(byLastMessageDate);
  });

  const currentUserCanViewActiveTrackChat: ComputedRef<boolean | null> = computed(() => {
    return activeTrackId.value ? currentUserCanViewChat(guestTracks.value, tracks.value, activeTrackId.value) : null;
  });

  const currentUserCanViewMeetingChat: ComputedRef<boolean | null> = computed(() => {
    return meetingTrack.value ? currentUserCanViewChat(guestTracks.value, tracks.value, meetingTrack.value.id) : null;
  });

  const currentUserCanSendActiveTrackChat: ComputedRef<boolean> = computed(() => {
    return activeTrackId.value ? currentUserCanSendChat(guestTracks.value, tracks.value, activeTrackId.value) : false;
  });

  const currentUserCanSendMeetingChat: ComputedRef<boolean> = computed(() => {
    return meetingTrack.value ? currentUserCanSendChat(guestTracks.value, tracks.value, meetingTrack.value.id) : false;
  });

  const trackNotifications: ComputedRef<TrackActivity[]> = computed(() => {
    const result: TrackActivity[] = [];
    const currentUserId: string | null = userStore.currentUserId;
    if (tracks.value) {
      const sortedTracks = Object.values(tracks.value)
        .filter((track: Track) => (track.type !== TrackType.SCHEDULED_MEETING))
        .sort((track1: Track, track2: Track) => (track1.title ?? '').localeCompare(track2.title ?? ''));
      for (const track of sortedTracks) {
        if (track.activity) {
          for (const activity of track.activity) {
            // if we directly use activity it will be persisted and circular loop issue occurs
            const trackActivity: TrackActivity = { ...activity };
            activity.track = track;
            trackActivity.track = track;
            trackActivity.canDelete = true;

            result.push(trackActivity);
          }
        }

        if (track.numUnseenChatMessages && track.numUnseenChatMessages > 0) {
          const a: TrackActivity = {
            type: ActivityType.CHAT_MESSAGE,
            track,
            userId: track.lastChatMessage && track.lastChatMessage.senderId ? track.lastChatMessage.senderId : '',
            userName: track.lastChatMessage ? track.lastChatMessage.senderName : '',
            message: track.lastChatMessage ? track.lastChatMessage.chatMessageContent.plain
              : 'There is a new chat message on this workspace',
            date: track.lastChatMessage ? new Date(track.lastChatMessage.date).getTime() : new Date(0).getTime(),
            canDelete: true,
          };
          result.push(a);
        }

        if (currentUserId && track.pendingTransferTarget === currentUserId) {
          const a: TrackActivity = {
            type: ActivityType.TRACK_TRANSFER_REQUEST,
            track,
            userId: '',
            message: 'You need to respond to a workspace transfer request',
            date: new Date().getTime(), // these will always be top of the list
            canDelete: false,
          };
          result.push(a);
        }

        // TODO - actions, anything else?
      }
    }
    return result;
  });

  /**
   * Track activities grouped by common user/trackid/tableid (where applicable)
   */
  const groupedTrackActivities: ComputedRef<TrackActivity[][]> = computed(() => {
    const result: TrackActivity[][] = [];
    const grouped: Record<string, Record<string, Record<string, TrackActivity[]>>> = {};
    for (const activity of trackNotifications.value) {
      const trackId = activity.track.id;
      const userId = activity.userId;
      const tableId = activity.tableId;
      if (trackId && userId && tableId && activity.type === ActivityType.NON_TASK_RECORD_MODIFIED) {
        if (!grouped[trackId]) {
          grouped[trackId] = {};
        }
        if (!grouped[trackId][userId]) {
          grouped[trackId][userId] = {};
        }
        if (!grouped[trackId][userId][tableId]) {
          grouped[trackId][userId][tableId] = [];
          result.push(grouped[trackId][userId][tableId]);
        }
        grouped[trackId][userId][tableId].push(activity);
      } else {
        result.push([activity]);
      }
    }
    return result;
  });

  const notificationsState: ComputedRef<NotificationsState> = computed(() => {
    if (trackNotifications.value.length === 0) {
      return NotificationsState.NONE;
    }

    for (const notification of trackNotifications.value) {
      if (notification.track.type === TrackType.PRIVATE_CHAT) {
        return NotificationsState.DIRECT;
      }

      // TODO - add @mentions in as directed
    }

    return NotificationsState.GENERAL;
  });

  const trackNotificationCountByTrack: ComputedRef<Record<string, number>> = computed(() => {
    // Group by track/user/table
    // filter out activity on tables which are not viewable
    const tasksStore = useTasksStore(pinia);
    const views: MiniAppView[] = tasksStore.allMiniAppViews;
    const viewableViewTypes = [TaskViewType.BASE, TaskViewType.CARD, TaskViewType.KANBAN];
    const countByTrack = groupedTrackActivities.value.reduce(
      (map: {[trackId: string]: number}, activities: TrackActivity[]) => {
        const activity = activities[0];
        if (map[activity.track.id] == null) {
          map[activity.track.id] = 0;
        }
        if (
          views.some(view => view.tableId === activity.tableId &&
            viewableViewTypes.includes(view.type))) {
          map[activity.track.id]++;
        }
        return map;
      }, {});
    return countByTrack;
  });

  const meetingTrack: ComputedRef<Track | undefined> = computed(() => {
    const meetingTrackId: string | null = meetingStore.meetingTrackId;
    if (meetingTrackId) {
      return userStore.isGuestUser || userStore.isGuestMember(meetingTrackId)
        ? guestTracks.value[meetingTrackId] : tracks.value[meetingTrackId];
    }
    return undefined;
  });

  const incomingCall: ComputedRef<CallMessage | null> = computed(() => {
    return incomingCallMessage.value;
  });

  const recentlyViewedTracks: ComputedRef<Track[]> = computed(() => {
    const toReturn = [];
    for (const trackId of recentlyViewedTrackIds.value) {
      const foundTrack = tracks.value[trackId];
      if (foundTrack) {
        toReturn.push(foundTrack);
      }
    }
    return toReturn;
  });

  /**
   * Returns `true` if the currently-active track should be treated as a template.
   * The track itself could have a type of TEMPLATE, or it could be a breakout room within a track of type TEMPLATE.
   */
  const isActiveTrackTemplateOrChildOfTemplate: ComputedRef<boolean | null> = computed(() => {
    if (activeTrack.value) {
      return isTemplateOrChildOfTemplate(activeTrack.value, tracks.value);
    } else {
      // If the tracks have not been loaded yet then we can't say whether it's a template track or not.
      return null;
    }
  });

  function setRecentlyViewedTracks(details: { recentlyViewedTrackIds: string[] }): void {
    recentlyViewedTrackIds.value = details.recentlyViewedTrackIds;
  }

  function clearIncomingCallMessage(details: {callMessage: CallMessage}): void {
    if (incomingCallMessage.value && incomingCallMessage.value.trackId === details.callMessage.trackId &&
      incomingCallMessage.value.userId === details.callMessage.userId) {
      // Remove incoming call if it is the same one requested
      incomingCallMessage.value = null;
    }
  }

  function setCallMessage(details: {callMessage: CallMessage; callMessageType: CallMessageType}): void {
    const currentUserId: string | null = userStore.currentUserId;
    switch (details.callMessageType) {
      case CallMessageType.CALL:
        if ((!incomingCallMessage.value) && (meetingStore.meetingTrackId !== details.callMessage.trackId)) {
          // Set an incoming call if there isn't currently one and the meeting invite isn't to the meeting we are in.
          incomingCallMessage.value = details.callMessage;
        }
        break;
      case CallMessageType.CALL_ANSWER:
        if (incomingCallMessage.value && incomingCallMessage.value.trackId === details.callMessage.trackId &&
          details.callMessage.userId === currentUserId) {
          // Current user has already answered call - ensure direct message call popup is closed
          incomingCallMessage.value = null;
        }
        break;
      case CallMessageType.CALL_REJECT:
        if (incomingCallMessage.value && incomingCallMessage.value.trackId === details.callMessage.trackId &&
          details.callMessage.userId === currentUserId) {
          // Current user has already rejected call - ensure direct message call popup is closed
          incomingCallMessage.value = null;
        }
        break;
      case CallMessageType.CALL_CANCEL:
        if (incomingCallMessage.value && incomingCallMessage.value.trackId === details.callMessage.trackId &&
          incomingCallMessage.value.userId === details.callMessage.userId) {
          // If we currently have an incoming call and the caller cancels that same call then remove it
          incomingCallMessage.value = null;
        }
        break;
    }
  }

  // Overwrites the last chat message in a track with the specified chat message. This is called when
  // new chat messages are received by the chat worker
  async function setLastChatMessageInTrack(details: { trackId: string; newestMessage: ChatMessage;
      numNewMessages: number }): Promise<void> {
    const trackToUpdate: Track = tracks.value[details.trackId];
    if (trackToUpdate) {
      if (trackToUpdate.lastChatMessage?.id !== details.newestMessage.id) {
        if ((!trackToUpdate.lastChatMessage) || (trackToUpdate.lastChatMessage?.date < details.newestMessage.date)) {
          // Make sure we show a notification indicator for the new message immediately.
          // Ideally we'd check whether the chat itself is being viewed, rather than just the active track.
          const uiStateStore = useUIStateStore();
          const tabActive = uiStateStore.tabVisible;
          if (!tabActive || activeTrackId.value !== details.trackId) {
            trackToUpdate.numUnseenChatMessages = trackToUpdate.numUnseenChatMessages == null ? details.numNewMessages
              : trackToUpdate.numUnseenChatMessages + details.numNewMessages;
          }
        }
        trackToUpdate.lastChatMessage = details.newestMessage;
        // Update the track in the indexedDB and make sure the track poll doesn't overwrite it with out-of-date data
        const request: Promise<void> = new Promise((resolve, reject) => {
          DataWorker.instance().dispatch('Tracks/updateTrackInIndexedDB', trackToUpdate).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
        return await performMonitoredTracksRequest(request);
      }
    }
  }

  function setTracks(tracksToSet: Track[]): void {
    for (const track of tracksToSet) {
      setOrPatchObject(tracks.value, track.id, asRecord(track));
      const targetTrack: Track = tracks.value[track.id];

      let tracksForTenant: Track[] = tracksByTenantId.value[targetTrack.tenantId];
      if (!tracksForTenant) {
        tracksForTenant = [];
        Vue.set(tracksByTenantId.value, track.tenantId, tracksForTenant);
      }
      if (tracksForTenant.findIndex((current: Track) => current.id === targetTrack.id) === -1) {
        tracksForTenant.push(targetTrack);
      }
    }
  }

  function setTrack(track: Track): void {
    setOrPatchObject(tracks.value, track.id, asRecord(track));
  }

  function removeTrack(track: Track): void {
    if (track) {
      Vue.delete(tracks.value, track.id);
      removeTrackFromTenant({ trackId: track.id, tenantId: track.tenantId });
    }
  }

  function addTrackToTenant(details: { track: Track; tenantId: string }): void {
    let tracksForTenant = tracksByTenantId.value[details.tenantId];
    if (!tracksForTenant) {
      tracksForTenant = [];
      Vue.set(tracksByTenantId.value, details.tenantId, tracksForTenant);
    }
    const existingIndex = tracksForTenant.findIndex((current) => current.id === details.track.id);
    if (existingIndex === -1) {
      tracksForTenant.push(details.track);
    }
  }

  function removeTrackFromTenant(details: { trackId: string; tenantId: string }): void {
    const tracksForTenant = tracksByTenantId.value[details.tenantId];
    if (tracksForTenant) {
      for (let i = 0; i < tracksForTenant.length; ++i) {
        if (details.trackId === tracksForTenant[i].id) {
          tracksForTenant.splice(i, 1);
          break;
        }
      }
    }
  }

  function clearUnseenChatsForActiveTrack(): void {
    if (activeTrack.value) {
      activeTrack.value.numUnseenChatMessages = 0;
    }
  }

  function clearUnseenChatsForTrack(trackId: string): void {
    const track = tracks.value[trackId];
    if (track) {
      track.numUnseenChatMessages = 0;
    }
  }

  function deleteGuestTrack(trackId: string): void {
    Vue.delete(guestTracks.value, trackId);
  }

  function addGuestTrack(track: Track): void {
    setOrPatchObject(guestTracks.value, track.id, asRecord(track));
  }

  function setLastGuestTrackUpdateTime(refreshTime: number): void {
    lastGuestTrackUpdate.value = refreshTime;
  }

  function setPublishedWorkspaceDetails(details: PublishedWorkspaceDetails | null): void {
    publishDetailsForActiveTrack.value = details;
  }

  function respondToCall(details: {callMessage: CallMessage, callMessageType: CallMessageType}): void {
    clearIncomingCallMessage({ callMessage: details.callMessage });
    DataWorker.instance().dispatch('Tracks/sendCallMessage', details.callMessage.trackId, details.callMessageType);
  }

  /**
   * Gets the list of tracks for which this `guestId` is a guest. A guest user can use this to access the
   * guest-specific server endpoint
   */
  async function updateGuestTracks(guestId: string): Promise<void> {
    const lastUpdateTime = lastGuestTrackUpdate.value || 0;
    const trackResponse: TrackPollResponse =
    await DataWorker.instance().dispatch('Tracks/updateGuestTracks', guestId, lastUpdateTime);

    const guestTracks: Track[] = trackResponse.tracks;
    for (const track of guestTracks) {
      addGuestTrack(track);
    }
    setLastGuestTrackUpdateTime(trackResponse.requestReceivedTime);
  }

  async function clearAllUnseenTrackActivity(): Promise<void> {
    const itemsToClear: NotificationItem[] = [];

    for (const activity of trackNotifications.value) {
      itemsToClear.push({
        type: activity.type,
        trackId: activity.track.id,
        entryId: activity.entryId,
        targetId: activity.targetId
      });
    }

    // no point waiting for the worker - if everything goes well then the notifications
    // will be removed - if not we'll get an update and they'll reappear
    DataWorker.instance().dispatch('OnlineStatus/clearNotifications', itemsToClear);

    // remove all the activities client side
    for (const activity of [...trackNotifications.value]) {
      activity.track.activity?.splice(0, activity.track.activity.length);
      if (activity.type === 'CHAT_MESSAGE') {
        // If we don't set this to 0, the notifications won't disappear until the next chat data update
        clearUnseenChatsForTrack(activity.track.id);
      }
    }
  }

  async function markEntriesViewed(target: {trackId: string, entryIds: string[]}): Promise<void> {
    await DataWorker.instance().dispatch('Tracks/updateTrackEntriesViewed', target.trackId, target.entryIds);
  }

  async function markChatThreadViewed(details: { trackId: string, parentChatId: string }): Promise<void> {
    const request: Promise<void> = new Promise((resolve, reject) => {
      const track = tracks.value[details.trackId];

      if (track?.activity) {
        // we need to find the matching activity here since a copy will have been taken
        const activity: TrackActivity | undefined = track.activity.find(a => details.parentChatId ===
          a.targetId && ActivityType.THREAD_CHAT_MESSAGE === a.type);

        if (activity) {
          const indexToRemove = track.activity.indexOf(activity);
          if (indexToRemove > -1) {
            track.activity.splice(indexToRemove, 1);
          }
        }
      }
      DataWorker.instance().dispatch('Tracks/updateChatThreadViewed', details.trackId, details.parentChatId, track)
        .then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
    });
    return await performMonitoredTracksRequest(request);
  }

  async function markEntryCommentViewed(details: EntryComment): Promise<void> {
    await DataWorker.instance()
      .dispatch('Tracks/updateTrackEntryCommentsViewed', details.track.id, details.entryId);

    if (details?.track?.activity) {
      // we need to find the matching activity here since a copy will have been taken
      const activity: TrackActivity | undefined = details.track.activity.find(a => details.entryId ===
        a.entryId && details.type === a.type);

      if (activity) {
        const indexToRemove = details.track.activity.indexOf(activity);
        if (indexToRemove > -1) {
          details.track.activity.splice(indexToRemove, 1);
        }
      }
    }
  }

  async function removeTrackActivity(details: TrackActivity): Promise<void> {
    switch (details.type) {
      case ActivityType.NEW_ENTRY:
      case ActivityType.SCHEDULE_MEETING:
      case ActivityType.MEETING:
        await DataWorker.instance()
          .dispatch('OnlineStatus/updateOnlineStatus', Date.now(), details.track.id, details.entryId);
        break;
      case ActivityType.ENTRY_COMMENT:
      case ActivityType.MEETING_COMMENT:
        await DataWorker.instance()
          .dispatch('Tracks/updateTrackEntryCommentsViewed', details.track.id, details.entryId);
        break;
      case ActivityType.THREAD_CHAT_MESSAGE: {
        await DataWorker.instance()
          .dispatch('Tracks/updateChatThreadViewed', details.track.id, details.targetId);
        const membership = userStore.trackMembership(details.track.id);
        let member: Member | null = null;
        if (membership === null) {
          const members = await trackMembersStore.getMembers(details.track.id);
          const currentUserId = userStore.currentUserId;
          member = members?.find((currentMember: Member) => currentMember.userId === currentUserId) ?? null;
        }
        const trackId = membership ? membership.trackId : member?.trackId;
        const id = membership ? membership.id : member?.id;
        const userId = membership ? membership.userId : member?.userId;
        if (trackId && id && userId) {
          const memberUpdate: Member = {
            trackId: trackId,
            id: id,
            userId: userId,
            added: Date.now(),
            lastChatThread: details.date,
            shouldCopyFromTemplate: undefined,
          };
          await trackMembersStore.updateMember(memberUpdate);
        }
        break;
      }
      case ActivityType.CHAT_MESSAGE: {
        const membership = userStore.trackMembership(details.track.id);
        let member: Member | null = null;
        if (membership === null) {
          const members = await trackMembersStore.getMembers(details.track.id);
          const currentUserId = userStore.currentUserId;
          member = members?.find((currentMember: Member) => currentMember.userId === currentUserId) ?? null;
        }
        const trackId = membership ? membership.trackId : member?.trackId;
        const id = membership ? membership.id : member?.id;
        const userId = membership ? membership.userId : member?.userId;
        if (trackId && id && userId) {
          const memberUpdate: Member = {
            trackId: trackId,
            id: id,
            userId: userId,
            added: Date.now(),
            lastChat: details.date,
            shouldCopyFromTemplate: undefined,
          };
          await trackMembersStore.updateMember(memberUpdate);
        }
        Vue.set(tracks.value[details.track.id], 'numUnseenChatMessages', 0);
        break;
      }
      case ActivityType.TASK_MODIFIED:
      case ActivityType.NON_TASK_RECORD_MODIFIED: {
        if (details.targetId) {
          const taskId: string = details.targetId;
          await DataWorker.instance().dispatch('Tasks/markTaskViewed', taskId);
        }
        break;
      }
      default:
        await DataWorker.instance()
          .dispatch('OnlineStatus/updateOnlineStatus', Date.now(), details.track.id, details.entryId);
        break;
    }

    if (details?.track?.activity) {
      // we need to find the matching activity here since a copy will have been taken
      const activity: TrackActivity | undefined = details.track.activity.find(a => trackActivityEquals(details, a));

      if (activity) {
        const indexToRemove = details.track.activity.indexOf(activity);
        if (indexToRemove > -1) {
          details.track.activity.splice(indexToRemove, 1);
        }
      } else {
        log.debug('Error: Could not find activity to be removed from track: ' + details.track.id);
        log.debug(details);
      }
    }
  }

  async function refreshTracks(): Promise<void> {
    if (userStore.isGuestUser) {
      // TODO: handle guests
      return;
    }
    await DataWorker.instance().dispatch('Tracks/refreshTracks');
  }

  function setLastDataUpdateTime(lastUpdate: number): void {
    lastTrackDataUpdate.value = lastUpdate;
  }

  async function setNewTrackList(details: { tracks: Track[]; fullRefresh: boolean }): Promise<void> {
    if (details.fullRefresh) {
      for (const track of Object.values(tracks.value)) {
        if (!details.tracks.find((current: Track) => current.id === track.id)) {
          removeTrack(track);
        }
      }
    }
    if (isUserFetchRequired(tracks.value, details.tracks, details.fullRefresh)) {
      userStore.refreshAllUsers();
    }
    setTracks(details.tracks);
    const tenants: Record<string, Tenant> = tenantsStore.tenants;
    if (details.tracks.some((track: Track) => !tenants[track.tenantId])) {
      tenantsStore.refreshTenants();
    }
  }

  async function createTrackBatch(trackBatches: TrackBatch[]): Promise<Track[] | undefined> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      const tracks: Track[] = await DataWorker.instance().dispatch('Tracks/createTrackBatch', trackBatches);

      for (const track of tracks) {
        setTrack(track);
        addTrackToTenant({ track, tenantId: track.tenantId });
      }
      return tracks;
    } catch (error) {
      log.error('Error creating track: ' + error);
    }
  }

  async function createTrack(details: TrackDetails): Promise<Track | undefined> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      const track: Track = await DataWorker.instance().dispatch('Tracks/createTrack', details);
      setTrack(track);
      addTrackToTenant({ track, tenantId: track.tenantId });
      return track;
    } catch (error) {
      log.error('Error creating track: ' + error);
    }
  }

  async function updateTrack(track: Track): Promise<Track | undefined> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      // Set activity to null before sending to server to avoid circular dependency serialising JSON.
      track.activity = undefined;
      const updatedTrack: Track = await DataWorker.instance().dispatch('Tracks/updateTrack', track);
      setTrack(updatedTrack);
      return updatedTrack;
    } catch (error) {
      log.error('Error updating track: ' + error);
    }
  }

  async function deleteTrack(track: Track): Promise<void> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      // Set activity to null before sending to server to avoid circular dependency serialising JSON.
      const trackToDelete: Track = Object.assign({}, track);
      trackToDelete.activity = undefined;
      await DataWorker.instance().dispatch('Tracks/deleteTrack', trackToDelete);
      removeTrack(trackToDelete);
    } catch (error) {
      log.error('Error deleting track: ' + error);
    }
  }

  async function updateTenantForTrack(track: Track): Promise<void> {
    let refreshTenantsFromServer = true;
    const allTenants: Tenant[] = tenantsStore.allTenants;
    for (const tenant of allTenants) {
      if (tenant.id === track.tenantId) {
        refreshTenantsFromServer = false;
        // Add the track to the new tenant's list if it doesn't exist.
        addTrackToTenant({ track, tenantId: tenant.id });
      } else {
        // Remove the track from the old tenant's list if it exists.
        removeTrackFromTenant({ trackId: track.id, tenantId: tenant.id });
      }
    }

    if (refreshTenantsFromServer) {
      // This is a tenant we've never seen before.
      await tenantsStore.refreshTenants();
      addTrackToTenant({ track, tenantId: track.tenantId });
    }
  }

  async function requestTrackTransfer(transferTrackPayload: TransferTrackPayload): Promise<Track | undefined> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      const transferToPayload = { transferTo: transferTrackPayload.transferTo } as TransferPayload;
      const track: Track = await DataWorker.instance()
        .dispatch('Tracks/requestTrackTransfer', transferToPayload, transferTrackPayload.trackId);
      return track;
    } catch (error) {
      log.error('Error accepting track transfer: ' + error);
    }
  }

  async function acceptTrackTransfer(track: Track): Promise<void> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      await DataWorker.instance().dispatch('Tracks/acceptTrackTransfer', track);
      track.owner = track.pendingTransferTarget;
      delete track.pendingTransferTarget;
      await trackMembersStore.refreshMembers();
      await updateTenantForTrack(track);
    } catch (error) {
      log.error('Error accepting track transfer: ' + error);
    }
  }

  async function cancelTrackTransfer(track: Track): Promise<void> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      await DataWorker.instance().dispatch('Tracks/cancelTrackTransfer', track);
      delete track.pendingTransferTarget;
    } catch (error) {
      log.error('Error cancelling track transfer: ' + error);
    }
  }

  async function rejectTrackTransfer(track: Track): Promise<void> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      await DataWorker.instance().dispatch('Tracks/rejectTrackTransfer', track);
      delete track.pendingTransferTarget;
      removeTrack(track);
    } catch (error) {
      log.error('Error rejecting track transfer: ' + error);
    }
  }

  async function acceptTrackMembership(track: Track): Promise<void> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      await DataWorker.instance().dispatch('Tracks/acceptTrackMembership', track);
      await trackMembersStore.refreshMembers();
    } catch (error) {
      log.error('Error accepting track membership: ' + error);
    }
  }

  async function rejectTrackMembership(track: Track): Promise<void> {
    try {
      if (userStore.isGuestUser) {
        return;
      }
      await DataWorker.instance().dispatch('Tracks/rejectTrackMembership', track);
      removeTrack(track);
    } catch (error) {
      log.error('Error rejecting track transfer: ' + error);
    }
  }

  async function addWorkflow(payload: WorkflowAddPayload): Promise<TrackWebhook | undefined> {
    try {
      const result: TrackWebhook =
      await DataWorker.instance().dispatch('Tracks/addWorkflow',
        payload.track.id, payload.workflow);
      payload.track.webhooks?.push(result);
      setTrack(payload.track);
      return result;
    } catch (error) {
      log.error('Error adding workflow to track: ' + error);
    }
  }

  async function deleteWorkflow(payload: WorkflowDeletePayload): Promise<void> {
    try {
      await DataWorker.instance().dispatch('Tracks/deleteWorkflow', payload.track.id, payload.workflowId);
      payload.track.webhooks = payload.track.webhooks?.filter(
        (webhook: TrackWebhook) => { return webhook.id !== payload.workflowId; });
      setTrack(payload.track);
    } catch (error) {
      log.error('Error deleteing workflow from track: ' + error);
    }
  }

  function invokeExternalWorkflow(payload: { track: Track | { id: string }, workflowId: string,
    text: string, platformApi: boolean, guestId?: string }): Promise<string> {
    return ServerData.invokeExternalWorkflow(payload.track.id, payload.workflowId, payload.text, payload.platformApi,
      payload.guestId);
  }

  function invokeWorkflow(payload: WorkflowInvokePayload): Promise<void> {
    return ServerData.invokeWebhook(payload.track, payload.trackWebhook, payload.text);
  }

  function invokeWorkflows(payload: WorkflowInvokePayload): Promise<void> {
    return ServerData.invokeWebhooks(payload.track, payload.text);
  }

  async function publishTrack(trackId: string): Promise<string> {
    const trackToPublish = tracks.value[trackId];

    if (!trackToPublish) {
      throw new Error(`Could not publish workspace with ID: ${trackId} (workspace does not exist for this tenant)`);
    }

    return await DataWorker.instance().dispatch('Tracks/publishTrack', trackId);
  }

  async function updatePublishedWorkspaceDetails(): Promise<void> {
    if (!activeTrack.value) {
      setPublishedWorkspaceDetails(null);
      return;
    }

    if (!activeTrack.value.publishable) {
      setPublishedWorkspaceDetails(null);
      return;
    }

    let result: PublishedWorkspaceDetails;
    try {
      result = await DataWorker.instance().dispatch('Tracks/getPublishedWorkspaceDetails', activeTrack.value.id);
      setPublishedWorkspaceDetails(result);
    } catch (err) {
      log.warn(`Could not find published workspace details for ${activeTrack.value.id}`);
      setPublishedWorkspaceDetails(null);
    }
  }

  async function clearPublishedWorkspaceDetails(): Promise<void> {
    setPublishedWorkspaceDetails(null);
  }

  async function toggleFavourite(trackId: string): Promise<void> {
    let track: Track = tracks.value[trackId];
    if (!track) {
      return;
    }
    const newStatus: boolean = !track.userFavourite;
    await DataWorker.instance().dispatch('Tracks/setFavourite', trackId, newStatus);

    // Re-grab it incase it's subsequently updated in a way to leave us with a stale reference, then update locally.
    // The shared worker doesn't bother signalling all instances to update immediately as there will be an imminent
    // poll that includes the change
    track = tracks.value[trackId];
    track.userFavourite = newStatus;
    setTrack(track);
  }

  async function updateTrackFeatureGrants(details: { trackId: string, features: string[] }): Promise<string[]> {
    return await DataWorker.instance().dispatch('Tracks/updateTrackFeatureGrants', details.trackId, details.features);
  }

  async function getTrackFeatureGrants(trackId: string): Promise<string[]> {
    return await DataWorker.instance().dispatch('Tracks/getTrackFeatureGrants', trackId);
  }

  async function updateLastSeenChatForTrack(details: { trackId: string, newestMessage: ChatMessage }): Promise<void> {
    if (details.newestMessage.isPlaceholder) {
      return;
    }

    clearUnseenChatsForTrack(details.trackId);
    const track: Track = tracks.value[details.trackId];
    if (track) {
      if (!track.lastChatMessage || (details.newestMessage.date >= track.lastChatMessage.date)) {
        const chatTrackMemberships: Record<string, Member> = userStore.currentUserChatTrackMemberships;
        if (chatTrackMemberships[details.trackId]) {
          const member: Member = chatTrackMemberships[details.trackId];
          // Do this as a monitored request because the value is calculated as part of the track poll, so we need to
          // make sure everything is synchronised.
          const request: Promise<void> = new Promise((resolve, reject) => {
            const memberUpdatePatch: Member = {
              id: member.id,
              userId: member.userId,
              trackId: member.trackId,
              added: member.added,
              trackHidden: member.trackHidden,
              lastChat: details.newestMessage.date,
              shouldCopyFromTemplate: undefined,
            };
            DataWorker.instance().dispatch('Tracks/updateLastSeenChatForTrack', memberUpdatePatch,
              details.newestMessage).then(() => {
              resolve();
            }).catch((error) => {
              reject(error);
            });
          });
          return await performMonitoredTracksRequest(request);
        }
      }
    }
  }

  async function performMonitoredTracksRequest(request: Promise<void>): Promise<void | undefined> {
    const requestId: string = uuid();
    addInProgressRequest({ requestId, request });
    try {
      return await request;
    } catch (error) {
      log.error(`Failed to perform 'Tracks' request: ${error}`);
    } finally {
      removeInProgressRequest({ requestId });
    }
  }

  function addInProgressRequest(details: { requestId: string; request: Promise<Track> | Promise<void> }): void {
    inProgressRequests.value[details.requestId] = details.request;
  }

  function removeInProgressRequest(details: { requestId: string }): void {
    delete inProgressRequests.value[details.requestId];
  }

  function setCurrentlyDisplayedChatSummaryTrack(track: Track | null): void {
    currentlyDisplayedChatSummaryTrack.value = track;
  }

  async function waitForInProgressUpdates(): Promise<void> {
    const inProgressUpdates: Promise<unknown>[] = Object.values(inProgressRequests.value);
    if (inProgressUpdates.length) {
      try {
        await Promise.all(inProgressUpdates);
      } catch (error) {
        // The error will be logged by the action that triggered the update
      }
    }
  }

  function isUserFetchRequired(currentTracks: Record<string, Track>, newTracks: Track[],
    fullRefresh: boolean) {
    if (fullRefresh) {
      // Assume that we will have already triggered a user fetch
      return false;
    }

    // Refresh the user lists if we get any new tracks
    for (const track of newTracks) {
      if (!currentTracks[track.id]) {
        return true;
      }
    }
    return false;
  }

  // Returns true if this is a private or track chat; false otherwise
  function isTrackWithChat(track: Track) {
    return track.type === TrackType.PRIVATE_CHAT || track.type === TrackType.STANDARD;
  }

  // Returns true if the track is a chat track and has at least one plaintext message; false otherwise
  function hasChatMessage(track: Track): boolean {
    return isTrackWithChat(track) && track.lastChatMessage?.chatMessageContent?.plain != null;
  }

  // Returns true if the current user's membership for a track indicates that this chat should be hidden
  function isChatHiddenForCurrentUser(track: Track): boolean {
    if (track.type !== TrackType.PRIVATE_CHAT) {
      // Can only hide private chats
      return false;
    }

    const currentUserId: string | null = userStore.currentUserId;
    const members: Member[] = trackMembersStore.membersForTrack(track.id);
    return members.some((m) => m.userId === currentUserId && m.trackHidden);
  }

  // Sort function for ordering tracks by last chat message date
  function byLastMessageDate(a: Track, b: Track): number {
    const aLastMessage = a?.lastChatMessage?.date ?? 0;
    const bLastMessage = b?.lastChatMessage?.date ?? 0;
    return bLastMessage - aLastMessage;
  }

  // Sort function for ordering tracks by last chat message date, or, if there are no messages, by creation date
  function byLastMessageDateOrCreatedDate(a: Track, b: Track) {
    const aLastDate = a?.lastChatMessage?.date ?? a?.created ?? 0;
    const bLastDate = b?.lastChatMessage?.date ?? b?.created ?? 0;
    return bLastDate - aLastDate;
  }

  function currentUserCanViewChat(guestTracks: Record<string, Track>, tracks: Record<string, Track>,
    trackId: string): boolean | null {
    const isGuest = userStore.isGuestUser || userStore.isGuestMember(trackId);
    const tracksObj: Record<string, Track> = isGuest ? guestTracks : tracks;
    const track: Track = tracksObj[trackId];
    if (!track) {
      // If we haven't finished loading the tracks, tell the caller that we don't know, rather than telling them 'no'
      return Object.keys(tracksObj).length ? false : null;
    }
    let viewChatSetting: ChatAccessLevel | undefined;
    if (track.type === TrackType.PRIVATE_CHAT || track.type === TrackType.CHANNEL_CHAT) {
      viewChatSetting = track.chatSettingViewMessages;
    } else {
      if ('tenantAllowsChatSettingOverrides' in track) {
        // We are a guest of this track, so the tenant information exists as part of the track object
        const guestTrack: GuestTrack = track as GuestTrack;
        viewChatSetting = guestTrack.tenantAllowsChatSettingOverrides ? guestTrack.chatSettingViewMessages
          : guestTrack.tenantChatSettingViewMessages;
      } else {
        // We are a member of this track, so we should have the tenant data in the store
        const allTenants: Tenant[] = tenantsStore.allTenants;
        const tenant: Tenant | undefined = allTenants.find(tenant => tenant.id === track.tenantId);
        if (!tenant) {
          // The tenant retrieval is probably in progress
          return null;
        }
        viewChatSetting = tenant.allowChatSettingOverrides ? track.chatSettingViewMessages
          : tenant.chatSettingViewMessages;
      }
    }

    const member: Member | null = userStore.trackMembership(trackId);
    switch (viewChatSetting) {
      case ChatAccessLevel.ANYONE:
        return true;
      case ChatAccessLevel.NON_GUESTS:
        return member?.confirmed ?? false;
      case ChatAccessLevel.COLLABORATORS:
        return member?.role === MemberRole.COORDINATOR || member?.role === MemberRole.COLLABORATOR;
      case ChatAccessLevel.COORDINATORS:
        return member?.role === MemberRole.COORDINATOR;
      default: // assume ChatAccessLevel.NOONE
        return false;
    }
  }

  function currentUserCanSendChat(guestTracks: Record<string, Track>, tracks: Record<string, Track>,
    trackId: string): boolean {
    let track: Track = tracks[trackId];
    if (!track) {
      track = guestTracks[trackId];
    }
    if (!track) {
      return false;
    }
    let viewChatSetting: ChatAccessLevel | undefined;
    if (track.type === TrackType.PRIVATE_CHAT || track.type === TrackType.CHANNEL_CHAT) {
      viewChatSetting = track.chatSettingSendMessages;
    } else {
      if ('tenantAllowsChatSettingOverrides' in track) {
        // We are a guest of this track, so the tenant information exists as part of the track object
        const guestTrack: GuestTrack = track as GuestTrack;
        viewChatSetting = guestTrack.tenantAllowsChatSettingOverrides ? guestTrack.chatSettingSendMessages
          : guestTrack.tenantChatSettingSendMessages;
      } else {
        // We are a member of this track, so we should have the tenant data in the store
        const allTenants: Tenant[] = tenantsStore.allTenants;
        const tenant: Tenant | undefined = allTenants.find(tenant => tenant.id === track.tenantId);
        if (!tenant) {
          // The tenant retrieval is probably in progress
          return false;
        }
        viewChatSetting = tenant.allowChatSettingOverrides ? track.chatSettingSendMessages
          : tenant.chatSettingSendMessages;
      }
    }

    const member: Member | null = userStore.trackMembership(trackId);
    switch (viewChatSetting) {
      case ChatAccessLevel.ANYONE:
        return true;
      case ChatAccessLevel.NON_GUESTS:
        return member?.confirmed ?? false;
      case ChatAccessLevel.COLLABORATORS:
        return member?.role === MemberRole.COORDINATOR || member?.role === MemberRole.COLLABORATOR;
      case ChatAccessLevel.COORDINATORS:
        return member?.role === MemberRole.COORDINATOR;
      default: // assume ChatAccessLevel.NOONE
        return false;
    }
  }

  function trackActivityEquals(a: TrackActivity, b: TrackActivity): boolean {
    return (a.type === b.type &&
      a.entryId === b.entryId &&
      a.track?.id === b.track?.id &&
      a.date === b.date &&
      a.userId === b.userId &&
      a.userName === b.userName);
  }

  return {
    lastTrackDataUpdate,
    tracks,
    guestTracks,
    publishDetailsForActiveTrack,
    currentlyDisplayedChatSummaryTrack,
    activeTrackId,
    activeAppId,
    trackTemplatesForActiveApp,
    activeTrackTitleInitials,
    trackOwnerEmail,
    trackCreationDate,
    allTracks,
    allPrivateChatTracks,
    guestTrack,
    activeTrack,
    activeTrackParent,
    recentlyUpdatedTracks,
    allTracksWithChats,
    chatsByMostRecentMessage,
    currentUserCanViewActiveTrackChat,
    currentUserCanViewMeetingChat,
    currentUserCanSendActiveTrackChat,
    currentUserCanSendMeetingChat,
    trackNotifications,
    groupedTrackActivities,
    notificationsState,
    trackNotificationCountByTrack,
    meetingTrack,
    incomingCall,
    recentlyViewedTracks,
    isActiveTrackTemplateOrChildOfTemplate,
    setRecentlyViewedTracks,
    setCallMessage,
    setLastChatMessageInTrack,
    setTracks,
    clearUnseenChatsForActiveTrack,
    deleteGuestTrack,
    respondToCall,
    updateGuestTracks,
    clearAllUnseenTrackActivity,
    markEntriesViewed,
    markChatThreadViewed,
    markEntryCommentViewed,
    removeTrackActivity,
    refreshTracks,
    setLastDataUpdateTime,
    setNewTrackList,
    createTrackBatch,
    createTrack,
    updateTrack,
    deleteTrack,
    requestTrackTransfer,
    acceptTrackTransfer,
    cancelTrackTransfer,
    rejectTrackTransfer,
    acceptTrackMembership,
    rejectTrackMembership,
    addWorkflow,
    deleteWorkflow,
    invokeExternalWorkflow,
    invokeWorkflow,
    invokeWorkflows,
    publishTrack,
    updatePublishedWorkspaceDetails,
    clearPublishedWorkspaceDetails,
    toggleFavourite,
    updateTrackFeatureGrants,
    getTrackFeatureGrants,
    updateLastSeenChatForTrack,
    setCurrentlyDisplayedChatSummaryTrack,
    waitForInProgressUpdates
  };
});
