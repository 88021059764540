/* eslint-disable  @typescript-eslint/no-explicit-any */

import log from 'loglevel';
import { v4 as uuid } from 'uuid';

import { CallMessage } from '@/data/datatypes/chat/CallMessage';
import { ChatMessage } from '@/data/datatypes/chat/ChatMessage';
import { MeetingStatus } from '@/data/datatypes/online/OnlineStatus';
import { CallMessageType } from '@/data/datatypes/Track';
import { TrackEntry } from '@/data/datatypes/TrackEntry';
import { workerLog } from '@/data/log/DataWorkerLog';
import { perfLog, perfMsg } from '@/data/log/PerformanceLog';
import Sanitiser from '@/data/Sanitiser';
import { WorkerMessageType } from '@/data/storage/WorkerMessageType';
import { checkForServiceWorkerUpdate } from '@/workers/ServiceWorkerUpdateCheck';

import Integrations from '../config/Integrations';
import { ThirdPartyMeetingDetails } from '../datatypes/meeting/ThirdPartyMeetingDetails';
import { FullUserDetails } from '../datatypes/UserDetails';
import ObjectStores from './ObjectStores';
import ObjectStoreSync from './ObjectStoreSync';

type ProgressCallback = (event: ProgressEvent) => any;
type CancelCallback = (cancelFunc: () => void) => void;
type SuccessCallback = (messageResponse: any) => void;
type ErrorCallback = (messageResponse: any) => void;

interface QueuedMessage {
  messageType: WorkerMessageType;
  messageArgs: any[];
  objectsToTransfer?: Transferable[];
  progressCallback?: ProgressCallback;
  cancelCallback?: CancelCallback;
  successCallback: SuccessCallback;
  errorCallback: ErrorCallback;
}

// type CallbackType = ((event: ProgressEvent<EventTarget> | (() => void)) => any) | undefined;
type CallbackType = ProgressCallback | CancelCallback | SuccessCallback | ErrorCallback;

export default class DataWorker {
  private endpoint!: MessagePort | Worker;
  private messageCallbacks: { [messageId: string]: { [callbackType: string]: CallbackType } } = {};

  private initialised: boolean = false;
  private workerLoginProcessed: boolean = false;
  private initialisationStarted: number = 0;
  private MAX_WAIT_FOR_INITIALISATION: number = 10000;
  private queuedMessages: QueuedMessage[] = [];
  private objectStoreSync!: ObjectStoreSync;
  private invalidated: boolean = false;

  private static theDataWorker: DataWorker;

  private initResolution!: () => void;
  private initPromise: Promise<void> = new Promise((resolve) => {
    this.initResolution = resolve;
  });

  private loginResolution!: () => void;
  private loginPromise: Promise<void> = new Promise((resolve) => {
    this.loginResolution = resolve;
  });

  private workerReadyPromise: Promise<[void, void]> = Promise.all([this.initPromise, this.loginPromise]);

  private async initialise(): Promise<void> {
    if (this.invalidated) {
      throw new Error('DataWorker has been invalidated');
    }
    if (this.initialised) {
      return;
    }
    if (this.initialisationStarted === 0) {
      const loadStart: number = performance.now();
      workerLog.debug('DataWorker initialising...');
      this.initialisationStarted = Date.now();
      // Make sure the main thread opens the DB, and upgrades it if required, before instantiating the worker
      await this.initObjectStoreSync();
      await this.initialiseWorker();
      // Get the shared worker log to match the same level as the main window log
      this.postMessageToInitialisedWorker(WorkerMessageType.SET_LOG_LEVEL, [log.getLevel()]);
      perfLog.debug(perfMsg('DataWorker initialised', false, loadStart));
    } else {
      await this.waitForInitialisation();
    }
  }

  private waitForInitialisation(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.initPromise.then(() => {
        resolve();
      });
      setTimeout(() => {
        if (!this.initialised) {
          reject(new Error('Waiting for initialisation timed out'));
        }
      }, this.MAX_WAIT_FOR_INITIALISATION);
    });
  }

  private async initObjectStoreSync(): Promise<ObjectStoreSync> {
    if (!this.objectStoreSync) {
      this.objectStoreSync = await ObjectStoreSync.getInstance();
    }
    return this.objectStoreSync;
  }

  private async initialiseWorker(): Promise<void> {
    if (window.SharedWorker) {
      /*
      // Webpack worker-loader creates a function that wraps the JS reference in a `new Worker` call. This treats
      // function as a string, pulls out the target script from the constructor params and passes that into a
      // `new SharedWorker` call: https://github.com/webpack-contrib/worker-loader/issues/2#issuecomment-543643361
      const scriptAsStr: string = '' + Worker;
      const stringArgs: RegExpMatchArray | null = scriptAsStr.match(/"(.*?)"/);
      if (stringArgs && stringArgs.length > 1) {
        // The first element is the full match, including quotation marks. Second element is just the filename.
        const scriptName = stringArgs[1];
        const worker: SharedWorker = new SharedWorker(process.env.BASE_URL + scriptName);
        worker.port.start();
        this.endpoint = worker.port;
      }
      */

      const worker: SharedWorker = new SharedWorker(new URL('@/workers/data.worker', import.meta.url));
      worker.port.start();
      this.endpoint = worker.port;
    }
    // If the browser doesn't support SharedWorker (Safari) or the code to start it failed, fall back to a WebWorker
    if (!this.endpoint) {
      this.endpoint = new Worker(new URL('@/workers/data.worker', import.meta.url));
    }
    this.endpoint.onmessage = (event: MessageEvent) => {
      this.handleMessage(event);
    };
    workerLog.debug('starting worker');
    await this.postMessageToInitialisedWorker(WorkerMessageType.START,
      [Integrations.BE, Integrations.WS, Integrations.XWIKI_SERVER]);
    workerLog.debug('worker started');
    this.initialised = true;
    this.initResolution();
  }

  private async handleMessage(event: MessageEvent): Promise<void> {
    if (Array.isArray(event.data) && event.data.length >= 2) {
      const messageId: string = event.data[1];
      switch (event.data[0] as WorkerMessageType) {
        case WorkerMessageType.ERROR:
          workerLog.error(event.data[2]);
          this.doCallback(messageId, 'error', event.data[2]);
          return;
        case WorkerMessageType.RESPONSE:
          // The callback will be handled below.
          break;
        case WorkerMessageType.PROGRESS:
          this.doCallback(messageId, 'progress', event.data[2]);
          return;
        case WorkerMessageType.PING:
          if (this.isInitialised()) {
            this.postMessage(WorkerMessageType.PONG);
          }
          break;
        case WorkerMessageType.PONG:
          this.doCallback(messageId, 'success');
          return;
        case WorkerMessageType.DATA_UPDATE:
          await this.handleWorkerUpdate(...(event.data[2]));
          break;
        case WorkerMessageType.SYNCHRONISED_DATA_UPDATE:
          await this.handleWorkerSynchronisedUpdate(...(event.data[2]));
          break;
        case WorkerMessageType.GUEST_DATA_UPDATE:
          await this.handleWorkerUpdateForGuest(...(event.data[2]));
          break;
        case WorkerMessageType.TYPING:
          await this.handleTypingUpdate(...(event.data[2]));
          break;
        case WorkerMessageType.CALL_MESSAGE:
          await this.handleCallMessageUpdate(...(event.data[2]));
          break;
        case WorkerMessageType.SET_RECENTLY_VIEWED_TRACK_IDS:
          await this.handleRecentlyViewedTracksUpdate(...(event.data[2]));
          break;
        case WorkerMessageType.SET_GUEST_MEETING_RECORD_ENTRY:
          await this.handleGuestMeetingRecordEntry(...(event.data[2]));
          break;
        case WorkerMessageType.THIRD_PARTY_MEETING_DETAILS:
          await this.handleThirdPartyMeetingDetails(...(event.data[2]));
          break;
        case WorkerMessageType.CHECK_FOR_SERVICE_WORKER_UPDATE:
          checkForServiceWorkerUpdate();
          break;
        case WorkerMessageType.TRIGGER_GUEST_ONLINE_UPDATE:
          await this.handleTriggerGuestOnlineUpdate(...(event.data[2]));
          break;
        default:
          workerLog.debug('Unrecognised message type from worker: ' + Sanitiser.safeStringify(event.data));
          break;
      }
      this.doCallback(messageId, 'success', event.data.length > 2 ? event.data[2] : undefined);
    } else {
      workerLog.debug('Unrecognised message from worker: ' + Sanitiser.safeStringify(event.data));
    }
  }

  private doCallback(messageId: string | null, callbackType: string, response?: any): void {
    if (messageId && this.messageCallbacks[messageId]) {
      const callback: CallbackType | undefined = this.messageCallbacks[messageId][callbackType];
      if (callbackType === 'success' || callbackType === 'error') {
        delete this.messageCallbacks[messageId];
      }
      if (callback) {
        callback(response);
      }
    }
  }

  private isGetTokenRequest(messageArgs?: any[]): boolean {
    return !!(messageArgs?.length) && ['User/getUserToken', 'User/getGuestToken'].includes(messageArgs[0]);
  }

  private isWorkerReadyForMessage(messageType: WorkerMessageType, messageArgs?: any[]): boolean {
    // Sending to a fully initialised worker is always allowed
    if (this.isInitialised()) {
      return true;
    }
    // If we don't have an endpoint setup yet, then we can't send
    if (!this.endpoint) {
      return false;
    }
    // These messages are required before any other message type, so allow them through as part of the init process
    if (messageType === WorkerMessageType.START || messageType === WorkerMessageType.HANDLE_LOGIN) {
      return true;
    }
    // We need to get a token as part of the auth process during init
    if (this.isGetTokenRequest(messageArgs)) {
      return true;
    }
    // This is unrelated to any user data - as long as we have an endpoint then this one's fair game
    if (messageType === WorkerMessageType.SET_LOG_LEVEL) {
      return true;
    }
    return false;
  }

  private postMessage(messageType: WorkerMessageType, messageArgs?: any[], objectsToTransfer?: Transferable[],
    successCallback?: SuccessCallback, errorCallback?: ErrorCallback, progressCallback?: ProgressCallback): string {
    let messageId: string = '';
    if (this.isWorkerReadyForMessage(messageType, messageArgs)) {
      messageId = uuid();
      if (successCallback || errorCallback || progressCallback) {
        this.messageCallbacks[messageId] = {};
        if (successCallback) {
          this.messageCallbacks[messageId].success = successCallback;
        }
        if (errorCallback) {
          this.messageCallbacks[messageId].error = errorCallback;
        }
        if (progressCallback) {
          this.messageCallbacks[messageId].progress = progressCallback;
        }
      }
      const transfer: Transferable[] = objectsToTransfer || [];
      const message: any[] = [messageType, messageId].concat(messageArgs || []);
      const messagePlusTransfer: any[] = message.concat(transfer);
      this.endpoint.postMessage(messagePlusTransfer, transfer);
    } else {
      workerLog.error(`worker not initialised. not sending ${messageType}`);
    }
    return messageId;
  }

  private async postMessageToInitialisedWorker(messageType: WorkerMessageType, messageArgs: any[],
    objectsToTransfer?: Transferable[], progressCallback?: ProgressCallback,
    cancelCallback?: CancelCallback) {
    return new Promise((resolve, reject) => {
      const successCallback = (messageResponse: any) => {
        resolve(messageResponse);
      };
      const errorCallback = (errorResponse: any) => {
        reject(errorResponse);
      };

      const messageId: string = this.postMessage(messageType, messageArgs, objectsToTransfer,
        successCallback, errorCallback, progressCallback);

      if (messageId && cancelCallback) {
        const cancelMessageFunction = () => {
          const cancelMessage = [WorkerMessageType.CANCEL, messageId];
          this.endpoint.postMessage(cancelMessage);
        };
        cancelCallback(cancelMessageFunction);
      }
    });
  }

  private async processQueuedMessages(): Promise<void> {
    for (const queuedMessage of this.queuedMessages) {
      this.postMessageToInitialisedWorker(queuedMessage.messageType,
        queuedMessage.messageArgs, queuedMessage.objectsToTransfer, queuedMessage.progressCallback)
        .then((response: any) => {
          queuedMessage.successCallback(response);
        }).catch((error: any) => {
          queuedMessage.errorCallback(error);
        });
    }
    this.queuedMessages = [];
  }

  private async processQueuedMessagesWhenReady(): Promise<void> {
    this.initialise();
    await this.workerReadyPromise;
    this.processQueuedMessages();
  }

  private async postMessageGetResponse(messageType: WorkerMessageType, messageArgs: any[],
    objectsToTransfer?: Transferable[], progressCallback?: (event: ProgressEvent) => any,
    cancelCallback?: (cancelFunc: () => void) => void) {
    return new Promise((resolve, reject) => {
      const successCallback = (messageResponse: any) => {
        resolve(messageResponse);
      };
      const errorCallback = (errorResponse: any) => {
        reject(errorResponse);
      };

      // If it's a get token request then do it as soon as the worker is initialised
      if (this.isGetTokenRequest(messageArgs)) {
        this.initPromise.then(() => {
          this.postMessage(messageType, messageArgs, objectsToTransfer, successCallback, errorCallback,
            progressCallback);
        }).catch((err) => {
          reject(err);
        });
      } else {
        this.queuedMessages.push({
          messageType,
          messageArgs,
          objectsToTransfer,
          progressCallback,
          cancelCallback,
          successCallback,
          errorCallback,
        });

        this.processQueuedMessagesWhenReady();
      }
    });
  }

  private async handleWorkerUpdate(objectStoreName?: string, timestamp?: number, extraData?: Record<string, unknown>):
      Promise<void> {
    if (!objectStoreName) {
      throw Error('Missing object store name for worker update');
    }
    if (timestamp == null) {
      throw Error('Missing timestamp for worker update');
    }

    workerLog.debug('got worker update of type ' + objectStoreName + ' with timestamp ' + timestamp);
    await this.initObjectStoreSync();
    await this.objectStoreSync.reload(objectStoreName, timestamp, extraData);
  }

  private async handleWorkerSynchronisedUpdate(objectStoreName?: string, timestamp?: number,
    extraData?: Record<string, unknown>): Promise<void> {
    if (!objectStoreName) {
      throw Error('Missing object store name for synchronised worker update');
    }
    if (timestamp == null) {
      throw Error('Missing timestamp for synchronised worker update');
    }

    workerLog.debug('got synchronised worker update of type ' + objectStoreName + ' with timestamp ' + timestamp);
    await this.initObjectStoreSync();
    await this.objectStoreSync.synchronisedReload(objectStoreName, timestamp, extraData);
  }

  private async handleWorkerUpdateForGuest(objectStoreName?: string, data?: ChatMessage[]):
      Promise<void> {
    if (!objectStoreName) {
      throw Error('Missing object store name for worker guest update');
    }
    if (data == null) {
      throw Error('Missing data for worker guest update');
    }
    await this.initObjectStoreSync();
    await this.objectStoreSync.reloadForGuest(objectStoreName, data);
  }

  private async handleTypingUpdate(trackId?: string, usersTyping?: Array<{userId: string; timestamp: number}>):
      Promise<void> {
    if (trackId && usersTyping) {
      await this.initObjectStoreSync();
      this.objectStoreSync.handleUsersTyping(trackId, usersTyping);
    }
  }

  private async handleRecentlyViewedTracksUpdate(trackIds?: string[]): Promise<void> {
    if (trackIds) {
      await this.initObjectStoreSync();
      this.objectStoreSync.handleRecentlyViewedTracksUpdate(trackIds);
    }
  }

  private async handleCallMessageUpdate(callMessage?: CallMessage, callMessageType?: CallMessageType):
    Promise<void> {
    if (callMessage && callMessageType) {
      await this.initObjectStoreSync();
      this.objectStoreSync.handleCallMessage(callMessage, callMessageType);
    }
  }

  private async handleGuestMeetingRecordEntry(meetingRecordEntry?: TrackEntry): Promise<void> {
    if (meetingRecordEntry) {
      await this.initObjectStoreSync();
      this.objectStoreSync.handleGuestMeetingRecordEntry(meetingRecordEntry);
    }
  }

  private async handleThirdPartyMeetingDetails(thirdPartyMeetingDetails?: ThirdPartyMeetingDetails): Promise<void> {
    if (thirdPartyMeetingDetails) {
      await this.initObjectStoreSync();
      this.objectStoreSync.handleThirdPartyMeetingDetails(thirdPartyMeetingDetails);
    }
  }

  private async handleTriggerGuestOnlineUpdate(trackId?: string): Promise<void> {
    if (trackId) {
      await this.initObjectStoreSync();
      this.objectStoreSync.handleTriggerGuestOnlineUpdate(trackId);
    }
  }

  private async reloadDataFromIDB(): Promise<void> {
    await this.initObjectStoreSync();
    this.objectStoreSync.reloadAll();
  }

  public isInitialised(): boolean {
    return this.initialised && this.workerLoginProcessed;
  }

  public async dispatch(actionName: string, ...args: any[]): Promise<any> {
    workerLog.debug('dispatching ' + actionName + ' with args: ' + Sanitiser.safeStringify(args));
    const messageArgs: any[] = [actionName].concat(args);
    return await this.postMessageGetResponse(WorkerMessageType.DISPATCH, messageArgs);
  }

  public async ping(): Promise<any> {
    workerLog.debug('pinging worker');
    return await this.postMessageGetResponse(WorkerMessageType.PING, []);
  }

  public iosReconnectLiveuser(): void {
    workerLog.debug('iOS app reconnecting liveuser');
    this.postMessage(WorkerMessageType.IOS_RECONNECT_LIVEUSER, []);
  }

  public async dispatchWithTransfer(actionName: string, args: any[],
    objectsToTransfer: Transferable[], progressCallback?: (event: ProgressEvent) => any,
    cancelCallback?: (cancelFunc: () => void) => void): Promise<any> {
    workerLog.debug('dispatching ' + actionName + ' with args: ' + Sanitiser.safeStringify(args));
    const messageArgs: any[] = [actionName].concat(args);
    return await this.postMessageGetResponse(WorkerMessageType.DISPATCH_WITH_PROGRESS, messageArgs,
      objectsToTransfer, progressCallback, cancelCallback);
  }

  public async handleLogin(userId: string, tenantId: string, featureGrants: string[] | undefined): Promise<any> {
    const loginStart: number = performance.now();
    perfLog.debug(perfMsg('handleLogin starting'));
    await this.initObjectStoreSync();
    // Get the User in the DB before the login - if it changes then we need to reload all data
    const currentUserInDb: FullUserDetails | undefined = await this.objectStoreSync.getCurrentDBUser();
    const userChanged: boolean = currentUserInDb?.id !== userId;
    // We have to post to the shared worker, so need to wait for it to finish initialising here
    await this.initPromise;
    const response: any = await this.postMessageToInitialisedWorker(WorkerMessageType.HANDLE_LOGIN,
      [userId, tenantId, featureGrants]);
    this.workerLoginProcessed = true;
    this.loginResolution();
    if (userChanged) {
      // If a new user has logged in, the worker will have cleared out the previous user's data, so now
      // we can synchronise our Pinia store.
      this.reloadDataFromIDB();
    }
    perfLog.debug(perfMsg('handleLogin completed', false, loginStart));
    return response;
  }

  public async handleGuestAuth(dataSyncRequired: boolean): Promise<void> {
    const functionStart: number = performance.now();
    perfLog.debug(perfMsg('handleGuestAuth starting'));
    await this.initialise();
    // Keep the general structure similar to the login function for timing reasons, although we don't need these.
    await this.initObjectStoreSync();
    if (dataSyncRequired) {
      // The shared worker will have cleared out any data that was left over from a previously logged-in user, so
      // re-sync our stores:
      this.objectStoreSync.reloadAll();
    }
    await this.initPromise;
    this.workerLoginProcessed = true;
    this.loginResolution();
    perfLog.debug(perfMsg('handleGuestAuth completed', false, functionStart));
  }

  public async setUserActive(isActive: boolean): Promise<void> {
    await this.postMessageGetResponse(WorkerMessageType.SET_USER_ACTIVE, [isActive]);
  }

  public async setActiveGuestTrackId(trackId: string | null): Promise<void> {
    await this.postMessageGetResponse(WorkerMessageType.SET_ACTIVE_GUEST_TRACK_ID, [trackId]);
  }

  public async setActiveTrackId(trackId: string | null, previousTrackId: string | null | undefined): Promise<void> {
    const start: number = performance.now();
    perfLog.debug(perfMsg('Setting active track ID'));
    // uninitialise the track data of the previous active track
    if (previousTrackId) {
      this.objectStoreSync.uninitialiseTrackData(previousTrackId);
      perfLog.debug(perfMsg('Uninitialised previous track ID', false, start));
    }

    const allPromises: Array<Promise<unknown>> = [];
    // Update Pinia
    if (trackId) {
      this.initObjectStoreSync().then((objectStoreSync: ObjectStoreSync) => {
        const handleStart: number = performance.now();
        perfLog.debug(perfMsg('Setting new track ID on Pinia stores'));
        const promises: Array<Promise<unknown>> = [
          objectStoreSync.handleNewActiveTrackId(trackId, ObjectStores.TRACK_ENTRIES_STORE),
          objectStoreSync.handleNewActiveTrackId(trackId, ObjectStores.TRACK_MEMBERS_STORE),
          objectStoreSync.handleNewActiveTrackId(trackId, ObjectStores.TRACK_ONLINE_STATUSES_STORE),
          objectStoreSync.handleNewActiveTrackId(trackId, ObjectStores.PINNED_TRACK_ITEMS_STORE),
          objectStoreSync.handleNewActiveTrackId(trackId, ObjectStores.TRACK_MINI_APPS_STORE),
          objectStoreSync.handleNewActiveTrackId(trackId, ObjectStores.TRACK_TASKS_TABLE_STORE),
          objectStoreSync.handleNewActiveTrackId(trackId, ObjectStores.TASKS_STORE),
          objectStoreSync.handleNewActiveTrackId(trackId, ObjectStores.SEMANTIC_TAGS_STORE),
          objectStoreSync.handleNewActiveTrackId(trackId, ObjectStores.ENTRY_THUMBNAIL_STORE),
        ];

        Promise.all(promises).then(() => {
          perfLog.debug(perfMsg('Done setting new track ID on Pinia stores', false, handleStart));
        });
        allPromises.push(...promises);
      });
    }

    // Update the data worker
    perfLog.debug(perfMsg('Sending new active track ID to data worker'));
    const workerMessagesStart: number = performance.now();
    const setActiveTrackPromise: Promise<unknown> =
      this.postMessageGetResponse(WorkerMessageType.SET_ACTIVE_TRACK_ID, [trackId]);
    setActiveTrackPromise.then(() => {
      perfLog.debug(perfMsg('Done Sending new activetrack ID to data worker', false, workerMessagesStart));
    });
    allPromises.push(setActiveTrackPromise);

    await Promise.all(allPromises);
    perfLog.debug(perfMsg('Done updating active track ID'));
  }

  public setActiveEntryId(entryId: string | null, previousEntryId?: string | null): Promise<unknown> {
    const promises: Array<Promise<unknown>> = [];
    if (this.objectStoreSync && entryId) {
      promises.push(this.objectStoreSync.handleNewActiveEntryId(entryId, ObjectStores.ENTRY_TEXT_FILES_STORE));
    }

    promises.push(this.postMessageGetResponse(WorkerMessageType.SET_ACTIVE_ENTRY_ID, [entryId, previousEntryId]));
    return Promise.all(promises);
  }

  public async setActiveMiniAppId(miniAppId: string | undefined, previousMiniAppId: string | undefined):
    Promise<unknown | undefined> {
    if (!miniAppId && !previousMiniAppId) {
      return;
    }

    if (this.objectStoreSync && previousMiniAppId) {
      this.objectStoreSync.uninitialiseMiniAppData(previousMiniAppId);
    }

    const promises: Array<Promise<unknown>> = [];
    if (this.objectStoreSync && miniAppId) {
      promises.push(this.objectStoreSync.handleNewActiveMiniAppId(miniAppId, ObjectStores.MINI_APP_VIEW_THEMES_STORE));
    }
    promises.push(this.postMessageGetResponse(WorkerMessageType.SET_ACTIVE_MINI_APP_ID,
      [miniAppId, previousMiniAppId]));

    return Promise.all(promises);
  }

  /**
   * Set the trackID of the ongoing meeting (which can be different from the currently viewed track)
   * and its associated status.
   */
  public async setMeetingTrackId(meetingTrackId: string, status: MeetingStatus): Promise<void> {
    // Update the endpoint (so that the timer based online poll knows what to send)
    await this.postMessageGetResponse(WorkerMessageType.SET_MEETING_TRACK_ID, [meetingTrackId, status]);

    // And then ensure an meeting online event is immediately sent to the server.
    // At present this is not used to store data (so no finally block).
    this.dispatch('OnlineStatus/handleUpdatedActiveMeetingId', meetingTrackId, status);

    const allPromises: Array<Promise<unknown>> = [];
    // Update Pinia
    this.initObjectStoreSync().then((objectStoreSync: ObjectStoreSync) => {
      const handleStart: number = performance.now();
      perfLog.debug(perfMsg('Setting new meeting track ID on Pinia stores'));
      const promises: Array<Promise<unknown>> = [
        objectStoreSync.handleNewMeetingTrackId(meetingTrackId, status, ObjectStores.ENTRY_THUMBNAIL_STORE),
      ];

      Promise.all(promises).then(() => {
        perfLog.debug(perfMsg('Done setting new meeting track ID on Pinia stores', false, handleStart));
      });
      allPromises.push(...promises);
    });
    await Promise.all(allPromises);
  }

  public async initialiseCommentsForEntryId(entryId: string, trackId: string, fullRefresh = false): Promise<void> {
    if (entryId) {
      this.dispatch('ChatMessages/initialiseCommentsForEntryId', entryId, trackId, fullRefresh)
        .finally(() => {
          if (this.objectStoreSync) {
            this.objectStoreSync.loadCommentsByEntryId(entryId, trackId, fullRefresh);
          }
        });
    }
  }

  public setNewChatTrackId(trackId: string, messagesInitialisedForTrack?: boolean, fullRefresh?: boolean): void {
    if (messagesInitialisedForTrack) {
      // We already have the latest messages in Pinia, so there is no need to wait for anything
      this.objectStoreSync.loadTrackChatMessagesByTrackId(trackId, fullRefresh);
      this.dispatch('ChatMessages/handleNewChatTrackId', trackId, null, fullRefresh);
    } else {
      // Ensure we have the latest messages before loading them into Pinia so that the paged history retrieval works
      // properly, and to avoid rendering old messages from the IDB before immediately replacing with a page of new ones
      let handleStart: number = performance.now();
      perfLog.debug(perfMsg('Sending new chat track ID to data worker'));
      this.dispatch('ChatMessages/handleNewChatTrackId', trackId, null, fullRefresh)
        .finally(() => {
          perfLog.debug(perfMsg('Finished updating new chat track ID in data worker', false, handleStart));
          handleStart = performance.now();
          this.objectStoreSync.loadTrackChatMessagesByTrackId(trackId, fullRefresh).then(() => {
            perfLog.debug(perfMsg('Finished loading messages for new track', false, handleStart));
          });
        });
    }
  }

  public clearChatTrackId(previousTrackId: string): void {
    this.dispatch('ChatMessages/handleNewChatTrackId', null, previousTrackId, false);
  }

  public static setInvalidated(): void {
    if (DataWorker.theDataWorker) {
      DataWorker.theDataWorker.invalidated = true;
    }
  }

  public static async waitForInstance(): Promise<DataWorker> {
    if (!DataWorker.theDataWorker) {
      DataWorker.theDataWorker = new DataWorker();
      try {
        await DataWorker.theDataWorker.initialise();
      } catch (error) {
        workerLog.error(`Unable to initialise data worker - ${(error as { message: string }).message}`);
      }
    }
    return DataWorker.theDataWorker;
  }

  public static instance(): DataWorker {
    if (!DataWorker.theDataWorker) {
      DataWorker.theDataWorker = new DataWorker();
      try {
        DataWorker.theDataWorker.initialise();
      } catch (error) {
        workerLog.error(`Unable to initialise data worker - ${(error as { message: string }).message}`);
      }
    }
    return DataWorker.theDataWorker;
  }
}
