import { RouteLocationRaw } from 'vue-router';

export type PopupMenuItemType =
  'not_set' |
  'menu_heading' |
  // Text only (not clickable/focusable)
  'text' |
  // Horizontal dividing line (not clickable/focusable)
  'divider' |
  // sub menu only
  'menu' |
  // clickable item
  'item' |
  // clickable item with sub menu button
  'item_menu' |
  // clickable item that also acts with 'select' style behaviour with sub menu button
  'item_hybrid' |
  // Similar to 'ITEM' but does not emit 'itemClicked' event
  'action' |
  // Similar to 'ACTION' but but is intended to trigger change within the menu tree
  'in_menu_action' |
  // Link to external url
  'url' |
  // Place holder for future use case
  'action_toggle';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PopupMenuCallback = (...args: any[]) => any;

interface PopupMenuItemCommon {
  optionsType: 'base' | 'callback' | 'router' | 'url';

  readonly elementId?: string;

  readonly elementTitle?: string;

  // CSS classe(s) to append to the menu item
  readonly cssClass?: string;

  // CSS styles to append to the menu item
  readonly cssStyle?: Record<string, string>;

  // Reference to one of our icon assets as used with BrandIcon.vue. see https://app.cafex.com/icons for full list of icon references
  readonly icon?: string;

  // additional data e.g. for use on callbacks etc
  readonly data?: unknown;

  // The 'selected' status of the item - true will add a check mark in front of the item in the menu
  readonly selected?: boolean;

  // Text that will added in front of the 'name' of the item
  readonly textBefore?: string;

  // Text that will added after the 'name' of the item
  readonly textAfter?: string;

  // Disabled state
  readonly disabled?: boolean;

  /**
   * Menu items of a PopupMenuItem
   * intended for use with PopupMenuItemType 'MENU' and 'ITEM_MENU',
   * items, of which type, that have an indicator/button (ITEM_MENU) for the presence/opening of a submenu.
   * N.B. An empty array will result in no indicator/button appearing on such items
   */
  // eslint-disable-next-line no-use-before-define
  readonly menuItems?: PopupMenuItem[];
}

interface PopupMenuItemBaseOptions extends PopupMenuItemCommon {
  optionsType: 'base';
}

interface PopupMenuItemRouterOptions extends PopupMenuItemCommon {
  optionsType: 'router';

  // Router location. if a value is present here, callbacks will not be used
  route: RouteLocationRaw;
}

interface PopupMenuItemURLOptions extends PopupMenuItemCommon {
  optionsType: 'url';

  // URL to navigate to in new tab. if a value is present here, callbacks will not be used
  url: string;
}

interface PopupMenuItemCallbackOptions extends PopupMenuItemCommon {
  optionsType: 'callback';

  // Callback function for 'clickable' items
  callback: PopupMenuCallback;
}

type PopupMenuItemOptions = PopupMenuItemBaseOptions | PopupMenuItemCallbackOptions | PopupMenuItemRouterOptions |
  PopupMenuItemURLOptions;

const DEFAULT_NAME: string = 'Unnamed Item';

export class PopupMenuItem {
  public itemType: PopupMenuItemType = 'not_set';

  // String used as both the visible text displayed for the item and and some cases as an idnetifier
  public name: string;

  /**
   * Creates an instance of PopupMenuItem.
   * @param itemType - PopupMenuItemType
   * @param [name] - String used as both the visible text displayed for the item and in some cases as an identifier
   * @param [options] - Optional additional properties @see PopupMenuItemOptions
   * Example of a simple menu:
   * @example
   * private viewActionsMenu: PopupMenuItem[] = [
      new PopupMenuItem('url', 'See our privacy policy', {
        optionsType: 'url',
        url: 'https://cafex.to/privacy/'
      }),
      new PopupMenuItem('menu', 'This is a menu', {
        optionsType: 'base',
        menuItems: [
          new PopupMenuItem('item', 'item one'),
          new PopupMenuItem('item', 'item two'),
          new PopupMenuItem('item', 'item three'),
        ]
      }),
      new PopupMenuItem('action', 'Set as default view', {
        optionsType: 'callback',
        callback: (item: PopupMenuItem) =>
          this.changeDefault(item.name)
      }),
      new PopupMenuItem('action', 'Delete this view', {
        optionsType: 'callback',
        callback: (item: PopupMenuItem) => {
          this.showDeleteViewDialog(item);
        },
        cssClass: 'alert'
      })
    ];
   */
  public constructor(
    itemType: PopupMenuItemType,
    name: string = DEFAULT_NAME,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    private options?: PopupMenuItemOptions) {
    this.itemType = itemType;
    this.name = name;
  }

  public get elementId(): string | undefined {
    return this.options?.elementId;
  }

  public get elementTitle(): string {
    return this.options?.elementTitle ?? '';
  }

  public get cssClass(): string | undefined {
    return this.options?.cssClass;
  }

  public get cssStyle(): Record<string, string> | undefined {
    return this.options?.cssStyle;
  }

  public get icon(): string | undefined {
    return this.options?.icon;
  }

  public get data(): unknown | undefined {
    return this.options?.data;
  }

  public get selected(): boolean | undefined {
    return this.options?.selected;
  }

  public get textBefore(): string | undefined {
    return this.options?.textBefore;
  }

  public get textAfter(): string | undefined {
    return this.options?.textAfter;
  }

  public get disabled(): boolean {
    return this.options?.disabled ?? false;
  }

  public get callback(): PopupMenuCallback | undefined {
    if (this.options?.optionsType === 'callback') {
      return (this.options as PopupMenuItemCallbackOptions)?.callback;
    }
  }

  public get route(): RouteLocationRaw | undefined {
    if (this.options?.optionsType === 'router') {
      return (this.options as PopupMenuItemRouterOptions)?.route;
    }
  }

  public get url(): string | undefined {
    if (this.options?.optionsType === 'url') {
      return (this.options as PopupMenuItemURLOptions)?.url;
    }
  }

  public get menuItems(): PopupMenuItem[] | undefined {
    return this.options?.menuItems;
  }

  public get popupMenuItemOptions(): PopupMenuItemOptions | undefined {
    return this.options;
  }
}

/**
 * Returns a copy of the item without menu items.
 * Used to prevent potential feedback looping
 * @param item: PopupMenuItem
 * @returns PopupMenuItem
 */
export function nonCircularCopy(item: PopupMenuItem): PopupMenuItem | undefined {
  // Separate out the options from the item (does this actually work given popupMenuItemOptions is a getter?
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { popupMenuItemOptions, ...popupItem } = item;

  // No options, just return a copy with the name and item type
  if (!popupMenuItemOptions) {
    return new PopupMenuItem(item.itemType, item.name);
  }

  // Separate the menuItems from the rest of the options
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { menuItems, ...options } = popupMenuItemOptions;

  // New copy with the options minus menuItems
  return new PopupMenuItem(item.itemType, item.name, options);
}
