import log from 'loglevel';

import { initialiseLogger } from '../log/LogHelper';
import { perfLog } from '../log/PerformanceLog';

const integrationsLoadStart: number = performance.now();

declare global {
  interface Window {
    pageLoadStart: number;
    dataServer: string;
    dataServerPath: string;
    xwikiServer: string;
    analyticsServer: string;
    contentDomain: string;
    testAudio: string;
    enableLogging: boolean;
    externalAddress: string;
    workflowServer: string;
  }
}
const devMode: boolean = process.env.NODE_ENV === 'development';
initialiseLogger(devMode);
perfLog.debug(`Page load start: ${window.pageLoadStart}`);
perfLog.debug(`Integrations handler load start: ${integrationsLoadStart}`);

// These defaults are overridden in main.ts with the values from index.html,
// which is updated with env-specifics on deploy
let dataServer = 'http://localhost:1802/be';
let wsServer = 'ws://localhost:1802/be';
let xwikiServer = ''; // http(s)://<server>[:<port>]/kb
let analyticsServer = '';
let contentServer = '';
let testAudio = '';
let externalAddress = null;
let workflowServer = 'http://localhost:4200/';
let defaultHost = true;
const canonicalHost = getConfig('dataServer', 'VUE_APP_DATA_SERVER', dataServer);

// Optionally constrain the allowed hosts
const allowedHostsStr = getConfig('allowedServers', 'VUE_APP_ALLOWED_SERVERS', '');
const allowedHosts: string[] | null =
  allowedHostsStr?.length ? allowedHostsStr.split(',').map(item => item.trim()) : null;

/**
 * Handle looking in the window values (injected at pod startup) or node env props (for dev) for config values.
 * @param windowAttrName The attribute on the window object to check
 * @param envPropName The node env var to check
 * @param defaultValue The default to use if not specified
 * @returns The value to use for the config item
 */
function getConfig(windowAttrName: string, envPropName: string, defaultValue: string): string {
  const windowVal: string | null | undefined =
    (window as unknown as Record<string, string | null | undefined>)[windowAttrName];
  const envVal: string | null | undefined = process.env?.[envPropName];
  const valToCheck = (windowVal && windowVal !== 'DEFAULT') ? windowVal : envVal;
  let value: string = defaultValue;
  if (valToCheck?.length && valToCheck !== 'DEFAULT') {
    value = valToCheck;
  }
  return value;
}

/**
 * Get the relevant host config. If a path value is set then it's assumed to be relative to the current host.
 * If the allowed hosts are set and the current host isn't in the allowed hosts, then the canonical host for the system
 * will be used instead.
 * @param windowAttrName The attribute on the window object to check
 * @param envPropName The node env var to check
 * @param windowRelativePathName The attribute on the window object to check for a relative path
 * @param envRelativePathName The node env var to check for a relative path
 * @param defaultValue The default value to use if nothing is configured
 * @returns The relevant configuration
 */
function getHostConfig(windowAttrName: string, envPropName: string, windowRelativePathName: string,
  envRelativePathName: string, defaultValue: string) {
  let value = getConfig(windowAttrName, envPropName, defaultValue);
  let path = getConfig(windowRelativePathName, envRelativePathName, '');
  // If we're using a relative path for the server, then we support custom / vanity URLs and we need to use the
  // current host with the relevant suffix
  if (path?.length) {
    if (!path.startsWith('/')) {
      path = `/${path}`;
    }
    const location = window.location.origin;
    // If the list of allowed hosts is constrained, then check if the location is allowed and fall back to the host.
    if (allowedHosts?.length && !allowedHosts.includes(location)) {
      value = canonicalHost;
    } else {
      value = location + path;
    }
  }
  return value;
}

dataServer = getHostConfig('dataServer', 'VUE_APP_DATA_SERVER', 'dataServerPath', 'VUE_APP_DYNAMIC_DATA_SERVER_PATH',
  dataServer);

wsServer = dataServer.replace(/^http(s?)/, 'ws$1');

xwikiServer = getHostConfig('xwikiServer', 'VUE_APP_XWIKI_SERVER', 'xwikiServerPath',
  'VUE_APP_DYNAMIC_XWIKI_SERVER_PATH', xwikiServer);

analyticsServer = getHostConfig('analyticsServer', 'VUE_APP_ANALYTICS_SERVER', 'analyticsServerPath',
  'VUE_APP_DYNAMIC_ANALYTICS_SERVER_PATH', analyticsServer);

contentServer = getConfig('contentDomain', 'VUE_APP_CONTENT_DOMAIN', contentServer);
if (contentServer && !contentServer.startsWith('http://') && !contentServer.startsWith('https://')) {
  contentServer = `https://${contentServer}`;
}
testAudio = getConfig('testAudio', 'VUE_APP_TEST_AUDIO', testAudio);
externalAddress = getConfig('externalAddress', 'VUE_APP_EXTERNAL_ADDRESS', externalAddress as unknown as string);
workflowServer = getConfig('workflowServer', 'VUE_APP_AP_SERVER', workflowServer);
// If the host that we're using doesn't match the one we'd use in a single-domain environment, then flag that
defaultHost = dataServer === canonicalHost;

log.debug(`devMode = ${devMode} | dataServer = ${dataServer} ` +
  `| xwikiServer = ${xwikiServer} | contentDomain = ${contentServer} | testAudio = ${testAudio} ` +
  `| analyticsServer = ${analyticsServer} ` +
  `| externalAddress = ${externalAddress} | workflowServer = ${workflowServer} | defaultHost = ${defaultHost}`);

export default {
  devMode,
  BE: dataServer,
  WS: wsServer,
  XWIKI_SERVER: xwikiServer,
  ANALYTICS_SERVER: analyticsServer,
  CONTENT_SERVER: contentServer,
  TEST_AUDIO: testAudio,
  externalAddress,
  WORKFLOW_SERVER: workflowServer,
  IS_DEFAULT_HOST: defaultHost,
  CANONICAL_HOST: canonicalHost,
};
