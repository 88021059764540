import VueRouter from 'vue-router';

import BrowserDetection from '@/data/BrowserDetection';
import Integrations from '@/data/config/Integrations';
import { getSpace } from '@/data/kb/XWikiClient';
import router from '@/router';
import RouteNames from '@/router/RouteNames';
import { useUserStore } from '@/stores/User';

function getWikiBaseURL(): string {
  const userStore = useUserStore();
  const tenantWikiId: string | undefined = userStore.currentToken?.kbWikiId;
  if (tenantWikiId) {
    return Integrations.XWIKI_SERVER + '/wiki/' + tenantWikiId;
  }
  return '';
}

function isWikiUrl(url: string): boolean {
  const wikiBaseUrl = getWikiBaseURL();
  return !!wikiBaseUrl && url.startsWith(wikiBaseUrl);
}

function convertWikiUrlToFender(url: string): string {
  const baseUrl = getWikiBaseURL();
  const path = url.replace(baseUrl, '');
  const route = router.resolve({
    name: RouteNames.KNOWLEDGE_MANAGEMENT_ROUTE_NAME
  });
  return route.href + path;
}

function openKnowledgeArticleByUrl(articleUrl: string, router: VueRouter): void {
  const userStore = useUserStore();
  const tenantWikiId: string | undefined = userStore.currentToken?.kbWikiId;
  if (tenantWikiId) {
    const articlePath = articleUrl.substring(articleUrl.indexOf(tenantWikiId) + tenantWikiId.length);
    const route = router.resolve({
      name: RouteNames.KNOWLEDGE_MANAGEMENT_ROUTE_NAME
    });
    if (BrowserDetection.isIosApp()) {
      // the iOS app opens the article in the same window...
      const iOSRouterPath = route.route.path + articlePath;
      router.push({ path: iOSRouterPath });
    } else {
      // ...everything else opens in new browser tab
      const urlToOpen = route.href + articlePath;
      window.open(urlToOpen, '_blank');
    }
  }
}

async function openKnowledgeArticleById(articleId: string, router: VueRouter): Promise<void> {
  const userStore = useUserStore();
  const tenantWikiId: string | undefined = userStore.currentToken?.kbWikiId;
  if (tenantWikiId) {
    const space = await getSpace(Integrations.XWIKI_SERVER, tenantWikiId, articleId);
    const articleUrl = space.xwikiAbsoluteUrl;
    openKnowledgeArticleByUrl(articleUrl, router);
  }
}

function constructUrlPathForArticle(spaceId: string): string {
  const spaceSeparatorRegex = /[^\\]\./g;
  const spaceComponents: string[] = [];
  const spaceSeparators = [...spaceId.matchAll(spaceSeparatorRegex)];
  let nextChar = 0;
  for (const separator of spaceSeparators) {
    if (separator.index) {
      const space = spaceId.substring(nextChar, separator.index + 1);
      spaceComponents.push(space.replaceAll('\\.', '.'));
      nextChar = separator.index + 2;
    }
  }
  if (nextChar <= spaceId.length) {
    spaceComponents.push(spaceId.substring(nextChar).replaceAll('\\.', '.'));
  }
  const path = `/k/view/${spaceComponents.join('/')}`;
  return path;
}

export {
  constructUrlPathForArticle,
  convertWikiUrlToFender,
  getWikiBaseURL,
  isWikiUrl,
  openKnowledgeArticleById,
  openKnowledgeArticleByUrl
};
