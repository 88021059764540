import { dump, load } from 'js-yaml';
import { isEqual } from 'lodash-es';
import log from 'loglevel';
import { defineStore } from 'pinia';
import { v4 as uuid } from 'uuid';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import { Track, TrackType } from '@/data/datatypes/Track';
import { FullUserDetails } from '@/data/datatypes/UserDetails';
import {
  AppImportPayload,
  FileUploadResult,
  setupFileUploadCalls, TableDataImportPayload,
  triggerFileDownload,
} from '@/data/helpers/FileHelper';
import { compare } from '@/data/helpers/SearchHelper';
import { getCollaborateApp, TASKS_MINI_APP_NAME } from '@/data/helpers/TaskHelper';
import { isTemplateOrChildOfTemplate } from '@/data/helpers/TrackHelper';
import DataWorker from '@/data/storage/DataWorker';
import { Attribute, AttributeType, FormulaAttributeOptionType } from '@/data/tasks/Attribute';
import { CustomViewDetails } from '@/data/tasks/customviews/CustomViewDetails';
import { FlowConfig } from '@/data/tasks/FlowConfig';
import { parseExpression } from '@/data/tasks/Formula';
import { ImportAppResponse } from '@/data/tasks/ImportAppResponse';
import { MiniApp, MiniAppPermission } from '@/data/tasks/MiniApp';
import {
  GuestAppAndSupportingModels,
  MiniAppViewConditionalOverrides,
  ViewAndSupportingModels, ViewAndSupportingModelsResponse
} from '@/data/tasks/MiniAppViewConditionalOverrides';
import {
  MiniAppViewTheme,
  MiniAppViewThemePayload
} from '@/data/tasks/MiniAppViewTheme';
import { TableDataExportDetails } from '@/data/tasks/TableDataExportDetails';
import { cloneTask, isTempTask, Task, TempTask } from '@/data/tasks/Task';
import { TasksTable } from '@/data/tasks/TasksTable';
import { MiniAppView, MiniAppViewPayload, TaskViewType } from '@/data/tasks/TaskView';
import { UIFlow } from '@/data/tasks/UIFlow';
import { UIFlowDependenciesResponse } from '@/data/tasks/UIFlowDependenciesResponse';
import { UpdateTableAttributeRequest } from '@/data/tasks/UpdateTableAttributeRequest';
import pinia from '@/stores/index';
import { useRouteStore } from '@/stores/Route';
import {
  asRecord,
  patchObject,
  setOrPatchObject
} from '@/stores/StoreHelper';
import { useTracksStore } from '@/stores/Tracks';
import { useUserStore } from '@/stores/User';

import { useDeployedAppsStore } from './release/DeployedApps';

type ConditionalOverridesByViewId =
  { [viewId: string]: { [conditionalOverridesId: string]: MiniAppViewConditionalOverrides } };

export const DEFAULT_COLLABORATION_APP_ID = '_';

export const useTasksStore = defineStore('Tasks', () => {
  const TMP_TASK_PREFIX: string = 'tmpTask-';
  const TENANT_VIEWS_KEY: string = 'tenant';

  const tracksStore = useTracksStore(pinia);
  const deployedAppsStore = useDeployedAppsStore();
  const userStore = useUserStore(pinia);

  const miniApps: Ref<{ [trackId: string]: { [itemId: string]: MiniApp } }> = ref({});
  const tables: Ref<{ [trackId: string]: { [itemId: string]: TasksTable } }> = ref({});
  const views: Ref<{ [trackId: string]: { [viewId: string]: MiniAppView } }> = ref({});
  const tasks: Ref<{ [trackId: string]: { [itemId: string]: Task } }> = ref({});
  const inProgressTaskRequests: Ref<Record<string, Promise<Task>>> = ref({});
  const inProgressTableRequests: Ref<Record<string, Promise<TasksTable>>> = ref({});
  const inProgressAppRequests: Ref<Record<string, Promise<MiniApp>>> = ref({});
  const inProgressUIFlowRequests: Ref<Record<string, Promise<UIFlow>>> = ref({});
  const uiFlowsByAppId: Ref<Record<string, UIFlow[]>> = ref({});
  const uiFlowRequestTimesByAppId: Ref<Record<string, number>> = ref({});
  const uiFlows: Ref<UIFlow[]> = ref([]);
  const selectedGridRecords: Ref<Array<Task | TempTask>> = ref([]);
  const selectedGridAttributes: Ref<Attribute[]> = ref([]);
  const customViewDetails: Ref<{ [trackId: string]: { [viewId: string]: CustomViewDetails } }> = ref({});
  const themes: Ref<{ [viewId: string]: { [themeId: string]: MiniAppViewTheme } }> = ref({});
  const conditionalOverrides: Ref<ConditionalOverridesByViewId> = ref({});

  const tasksInitialised: Ref<Record<string, boolean>> = ref({});
  const taskTablesInitialised: Ref<Record<string, boolean>> = ref({});
  const miniAppViewsInitialised: Ref<Record<string, boolean>> = ref({});
  const miniAppViewsInitialisedForApp: Ref<Record<string, boolean>> = ref({});
  const miniAppsInitialised: Ref<Record<string, boolean>> = ref({});
  const tenantMiniAppsInitialised: Ref<boolean> = ref(false);
  const themesInitialisedForMiniApp: Ref<Record<string, boolean>> = ref({});
  const conditionalOverridesInitialisedForMiniApp: Ref<Record<string, boolean>> = ref({});

  const activeTaskId: ComputedRef<string | null> = computed(() => {
    const routeStore = useRouteStore(pinia);
    return routeStore.route?.params.taskId ?? null;
  });

  const activeTask: ComputedRef<Task | undefined> = computed(() => {
    if (activeTaskId.value) {
      const activeTrackId: string | null = tracksStore.activeTrackId;
      if (activeTrackId && tasks.value[activeTrackId]) {
        return tasks.value[activeTrackId][activeTaskId.value];
      }
    }
    return undefined;
  });

  const allMiniAppsById: ComputedRef<Record<string, MiniApp>> = computed(() => {
    const allMiniApps: Record<string, MiniApp> = {};
    Object.values(miniApps.value).forEach(item => Object.assign(allMiniApps, item));
    return allMiniApps;
  });

  const getAllTasks: ComputedRef<Task[]> = computed(() => {
    let allTasks: Task[] = [];
    const tracks: Track[] | null = tracksStore.allTracks;
    if (tracks) {
      for (const track of tracks) {
        if (tasks.value[track.id]) {
          const trackTasks: Task[] = Object.values(tasks.value[track.id]);
          allTasks = allTasks.concat(trackTasks);
        }
      }
    }
    return allTasks;
  });

  // returns all tables for the default 'Tasks' app
  const allTasksTables: ComputedRef<TasksTable[]> = computed(() => {
    const tables: TasksTable[] = [];
    const tracks: Track[] | null = tracksStore.allTracks;
    if (tracks) {
      for (const track of tracks) {
        if (miniApps.value[track.id]) {
          Object.values(miniApps.value[track.id]).forEach((app) => {
            if (app.name === TASKS_MINI_APP_NAME) {
              tables.push(...tablesForApp.value(app.id));
            }
          });
        }
      }
    }
    return tables;
  });

  /**
   * Get all apps that have been copied to the track, including the Tasks app.
   */
  const miniAppsForActiveTrack: ComputedRef<MiniApp[]> = computed(() => {
    const trackId: string | null = tracksStore.activeTrackId;
    if (trackId && miniApps.value[trackId]) {
      return Object.values(miniApps.value[trackId]);
    } else {
      return [];
    }
  });

  /**
   * Get all non-Tasks apps that have been copied to the active track.
   */
  const miniAppsAddedToActiveTrackExcludingTasks: ComputedRef<MiniApp[]> = computed(() => {
    return miniAppsForActiveTrack.value.filter((app) => app.name !== TASKS_MINI_APP_NAME);
  });

  /**
   * Get all non-Tasks apps that have been copied to the active track, plus the track's owning app.
   */
  const miniAppsForActiveTrackExcludingTasks: ComputedRef<MiniApp[]> = computed(() => {
    const track: Track | undefined = tracksStore.activeTrack;
    const apps: MiniApp[] = miniAppsAddedToActiveTrackExcludingTasks.value.slice();
    if (track?.owningMiniAppId) {
      const owningApp = referencedTenantApps.value.find(referencedApp => referencedApp.id === track.owningMiniAppId);
      if (owningApp) {
        apps.unshift(owningApp);
      }
    }
    return apps;
  });

  /**
   * Get all non-Tasks apps that have been copied to the active track, plus the track's owning app if it has any views.
   */
  const interactableAppsForActiveTrack: ComputedRef<MiniApp[]> = computed(() => {
    const track: Track | undefined = tracksStore.activeTrack;
    const apps: MiniApp[] = miniAppsAddedToActiveTrackExcludingTasks.value.slice();
    if (track?.owningMiniAppId) {
      if (viewsForTenantApps.value.some((view) => view.miniAppId === track.owningMiniAppId)) {
        const owningApp = referencedTenantApps.value.find(referencedApp => referencedApp.id === track.owningMiniAppId);
        if (owningApp) {
          apps.unshift(owningApp);
        }
      }
    }
    // Check if any config exists for custom internal views.
    const toReturn: MiniApp[] = [];
    for (const miniApp of apps) {
      const viewableViewAvailable = viewsForTenantApps.value.some((tenantAppView) => {
        if (tenantAppView.miniAppId === miniApp.id) {
          if (tenantAppView.type === TaskViewType.CUSTOM_INTERNAL) {
            return isAppViewEdited(tenantAppView);
          }
          return true;
        }
        return false;
      });
      if (viewableViewAvailable) {
        toReturn.push(miniApp);
      }
    }
    return toReturn;
  });

  function isAppViewEdited(miniAppView: MiniAppView): boolean {
    return miniAppView.options?.uiConfig !== undefined;
  }

  const getTasksMiniAppForTrack: ComputedRef<MiniApp | undefined> = computed(() => {
    const trackId: string | null = tracksStore.activeTrackId;
    if (trackId && miniApps.value[trackId]) {
      return Object.values(miniApps.value[trackId]).find((app) => {
        if (app.name === TASKS_MINI_APP_NAME) {
          return app;
        }
        return false;
      });
    }
    return undefined;
  });

  const tenantLevelAppsById: ComputedRef<Record<string, MiniApp>> = computed(() => {
    // Top-level apps don't belong to a track. The Java code used to always return null, but now doesn't return the
    // null value. Include both null and undefined track IDs for compatibility.
    return Object.assign(Object.assign({}, miniApps.value.null), miniApps.value.undefined);
  });

  // Returns all tenant level apps, whether they're templates or not
  const tenantApps: ComputedRef<MiniApp[]> = computed(() => {
    return Object.values(tenantLevelAppsById.value);
  });

  // Returns non template tenant level apps i.e. apps that are referenced by the apps workspaces
  const referencedTenantApps: ComputedRef<MiniApp[]> = computed(() => {
    const apps: MiniApp[] = [];
    Object.values(tenantLevelAppsById.value).forEach((app) => {
      let isTemplate = app.template;
      // We had to temporarily add a separate appTemplate field to allow backwards UI compatibility:
      if (isTemplate) {
        if (app.appTemplate === false) {
          // This is actually a top-level non-template app.
          isTemplate = false;
        }
      }
      if (!isTemplate) {
        apps.push(app);
      }
    });
    return apps;
  });

  // Returns only the old template tenant level apps
  const miniAppTemplates: ComputedRef<MiniApp[]> = computed(() => {
    const apps: MiniApp[] = [];
    Object.values(tenantLevelAppsById.value).forEach((app) => {
      let isTemplate = app.template;
      // We had to temporarily add a separate appTemplate field to allow backwards UI compatibility:
      if (isTemplate) {
        if (app.appTemplate === false) {
          // This is actually a top-level non-template app.
          isTemplate = false;
        }
      }
      if (isTemplate) {
        apps.push(app);
      }
    });
    return apps;
  });

  const availableAppTemplatesToCopy: ComputedRef<MiniApp[]> = computed(() => {
    return miniAppTemplates.value.filter((miniApp) => {
      return !miniAppsForActiveTrackExcludingTasks.value.some((existingApp) => {
        return existingApp.fromTemplateId === miniApp.id;
      });
    });
  });

  const appsWithTracks: ComputedRef<MiniApp[]> = computed(() => {
    const apps: MiniApp[] = [];
    Object.keys(tracksPerApp.value).forEach(appId => {
      if (appId === DEFAULT_COLLABORATION_APP_ID) {
        apps.push(getCollaborateApp());
      } else {
        const app = tenantApps.value.find(app => app.id === appId);
        if (app) {
          apps.push(app);
        }
      }
    });

    // Add a pseudo-app for any chat calls, scheduled meetings and ad-hoc meetings.
    const tracks = Object.values(tracksStore.tracks ?? {});
    if (tracks.some((t) => (t.type === TrackType.PRIVATE_CHAT || t.type === TrackType.CHANNEL_CHAT ||
      t.type === TrackType.SCHEDULED_MEETING || t.type === TrackType.ADHOC_MEETING))) {
      apps.push({
        id: 'meetings',
        name: 'Calls and Scheduled Meetings',
        trackId: '',
        description: '',
        template: false,
        fromTemplateId: '',
        showOnNavigationBar: false,
        webhooks: [],
        permissions: [],
        workspaceLabels: [],
        maintenanceEnabled: false,
        environmentVariables: [],
      });
    }

    return apps.sort((a: MiniApp, b: MiniApp) => {
      let result: number = compare(a.name, b.name);
      if (result === 0) {
        result = compare(a.id, b.id);
      }
      return result;
    });
  });

  const tracksPerApp: ComputedRef<Record<string, Track[]>> = computed(() => {
    const tracks = tracksStore.tracks;
    if (!tracks) {
      return {};
    }
    const deployedAppIds = deployedAppsStore.availableDeployedApps.map(deployed => deployed.appId);
    const tenantApps = tenantLevelAppsById.value;
    const trackTypes = [TrackType.BREAKOUT, TrackType.STANDARD];
    return Object.values(tracks).reduce((result: Record<string, Track[]>, track: Track) => {
      if (trackTypes.includes(track.type) && !isTemplateOrChildOfTemplate(track, tracks)) {
        let tenantAppId: string = track.owningMiniAppId ?? DEFAULT_COLLABORATION_APP_ID;
        if (tenantAppId !== DEFAULT_COLLABORATION_APP_ID &&
          !tenantApps[tenantAppId] && !deployedAppIds.includes(tenantAppId)) {
          // We don't have access to the app.
          // TODO: we need to fix this by getting info about app templates in other tenants.
          tenantAppId = DEFAULT_COLLABORATION_APP_ID;
        }
        if (result[tenantAppId]) {
          result[tenantAppId].push(track);
        } else {
          result[tenantAppId] = [track];
        }
      }
      return result;
    }, {});
  });

  const tenantAppsVisibleToUser: ComputedRef<MiniApp[]> = computed(() => {
    const canConfigureApps: boolean = userStore.canConfigureApps;
    const currentUserId: string | null = userStore.currentUserId;
    const currentUser: FullUserDetails | null = userStore.currentUserDetails;
    const userTenantAdmin: boolean = userStore.isUserTenantAdmin;

    const apps: MiniApp[] = tenantApps.value.filter((miniApp: MiniApp) => {
      if (canConfigureApps) {
        if (userTenantAdmin && miniApp.id === DEFAULT_COLLABORATION_APP_ID) {
          // Tenant admins can configure the default collaboration app
          return true;
        }

        if (userTenantAdmin && currentUser?.tenantId === miniApp.tenantId) {
          // Tenant admins can configure any app in the same tenant
          return true;
        }

        const configureAppPermissions: MiniAppPermission[] = miniApp.permissions.filter(
          (permission: MiniAppPermission) => permission.type === 'CONFIGURE_APP');
        if (configureAppPermissions.length === 0) {
          // User is an app builder and configuration of this app is not restricted
          return true;
        }

        const hasConfigureAppPermission: boolean = configureAppPermissions.some(
          (permission: MiniAppPermission) => permission.userId === currentUserId);
        if (hasConfigureAppPermission) {
          // User is an app builder and has permission to configure this app
          return true;
        }
      }
      // Display the app if the user is allowed to create workspaces in it, or if they are a member of workspaces in it.
      const hasAddWorkspacePermission: boolean = !!miniApp.permissions?.find((permission: MiniAppPermission) =>
        permission.type === 'ADD_WORKSPACE' && permission.userId === currentUserId);
      return hasAddWorkspacePermission || tracksPerApp.value[miniApp.id]?.length;
    }).sort((a: MiniApp, b: MiniApp) => {
      let result: number = compare(a.name, b.name);
      if (result === 0) {
        result = compare(a.id, b.id);
      }
      return result;
    });

    if (tracksPerApp.value[DEFAULT_COLLABORATION_APP_ID]?.length) {
      apps.unshift(getCollaborateApp());
    }
    return apps;
  });

  const getTaskTablesForActiveTrack: ComputedRef<TasksTable[]> = computed(() => {
    const tasksAppId = getTasksMiniAppForTrack.value?.id;
    return getTablesForActiveTrack.value.filter(table => table.miniAppId === tasksAppId);
  });

  const getTablesForActiveTrack: ComputedRef<TasksTable[]> = computed(() => {
    const track: Track | undefined = tracksStore.activeTrack;
    const tablesToReturn: TasksTable[] = [];
    if (track) {
      const activeTrack = tracksStore.activeTrack;
      const deployedApp = deployedAppsStore.availableDeployedApps.find(
        da => da.environmentId === activeTrack?.environmentId);
      if (deployedApp) {
        return deployedApp.currentRelease.tables;
      }
      // If we're a referenced app we'll need to get the tables from the owning app
      const owningAppId: string = track.owningMiniAppId ?? DEFAULT_COLLABORATION_APP_ID;
      const owningApp = referencedTenantApps.value.find(app => app.id === owningAppId);
      if (owningApp) {
        const topLevelTables = getDevelopmentTablesForTenantApps.value.filter((table: TasksTable) =>
          table.miniAppId === owningAppId);
        tablesToReturn.push(...topLevelTables);
      }
      // Add any legacy tables that are already per track
      if (track.id && tables.value[track.id]) {
        tablesToReturn.push(...Object.values(tables.value[track.id]));
      }
    }
    return tablesToReturn;
  });

  const tablesByEnvironment: ComputedRef<Record<string, TasksTable[]>> = computed(() => {
    const result: Record<string, TasksTable[]> = {};
    deployedAppsStore.availableDeployedApps.forEach(da => {
      result[da.environmentId] = da.currentRelease.tables;
    });
    return result;
  });

  const getTasksForActiveTrack: ComputedRef<Task[]> = computed(() => {
    const trackId: string | null = tracksStore.activeTrackId;
    if (trackId && tasks.value[trackId]) {
      return Object.values(tasks.value[trackId]);
    } else {
      return [];
    }
  });

  // Note: TaskTables just referring to the type here, doesn't relate to the tasks app
  const getDevelopmentTablesForTenantApps: ComputedRef<TasksTable[]> = computed(() => {
    // this.tables is listed by trackId
    // so null find all of the tenant level instances
    if (tables.value.null || tables.value.undefined) {
      const toReturn = [];
      if (tables.value.null) {
        toReturn.push(...Object.values(tables.value.null));
      }
      if (tables.value.undefined) {
        toReturn.push(...Object.values(tables.value.undefined));
      }
      return toReturn;
    }
    return [];
  });

  const allMiniAppViews: ComputedRef<MiniAppView[]> = computed(() => {
    const viewsToReturn = [];
    for (const trackId in views.value) {
      viewsToReturn.push(...Object.values(views.value[trackId]));
    }
    return viewsToReturn;
  });

  const viewsForTenantApps: ComputedRef<MiniAppView[]> = computed(() => {
    // this.views is listed by trackId
    // so find all of the tenant level instances using the hard-coded key
    if (views.value[TENANT_VIEWS_KEY]) {
      return Object.values(views.value[TENANT_VIEWS_KEY]);
    }
    return [];
  });

  const viewsForActiveTrack: ComputedRef<MiniAppView[]> = computed(() => {
    const track: Track | undefined = tracksStore.activeTrack;
    const viewsToReturn: MiniAppView[] = [];
    if (track) {
      // Add the reference to the top level views
      const owningAppId: string = track.owningMiniAppId ?? DEFAULT_COLLABORATION_APP_ID;
      const owningApp = referencedTenantApps.value.find(reference => reference.id === owningAppId);
      if (owningApp) {
        const topLevelViews = viewsForTenantApps.value.filter(view => view.miniAppId === owningAppId) ?? [];
        viewsToReturn.push(...topLevelViews);
      }
      if (track.id && views.value[track.id]) {
        viewsToReturn.push(...Object.values(views.value[track.id]));
      }
    }
    return viewsToReturn;
  });

  const getSortedTasksForActiveTrack: ComputedRef<Task[]> = computed(() => {
    const sortedTasks: Task[] = [];
    const activeTrackTableIds: string[] = [];
    const defaultAppId = getTasksMiniAppForTrack.value?.id;
    getTablesForActiveTrack.value.forEach((table) => {
      if (table.miniAppId === defaultAppId) {
        activeTrackTableIds.push(table.id);
      }
    });
    // We have to copy these out or vue will replace them from under with the server order,
    // us causing the list to jump while it re-sorts - this also ensures that the main list
    // doesn't get reordered
    getTasksForActiveTrack.value.forEach((task) => {
      if (activeTrackTableIds.includes(task.tableId)) {
        sortedTasks.push(task);
      }
    });

    sortedTasks.sort((task1, task2) => {
      if (task1.properties.__POSITION > task2.properties.__POSITION) {
        return 1;
      } else if (task1.properties.__POSITION < task2.properties.__POSITION) {
        return -1;
      } else {
        // They are the same, so use their table id to order them so it remains doesn't change
        return task1.tableId.localeCompare(task2.tableId);
      }
    });
    return sortedTasks;
  });

  const uiFlowsForActiveTrack: ComputedRef<UIFlow[]> = computed(() => {
    const trackUiFlows: UIFlow[] = [];
    for (const miniApp of miniAppsForActiveTrackExcludingTasks.value) {
      const appFlows: UIFlow[] = uiFlowsByAppId.value[miniApp.id];
      if (appFlows && appFlows.length) {
        trackUiFlows.push(...appFlows);
      }
    }
    return trackUiFlows;
  });

  const gridViewFormattingEnabled: ComputedRef<boolean> = computed(() => {
    // If we have added a new, unsaved row then it needs to be non-empty
    if (selectedGridRecords.value?.length) {
      if (isTempTask(selectedGridRecords.value[0])) {
        return selectedGridRecords.value[0].hasInput;
      }
    }
    return !!(selectedGridRecords.value.length && selectedGridAttributes.value.length);
  });

  // Note: only used by UIFlows
  const tablesForApp: ComputedRef<(appId: string) => TasksTable[]> = computed(() => {
    return (appId: string) => {
      const appTables: TasksTable[] = [];
      for (const trackId of Object.keys(tables.value)) {
        appTables.push(...Object.values(tables.value[trackId]).filter((table) => table.miniAppId === appId));
      }
      return appTables;
    };
  });

  function setMiniApps(details: { miniApps: MiniApp[]; fullRefresh: boolean; }): void {
    const appsToSet: { [trackId: string]: { [itemId: string]: MiniApp } } = details.fullRefresh ? {} : miniApps.value;
    for (const item of details.miniApps) {
      if (!item.environmentVariables) {
        item.environmentVariables = [];
      }
      let currentMiniApps: { [itemId: string]: MiniApp } = appsToSet[item.trackId];
      if (!currentMiniApps) {
        currentMiniApps = {};
        Vue.set(appsToSet, item.trackId, currentMiniApps);
      }
      setOrPatchObject(currentMiniApps, item.id, asRecord(item));
    }

    if (miniApps.value) {
      patchObject(miniApps.value, appsToSet, details.fullRefresh);
    } else {
      miniApps.value = appsToSet;
    }
  }

  function setTaskTables(details: { taskTables: TasksTable[]; fullRefresh: boolean; }): void {
    const tablesToSet: { [trackId: string]: { [itemId: string]: TasksTable } } =
      details.fullRefresh ? {} : tables.value;
    for (const item of details.taskTables) {
      let currentTaskTables: { [itemId: string]: TasksTable } = tablesToSet[item.trackId];
      if (!currentTaskTables) {
        currentTaskTables = {};
        Vue.set(tablesToSet, item.trackId, currentTaskTables);
      }
      // Issue #9114: Temp hack to set an ID to avoid a UI re-render
      item.schema?.forEach((attr: Attribute) => {
        if (!attr.id) {
          attr.id = attr.name;
        }
        parseFormula(attr, item.schema);
      });
      setOrPatchObject(currentTaskTables, item.id, asRecord(item));
    }

    if (tables.value) {
      patchObject(tables.value, tablesToSet, details.fullRefresh);
    } else {
      tables.value = tablesToSet;
    }
  }

  function setMiniAppViews(details: { miniAppViews: MiniAppView[]; fullRefresh: boolean; }): void {
    const viewsToSet: { [trackId: string]: { [itemId: string]: MiniAppView } } =
      details.fullRefresh ? {} : views.value;
    for (const item of details.miniAppViews) {
      const trackIdKey: string = item.trackId ?? TENANT_VIEWS_KEY;
      let currentViews: { [itemId: string]: MiniAppView } = viewsToSet[trackIdKey];
      if (!currentViews) {
        currentViews = {};
        Vue.set(viewsToSet, trackIdKey, currentViews);
      }
      setOrPatchObject(currentViews, item.id, asRecord(item));
    }

    if (views.value) {
      patchObject(views.value, viewsToSet, details.fullRefresh);
    } else {
      views.value = viewsToSet;
    }
  }

  function setTasks(details: { tasks: Task[]; fullRefresh: boolean; }): void {
    const tasksToSet: { [trackId: string]: { [itemId: string]: Task } } =
      details.fullRefresh ? {} : tasks.value;
    for (const item of details.tasks) {
      let currentTasks: { [itemId: string]: Task } = tasksToSet[item.trackId];
      if (!currentTasks) {
        currentTasks = {};
        Vue.set(tasksToSet, item.trackId, currentTasks);
      }
      setOrPatchObject(currentTasks, item.id, asRecord(item));
    }

    if (tasks.value) {
      patchObject(tasks.value, tasksToSet, details.fullRefresh);
    } else {
      tasks.value = tasksToSet;
    }
  }

  function setTask(task: Task): void {
    if (task.trackId) {
      let tasksForTrack: { [itemId: string]: Task } = tasks.value[task.trackId];
      if (!tasksForTrack) {
        tasksForTrack = {};
        Vue.set(tasks.value, task.trackId, tasksForTrack);
      }
      setOrPatchObject(tasksForTrack, task.id, asRecord(task));
    }
  }

  function removeTask(task: { id: string, trackId: string }): void {
    if (tasks.value[task.trackId] && tasks.value[task.trackId][task.id]) {
      Vue.delete(tasks.value[task.trackId], task.id);
    }
  }

  function setTable(table: TasksTable): void {
    let tablesForTrack: { [itemId: string]: TasksTable } = tables.value[table.trackId];
    if (!tablesForTrack) {
      tablesForTrack = {};
      Vue.set(tables.value, table.trackId, tablesForTrack);
    }
    // Issue #9114: Temp hack to set an ID to avoid a UI re-render
    table.schema?.forEach((attr: Attribute) => {
      if (!attr.id) {
        attr.id = attr.name;
      }
      parseFormula(attr, table.schema);
    });
    setOrPatchObject(tablesForTrack, table.id, asRecord(table));
  }

  function removeTable(table: TasksTable): void {
    if (tables.value[table.trackId] && tables.value[table.trackId][table.id]) {
      Vue.delete(tables.value[table.trackId], table.id);
    }
  }

  function setView(view: MiniAppView): void {
    const trackIdKey: string = view.trackId ?? TENANT_VIEWS_KEY;
    let viewsForTrack: { [viewId: string]: MiniAppView } = views.value[trackIdKey];
    if (!viewsForTrack) {
      viewsForTrack = {};
      Vue.set(views.value, trackIdKey, viewsForTrack);
    }
    setOrPatchObject(viewsForTrack, view.id, asRecord(view));
  }

  function removeView(view: MiniAppView): void {
    const trackIdKey: string = view.trackId ?? TENANT_VIEWS_KEY;
    if (views.value[trackIdKey] && views.value[trackIdKey][view.id]) {
      Vue.delete(views.value[trackIdKey], view.id);
    }
  }

  function setTheme(theme: MiniAppViewTheme): void {
    let themesForView: { [themeId: string]: MiniAppViewTheme } = themes.value[theme.miniAppViewId];
    if (!themesForView) {
      themesForView = {};
      Vue.set(themes.value, theme.miniAppViewId, themesForView);
    }
    setOrPatchObject(themesForView, theme.id, asRecord(theme));
  }

  function setConditionalOverrides(overrides: MiniAppViewConditionalOverrides): void {
    let conditionalOverridesForView: { [conditionalOverridesId: string]: MiniAppViewConditionalOverrides } =
    conditionalOverrides.value[overrides.miniAppViewId];
    if (!conditionalOverridesForView) {
      conditionalOverridesForView = {};
      Vue.set(conditionalOverrides.value, overrides.miniAppViewId, conditionalOverridesForView);
    }
    setOrPatchObject(conditionalOverridesForView, overrides.id, asRecord(overrides));
  }

  function setThemes(details: { themes: MiniAppViewTheme[]; fullRefresh: boolean; }): void {
    const themesToSet: { [viewId: string]: { [themeId: string]: MiniAppViewTheme } } =
      details.fullRefresh ? {} : themes.value;
    for (const item of details.themes) {
      let currentThemes: { [themeId: string]: MiniAppViewTheme } = themesToSet[item.miniAppViewId];
      if (!currentThemes) {
        currentThemes = {};
        Vue.set(themesToSet, item.miniAppViewId, currentThemes);
      }
      setOrPatchObject(currentThemes, item.id, asRecord(item));
    }

    patchObject(themes.value, themesToSet, details.fullRefresh);
  }

  function setConditionalOverridesDetails(
    details: { overrides: MiniAppViewConditionalOverrides[]; fullRefresh: boolean; }): void {
    const overridesToSet: { [viewId: string]: { [conditionalOverridesId: string]: MiniAppViewConditionalOverrides } } =
      details.fullRefresh ? {} : conditionalOverrides.value;
    for (const item of details.overrides) {
      let currentOverrides: { [conditionalOverridesId: string]: MiniAppViewConditionalOverrides } =
        overridesToSet[item.miniAppViewId];
      if (!currentOverrides) {
        currentOverrides = {};
        Vue.set(overridesToSet, item.miniAppViewId, currentOverrides);
      }
      setOrPatchObject(currentOverrides, item.id, asRecord(item));
    }

    patchObject(conditionalOverrides.value, overridesToSet, details.fullRefresh);
  }

  function setMiniApp(miniApp: MiniApp): void {
    let miniAppsForTrack: { [itemId: string]: MiniApp } = miniApps.value[miniApp.trackId];
    if (!miniAppsForTrack) {
      miniAppsForTrack = {};
      Vue.set(miniApps.value, miniApp.trackId, miniAppsForTrack);
    }
    setOrPatchObject(miniAppsForTrack, miniApp.id, asRecord(miniApp));
  }

  function removeMiniApp(miniApp: MiniApp): void {
    if (miniApps.value[miniApp.trackId] && miniApps.value[miniApp.trackId][miniApp.id]) {
      Vue.delete(miniApps.value[miniApp.trackId], miniApp.id);
    }
  }

  function addInProgressRequest<T>(details: { entityName: string, requestId: string; request: Promise<T> }): void {
    switch (details.entityName) {
      case 'Task':
        inProgressTaskRequests.value[details.requestId] = details.request as Promise<Task>;
        break;
      case 'TasksTable':
        inProgressTableRequests.value[details.requestId] = details.request as Promise<TasksTable>;
        break;
      case 'MiniApp':
        inProgressAppRequests.value[details.requestId] = details.request as Promise<MiniApp>;
        break;
      case 'UiFlows':
        inProgressUIFlowRequests.value[details.requestId] = details.request as Promise<UIFlow>;
        break;
      default:
        break;
    }
  }

  function removeInProgressRequest(details: { entityName: string, requestId: string }): void {
    switch (details.entityName) {
      case 'Task':
        delete inProgressTaskRequests.value[details.requestId];
        break;
      case 'TasksTable':
        delete inProgressTableRequests.value[details.requestId];
        break;
      case 'MiniApp':
        delete inProgressAppRequests.value[details.requestId];
        break;
      case 'UiFlows':
        delete inProgressUIFlowRequests.value[details.requestId];
        break;
      default:
        break;
    }
  }

  function setTasksInitialised(details: { trackId: string; initialised: boolean }): void {
    Vue.set(tasksInitialised.value, details.trackId, details.initialised);
  }

  function setTaskTablesInitialised(details: { trackId: string; initialised: boolean }): void {
    Vue.set(taskTablesInitialised.value, details.trackId, details.initialised);
  }

  function setMiniAppViewsInitialised(details: { trackId: string; initialised: boolean }): void {
    Vue.set(miniAppViewsInitialised.value, details.trackId, details.initialised);
  }

  function setMiniAppViewsInitialisedForApp(details: { miniAppId: string; initialised: boolean }): void {
    Vue.set(miniAppViewsInitialisedForApp.value, details.miniAppId, details.initialised);
  }

  function setMiniAppsInitialised(details: { trackId: string; initialised: boolean }): void {
    Vue.set(miniAppsInitialised.value, details.trackId, details.initialised);
  }

  function setTenantMiniAppsInitialised(): void {
    tenantMiniAppsInitialised.value = true;
  }

  function setThemesInitialisedForMiniApp(details: { miniAppId: string, initialised: boolean }): void {
    Vue.set(themesInitialisedForMiniApp.value, details.miniAppId, details.initialised);
  }

  function setConditionalOverridesInitialisedForMiniApp(details: { miniAppId: string, initialised: boolean }): void {
    Vue.set(conditionalOverridesInitialisedForMiniApp.value, details.miniAppId, details.initialised);
  }

  function setNoUiFlows(miniAppId: string): void {
    Vue.set(uiFlowsByAppId.value, miniAppId, []);
  }

  function resetUiFlows(): void {
    uiFlowsByAppId.value = {};
  }

  function setUiFlow(details: { uiFlow: UIFlow }): void {
    const uiFlow = details.uiFlow;
    let appFlows = uiFlowsByAppId.value[uiFlow.appId];
    if (!appFlows) {
      appFlows = [];
      Vue.set(uiFlowsByAppId.value, uiFlow.appId, appFlows);
    }
    let existingObject: UIFlow | undefined;
    if (uiFlow.id) {
      existingObject = appFlows.find((flow: UIFlow) => flow.id === uiFlow.id);
    }
    if (existingObject) {
      patchObject(asRecord(existingObject), asRecord(uiFlow));
    } else {
      // Push the same object to both arrays - we can just update the one then
      appFlows.push(uiFlow);
      uiFlows.value.push(uiFlow);
    }
  }

  function removeUiFlowById(payload: { appId: string, flowId: string }): void {
    const { appId, flowId } = payload;
    if (appId) {
      const appFlows = uiFlowsByAppId.value[appId];
      const index = appFlows.findIndex((flow: UIFlow) => flow.id === flowId);
      if (index !== -1) {
        appFlows.splice(index, 1);
      }
    }

    // Remove from the flat list as well
    const index = uiFlows.value.findIndex((flow: UIFlow) => flow.id === flowId);
    if (index !== -1) {
      uiFlows.value.splice(index, 1);
    }
  }

  function setSelectedGridRecords(selectedGridRecordsToSet: Array<Task | TempTask>): void {
    selectedGridRecords.value = selectedGridRecordsToSet;
  }

  function setSelectedGridAttributes(selectedGridAttributesToSet: Attribute[]): void {
    selectedGridAttributes.value = selectedGridAttributesToSet;
  }

  async function removeUiFlow(uiFlow: UIFlow): Promise<void> {
    if (!uiFlow.id) {
      return;
    }
    removeUiFlowById({ appId: uiFlow.appId, flowId: uiFlow.id });
  }

  function setUiFlows(details: { uiFlows: UIFlow[]; fullRefresh: boolean; }): void {
    if (details.fullRefresh) {
      resetUiFlows();
    }
    for (const uiFlow of details.uiFlows) {
      setUiFlow({ uiFlow });
    }
  }

  async function addTask(task: Task): Promise<Task | undefined> {
    // This is a prep stage, where we immediately add the object to the Pinia store in a way that 'looks' consistent
    // with what the user requested. e.g. on the grid view it is naturally ordered at the end.
    let tmpId: string;
    let tmpObject: Task;
    if (task.id?.startsWith(TMP_TASK_PREFIX)) {
      // Cannot add a pending temporary task
      return;
    }
    if (!task.trackId) {
      // Cannot create task without track id
      return;
    }
    if (userStore.isGuestMember(task.trackId)) {
      return;
    }
    if (!task.id) {
      // We give it a clear temporary ID
      tmpId = `${TMP_TASK_PREFIX}${Date.now()}-${Math.random()}`;
      // Create a temporary object from the one we send to the server - we don't want to corrupt that.
      tmpObject = cloneTask(task);
      tmpObject.id = tmpId;
      Vue.set(tmpObject.properties, '__CREATED', new Date().getTime());

      // The server sets the position as 1 greater than the number of previously created records. For now just make
      // it the highest that we have
      const positions: number[] = Object.values(tasks.value[task.trackId] ?? {}).map((task: Task) => {
        return Number.parseInt(task.properties?.__POSITION ?? 0);
      });
      const position: number = (Math.max(...positions, 0) ?? 0) + 1;
      Vue.set(tmpObject.properties, '__POSITION', position);
      // Set the temporary object into the store
      setTask(tmpObject);
    }

    // Now we actually request that the task is created
    const request: Promise<Task> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Tasks/addTask', task).then((newTask: Task) => {
        // If we created a temporary object, then we need to swap that out with the real one
        if (tmpObject) {
          // Path the object itself for any live references
          patchObject(asRecord(tmpObject), asRecord(newTask));
          // It's held in a record using ID as key - need to shuffle it to the new ID
          // Ideally we'd not key on ID and this could be removed, but it seems to not cause an issue atm
          setTask(tmpObject);
          removeTask({ id: tmpId, trackId: tmpObject.trackId });
        } else {
          setTask(newTask);
        }
        resolve(newTask);
      }).catch((error) => {
        // If it failed to save, then remove the temporary task if we added one
        if (tmpObject) {
          removeTask({ id: tmpId, trackId: tmpObject.trackId });
        }
        reject(error);
      });
    });

    return await performMonitoredRequest<Task>('Task', request);
  }

  async function performMonitoredRequest<T>(entityName: string, request: Promise<T>): Promise<T | undefined> {
    const requestId: string = uuid();
    addInProgressRequest<T>({ entityName, requestId, request });
    try {
      return await request;
    } catch (error) {
      log.error(`Failed to perform ${entityName} request: ${error}`);
    } finally {
      removeInProgressRequest({ entityName, requestId });
    }
  }

  async function refreshTaskRulesetAttributes(details: { taskId: string, attributeName: string }): Promise<void> {
    DataWorker.instance().dispatch('Tasks/refreshTaskRuleset', details.taskId, details.attributeName);
  }

  async function restoreTask(task: Task): Promise<Task | undefined> {
    if (userStore.isGuestMember(task.trackId)) {
      return;
    }
    setTask(task);
    const request: Promise<Task> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Tasks/restoreTask', task).then((restoredTask: Task) => {
        setTask(restoredTask);
        resolve(restoredTask);
      }).catch((error) => {
        reject(error);
      });
    });

    return await performMonitoredRequest<Task>('Task', request);
  }

  async function updateTask(task: Task): Promise<Task | undefined> {
    if (task.id?.startsWith(TMP_TASK_PREFIX)) {
      // Cannot update a pending temporary task
      return;
    }
    if (userStore.isGuestMember(task.trackId)) {
      return;
    }
    // Clone the existing task in case the update fails
    const inStore: Task | undefined = (tasks.value[task.trackId] ?? {})[task.id];
    let preUpdate: Task | undefined;
    if (inStore) {
      preUpdate = cloneTask(inStore);
    }

    // Update the Pinia store immediately, then make the request of the shared worker
    setTask(task);
    const request: Promise<Task> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Tasks/updateTask', task).then((updatedTask: Task) => {
        setTask(updatedTask);
        resolve(updatedTask);
      }).catch((error) => {
        // If it failed, then set it back how it was
        if (preUpdate) {
          setTask(preUpdate);
        }
        reject(error);
      });
    });

    return await performMonitoredRequest<Task>('Task', request);
  }

  async function updateTasks(tasksToUpdate: Task[]): Promise<Task[] | undefined> {
    tasksToUpdate = tasksToUpdate.filter(t => !t.id?.startsWith(TMP_TASK_PREFIX));
    // Clone the existing tasks in case the update fails
    const preUpdate: Task[] = [];
    for (const task of tasksToUpdate) {
      const inStore: Task | undefined = (tasks.value[task.trackId] ?? {})[task.id];
      if (inStore) {
        preUpdate.push(cloneTask(inStore));
      }
    }

    // Update the Pinia store immediately, then make the request of the shared worker
    setTasks({ tasks: tasksToUpdate, fullRefresh: false });
    const request: Promise<Task[]> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Tasks/updateTasks', tasksToUpdate).then((updatedTasks: Task[]) => {
        setTasks({ tasks: updatedTasks, fullRefresh: false });
        resolve(updatedTasks);
      }).catch((error) => {
        // If it failed, then set it back how it was
        setTasks({ tasks: preUpdate, fullRefresh: false });
        reject(error);
      });
    });

    return await performMonitoredRequest<Task[]>('Task', request);
  }

  async function deleteTask(task: Task): Promise<void> {
    if (userStore.isGuestMember(task.trackId)) {
      return;
    }
    removeTask(task);
    const request: Promise<void> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Tasks/deleteTask', task).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });

    await performMonitoredRequest<void>('Task', request);
  }

  async function addApp(app: MiniApp): Promise<MiniApp | undefined> {
    const request: Promise<MiniApp> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('MiniApps/addMiniApp', app).then((newApp: MiniApp) => {
        setMiniApp(newApp);
        resolve(newApp);
      }).catch((error) => {
        reject(error);
      });
    });

    return await performMonitoredRequest<MiniApp>('MiniApp', request);
  }

  async function updateApp(app: MiniApp): Promise<MiniApp | undefined> {
    const request: Promise<MiniApp> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('MiniApps/updateMiniApp', app).then((updatedApp: MiniApp) => {
        setMiniApp(updatedApp);
        resolve(updatedApp);
      }).catch((error) => {
        reject(error);
      });
    });

    return await performMonitoredRequest<MiniApp>('MiniApp', request);
  }

  async function deleteApp(app: MiniApp): Promise<void> {
    const request: Promise<void> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('MiniApps/deleteMiniApp', app).then(() => {
        removeMiniApp(app);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });

    await performMonitoredRequest<void>('MiniApp', request);
  }

  async function addTable(table: TasksTable): Promise<TasksTable | undefined> {
    const request: Promise<TasksTable> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('TasksTables/addTable', table).then((newTable: TasksTable) => {
        setTable(newTable);
        resolve(newTable);
      }).catch((error) => {
        reject(error);
      });
    });

    return await performMonitoredRequest<TasksTable>('TasksTable', request);
  }

  async function updateTable(table: TasksTable): Promise<TasksTable | undefined> {
    setTable(table);
    const request: Promise<TasksTable> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('TasksTables/updateTable', table).then((updatedTable: TasksTable) => {
        setTable(updatedTable);
        resolve(updatedTable);
      }).catch((error) => {
        reject(error);
      });
    });

    return await performMonitoredRequest<TasksTable>('TasksTable', request);
  }

  async function deleteTable(table: TasksTable): Promise<void> {
    removeTable(table);
    const request: Promise<void> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('TasksTables/deleteTable', table).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });

    await performMonitoredRequest<void>('TasksTable', request);
  }

  async function renameProperty(details: { tableId: string, property: string, to: string }): Promise<void> {
    await DataWorker.instance().dispatch('TasksTables/renameProperty', details);
  }

  async function addView(view: MiniAppViewPayload): Promise<MiniAppView> {
    const newView: MiniAppView = await DataWorker.instance().dispatch('MiniAppViews/addView', view);
    setView(newView);
    return newView;
  }

  async function updateView(view: MiniAppViewPayload): Promise<MiniAppView> {
    const updatedView: MiniAppView = await DataWorker.instance().dispatch('MiniAppViews/updateView', view);
    setView(updatedView);
    if (view.previousViewName) {
      // This really should be done server-side, but all the server has is a blob of config that it doesn't understand.
      const uiFlowsForApp = uiFlowsByAppId.value[view.miniAppId] ?? [];
      for (const uiFlow of uiFlowsForApp) {
        let uiFlowUpdated: boolean = false;
        const config: FlowConfig = load(uiFlow.config) as FlowConfig;
        for (const page of config.pages) {
          if (page.view === view.previousViewName) {
            page.view = view.name;
            uiFlowUpdated = true;
          }
        }
        if (uiFlowUpdated) {
          uiFlow.config = dump(config);
          saveUiFlow(uiFlow);
        }
      }
    }
    return updatedView;
  }

  async function deleteView(view: MiniAppView): Promise<void> {
    removeView(view);
    await DataWorker.instance().dispatch('MiniAppViews/deleteView', view);
  }

  function areThemeConfigsEqual(firstConfigString: string, secondConfigString: string): boolean {
    try {
      const firstConfig = JSON.parse(firstConfigString);
      const secondConfig = JSON.parse(secondConfigString);
      if (isEqual(firstConfig, secondConfig)) {
        return true;
      }
    } catch (err) {
      log.error('Failed to parse theme config JSON', err);
    }
    return false;
  }

  async function addOrUpdateMiniAppViewTheme(theme: MiniAppViewThemePayload): Promise<MiniAppViewTheme> {
    if (theme.id) {
      // Update our local state now, assuming that the save will succeed.
      const existingTheme: MiniAppViewTheme | undefined = themes.value[theme.miniAppViewId]?.[theme.id];
      if (existingTheme) {
        // If it hasn't changed then don't ask the server to update it, which would create a new version
        if (areThemeConfigsEqual(existingTheme.config, theme.config)) {
          return existingTheme;
        }
        existingTheme.config = theme.config;
      }
    }
    const updated: MiniAppViewTheme = await DataWorker.instance().dispatch('MiniAppViewThemes/addOrUpdateTheme', theme);
    setTheme(updated);
    return updated;
  }

  async function updateViewAndSupportingModels(viewId: string, viewAndSupportingModels: ViewAndSupportingModels):
    Promise<void> {
    const viewAndSupportingModelsResponse: ViewAndSupportingModelsResponse = await DataWorker.instance().dispatch(
      'Tasks/updateViewAndSupportingModels', viewId, viewAndSupportingModels);
    if (viewAndSupportingModelsResponse) {
      if (viewAndSupportingModelsResponse.updatedView) {
        setView(viewAndSupportingModelsResponse.updatedView);
        const viewPayload = viewAndSupportingModels.view;
        if (viewPayload?.previousViewName) {
          // This really should be done server-side, but all the server has is a blob of config that it
          // doesn't understand.
          const uiFlowsForApp = uiFlowsByAppId.value[viewPayload.miniAppId] ?? [];
          for (const uiFlow of uiFlowsForApp) {
            let uiFlowUpdated: boolean = false;
            const config: FlowConfig = load(uiFlow.config) as FlowConfig;
            for (const page of config.pages) {
              if (page.view === viewPayload.previousViewName) {
                page.view = viewPayload.name;
                uiFlowUpdated = true;
              }
            }
            if (uiFlowUpdated) {
              uiFlow.config = dump(config);
              saveUiFlow(uiFlow);
            }
          }
        }
      }
      if (viewAndSupportingModelsResponse.createdConditionalOverrides) {
        for (const addedConditionalOverrides of viewAndSupportingModelsResponse.createdConditionalOverrides) {
          setConditionalOverrides(addedConditionalOverrides);
        }
      }
      if (viewAndSupportingModelsResponse.updatedConditionalOverrides) {
        for (const updatedConditionalOverrides of viewAndSupportingModelsResponse.updatedConditionalOverrides) {
          setConditionalOverrides(updatedConditionalOverrides);
        }
      }
      if (viewAndSupportingModelsResponse.createdThemes) {
        for (const addedTheme of viewAndSupportingModelsResponse.createdThemes) {
          setTheme(addedTheme);
        }
      }
      if (viewAndSupportingModelsResponse.updatedThemes) {
        for (const updatedTheme of viewAndSupportingModelsResponse.updatedThemes) {
          setTheme(updatedTheme);
        }
      }
    }
  }

  async function waitForInProgressUpdates(entityName: string): Promise<void> {
    let inProgressUpdates: Promise<unknown>[] = [];
    switch (entityName) {
      case 'Task':
        inProgressUpdates = Object.values(inProgressTaskRequests.value);
        break;
      case 'TasksTable':
        inProgressUpdates = Object.values(inProgressTableRequests.value);
        break;
      case 'MiniApp':
        inProgressUpdates = Object.values(inProgressAppRequests.value);
        break;
      case 'UiFlows':
        inProgressUpdates = Object.values(inProgressUIFlowRequests.value);
        break;
      default:
        break;
    }

    if (inProgressUpdates.length) {
      try {
        await Promise.all(inProgressUpdates);
      } catch (error) {
        // The error will be logged by the action that triggered the update
      }
    }
  }

  function setMiniAppsForTrack(details: { trackId: string; miniApps: MiniApp[] }): void {
    const existingMiniAppsForTrack: Record<string, MiniApp> = miniApps.value[details.trackId] || {};
    for (const miniApp of Object.values(existingMiniAppsForTrack)) {
      if (!details.miniApps.find((current: MiniApp) => current.id === miniApp.id)) {
        removeMiniApp(miniApp);
      }
    }
    setMiniApps({ miniApps: details.miniApps, fullRefresh: false });
  }

  function setTaskTablesForTrack(details: { trackId: string; taskTables: TasksTable[] }): void {
    const existingTablesForTrack: Record<string, TasksTable> = tables.value[details.trackId] || {};
    for (const table of Object.values(existingTablesForTrack)) {
      if (!details.taskTables.find((current: TasksTable) => current.id === table.id)) {
        removeTable(table);
      }
    }
    setTaskTables({ taskTables: details.taskTables, fullRefresh: false });
  }

  function setMiniAppViewsForTrack(details: { trackId: string; miniAppViews: MiniAppView[] }): void {
    const existingViewsForTrack: Record<string, MiniAppView> = views.value[details.trackId] || {};
    for (const view of Object.values(existingViewsForTrack)) {
      if (!details.miniAppViews.find((current: MiniAppView) => current.id === view.id)) {
        removeView(view);
      }
    }
    setMiniAppViews({ miniAppViews: details.miniAppViews, fullRefresh: false });
  }

  function setTasksForTrack(details: { trackId: string; tasks: Task[] }): void {
    const existingTasksForTrack: Record<string, Task> = tasks.value[details.trackId] || {};
    for (const task of Object.values(existingTasksForTrack)) {
      if (!details.tasks.find((current: Task) => current.id === task.id)) {
        removeTask(task);
      }
    }
    setTasks({ tasks: details.tasks, fullRefresh: false });
  }

  async function saveUiFlow(uiFlow: UIFlow): Promise<UIFlow> {
    let tmpId: string | undefined;
    let toStore = uiFlow;
    if (!uiFlow.id) {
      toStore = Object.assign({}, uiFlow);
      tmpId = `tmp-${Date.now()}-${Math.random()}`;
      Vue.set(uiFlow, 'id', tmpId);
    }
    setUiFlow({ uiFlow });
    try {
      const updated: UIFlow = await DataWorker.instance().dispatch('UIFlows/saveUiFlow', toStore);
      setUiFlow({ uiFlow: updated });
      if (tmpId) {
        removeUiFlowById({ appId: uiFlow.appId, flowId: tmpId });
      }
      return updated;
    } catch (err) {
      throw new Error(`Failed to save UI Flow: ${err}`);
    }
  }

  async function registerInterestInActiveTrackAppUIFlows(): Promise<void> {
    for (const miniApp of miniAppsForActiveTrackExcludingTasks.value) {
      try {
        registerInterestInMiniAppUIFlows(miniApp.id);
      } catch (err) {
        log.error(`Failed to register interest in UI flows for app ${miniApp.id} - ${err}`);
      }
    }
  }

  async function registerInterestInMiniAppUIFlows(miniAppId: string): Promise<void> {
    // uiFlowsByAppId isn't rehydrated from the IndexedDB on load, so having a record in this object means that we have
    // already started watching this mini app in this browsing session and are already watching for updates.
    // The shared worker will only keep polling for a limited time after we express interest - if we haven't requested
    // in the past hour then allow the request through. This is a temporary mechanism added by #10024 until we have a
    // deregistration mechanism.
    const now: number = Date.now();
    if (uiFlowsByAppId.value[miniAppId] && uiFlowRequestTimesByAppId.value[miniAppId] > (now - 3_600_000)) {
      return;
    }
    uiFlowRequestTimesByAppId.value[miniAppId] = now;
    try {
      const flows: UIFlow[] = await DataWorker.instance().dispatch('UIFlows/getUiFlowsForMiniApp', miniAppId);
      if (!flows?.length) {
        // Set something in the place to avoid duplicate calls. We're already watching for changes at this point
        setNoUiFlows(miniAppId);
      } else {
        for (const uiFlow of flows) {
          setUiFlow({ uiFlow });
        }
      }
    } catch (err) {
      throw new Error(`Failed to register interest in UI Flows: ${err}`);
    }
  }

  async function deleteUiFlow(uiFlow: UIFlow): Promise<void> {
    if (!uiFlow) {
      return;
    }
    try {
      await DataWorker.instance().dispatch('UIFlows/deleteUiFlow', uiFlow);
      removeUiFlow(uiFlow);
    } catch (err) {
      throw new Error(`Failed to delete UI Flow: ${err}`);
    }
  }

  async function requestAppExport(details: { appId: string, includeData: boolean }): Promise<void> {
    const downloadUrl: string = await DataWorker.instance().dispatch('MiniApps/getAppExportUrl', details.appId,
      details.includeData);
    triggerFileDownload(downloadUrl);
  }

  async function requestTableDataExport(details: TableDataExportDetails): Promise<void> {
    const downloadUrl: string = await DataWorker.instance().dispatch('TasksTables/getTableDataExportUrl',
      details.trackId, details.tableId, details.firstRowHeaders);
    triggerFileDownload(downloadUrl);
  }

  async function importApp(payload: AppImportPayload): Promise<ImportAppResponse | undefined> {
    const workerArgResolver = (file: File) => {
      return [payload.trackId, file.name, payload.newAppName, payload.newAppDescription,
        payload.includeData];
    };
    const uploadResults: FileUploadResult[] = [];
    const uploadPromises: Array<Promise<ImportAppResponse>> = await setupFileUploadCalls(payload,
      uploadResults, 'MiniApps/importApp', workerArgResolver);
    await Promise.all(uploadPromises);
    if (payload.onFinishedCallback) {
      payload.onFinishedCallback(uploadResults);
    }
    // Although the payload allows for multiple files, we only actually upload one file at a time.
    if (uploadResults.length) {
      if (uploadResults[0].status === 200) {
        return uploadResults[0].response as ImportAppResponse;
      } else {
        return { errorDescription: 'Unable to import app' };
      }
    }
  }

  async function addTables(details: { appId: string, tables: Record<string, unknown> }): Promise<unknown> {
    return await DataWorker.instance().dispatch('MiniApps/addTables', details.appId, details.tables);
  }

  async function importDataToTable(payload: TableDataImportPayload): Promise<boolean> {
    const workerArgResolver = (file: File) => {
      return [payload.trackId, payload.tableId, file.name, payload.firstRowHeaders, payload.delimiter];
    };
    const uploadResults: FileUploadResult[] = [];
    const uploadPromises: Array<Promise<void>> = await setupFileUploadCalls(payload, uploadResults,
      'TasksTables/importDataToTable', workerArgResolver);
    await Promise.all(uploadPromises);
    if (payload.onFinishedCallback) {
      payload.onFinishedCallback(uploadResults);
    }
    // Although the payload allows for multiple files, we only actually upload one file at a time.
    if (uploadResults.length) {
      if (uploadResults[0].status === 200) {
        return true;
      }
    }
    return false;
  }

  async function updateTableAttribute(request: UpdateTableAttributeRequest):
    Promise<void> {
    await DataWorker.instance().dispatch('TasksTables/updateTableAttribute', request);
  }

  async function requestAllTasksForTracks(): Promise<void> {
    await DataWorker.instance().dispatch('Tasks/requestAllTasksForTracks');
  }

  async function getCustomViewDetails(trackId: string, viewId: string, guestId?: string): Promise<CustomViewDetails> {
    if (customViewDetails.value[trackId]?.[viewId]) {
      return customViewDetails.value[trackId][viewId];
    }
    const details: CustomViewDetails = await DataWorker.instance().dispatch(
      'Tasks/getCustomViewDetails', trackId, viewId, guestId);
    let detailsForTrack: { [viewId: string]: CustomViewDetails } | undefined = customViewDetails.value[trackId];
    if (!detailsForTrack) {
      detailsForTrack = {};
      Vue.set(customViewDetails.value, trackId, detailsForTrack);
    }
    Vue.set(detailsForTrack, viewId, details);
    return details;
  }

  function parseFormula(attr: Attribute, schema: Attribute[]) {
    if (attr.type === AttributeType.FORMULA &&
      attr.options && attr.options[FormulaAttributeOptionType.FORMULA]) {
      const formula = attr.options[FormulaAttributeOptionType.FORMULA];
      try {
        JSON.parse(formula);
      } catch (error) {
        try {
          const expression = parseExpression(formula, schema);
          attr.options[FormulaAttributeOptionType.FORMULA] = JSON.stringify(expression);
        } catch (error) {
        }
      }
    }
  }

  async function getTablesAsGuest(guestId: string): Promise<TasksTable[]> {
    const tables: TasksTable[] = await DataWorker.instance().dispatch('TasksTables/getTablesAsGuest', guestId);
    setTaskTables({ taskTables: tables, fullRefresh: true });
    return tables;
  }

  async function getTasksAsGuest(guestId: string): Promise<Task[]> {
    const tasks: Task[] = await DataWorker.instance().dispatch('Tasks/getTasksAsGuest', guestId);
    setTasks({ tasks, fullRefresh: true });
    return tasks;
  }

  async function getMiniAppAsGuest(guestId: string): Promise<MiniApp> {
    const miniApp: MiniApp = await DataWorker.instance().dispatch('Tasks/getMiniAppAsGuest', guestId);
    setMiniApp(miniApp);
    return miniApp;
  }

  async function getThemesAsGuest(guestId: string, miniAppId: string): Promise<MiniAppViewTheme[]> {
    const themes: MiniAppViewTheme[] =
      await DataWorker.instance().dispatch('MiniAppViewThemes/getThemesAsGuest', guestId);
    setThemes({ themes, fullRefresh: true });
    setThemesInitialisedForMiniApp({ miniAppId, initialised: true });
    return themes;
  }

  async function getAppAndSupportingModelsForGuest(guestId: string, miniAppId: string):
    Promise<GuestAppAndSupportingModels> {
    const guestAppAndSupportingModels: GuestAppAndSupportingModels =
      await DataWorker.instance().dispatch('Tasks/getGuestAppAndSupportingModels', guestId);
    if (guestAppAndSupportingModels) {
      setTaskTables({ taskTables: guestAppAndSupportingModels.tables, fullRefresh: true });
      setTasks({ tasks: guestAppAndSupportingModels.tasks, fullRefresh: true });
      if (guestAppAndSupportingModels.miniApp) {
        setMiniApp(guestAppAndSupportingModels.miniApp);
      }
      setThemes({ themes: guestAppAndSupportingModels.themes, fullRefresh: true });
      setThemesInitialisedForMiniApp({ miniAppId, initialised: true });
      setConditionalOverridesDetails({
        overrides: guestAppAndSupportingModels.conditionalOverrides,
        fullRefresh: true
      });
      setConditionalOverridesInitialisedForMiniApp({ miniAppId, initialised: true });
    }

    return guestAppAndSupportingModels;
  }

  async function populateDependenciesForUIFlow(trackId: string, uiFlowId: string): Promise<void> {
    const tracksStore = useTracksStore();
    const trackRequired: boolean = !tracksStore.tracks[trackId];
    let appRequired: boolean = true;
    let uiFlowRequired: boolean = true;
    let viewsRequired: boolean = true;
    const uiFlow: UIFlow | undefined = uiFlows.value.find((current) => current.id === uiFlowId);
    if (uiFlow) {
      uiFlowRequired = false;
      let miniApp: MiniApp | undefined = tenantLevelAppsById.value[uiFlow.appId];
      if (!miniApp) {
        const miniAppsForTrack = miniApps.value[trackId];
        if (miniAppsForTrack) {
          miniApp = miniAppsForTrack[uiFlow.appId];
        }
      }
      if (miniApp) {
        const miniAppId = miniApp.id;
        appRequired = false;
        // Assume that if we have any views for the app then we have them all.
        let viewsForApp: MiniAppView[] | undefined = viewsForTenantApps.value.filter(
          (view) => view.miniAppId === miniAppId);
        if (!viewsForApp.length) {
          const viewsForTrack = views.value[trackId];
          if (viewsForTrack) {
            viewsForApp = Object.values(viewsForTrack).filter((view) => view.miniAppId === miniAppId);
          }
        }
        if (viewsForApp.length) {
          viewsRequired = false;
        }
      }
    }

    if (trackRequired || uiFlowRequired || appRequired || viewsRequired) {
      const dataWorker = await DataWorker.waitForInstance();
      const dependencies: UIFlowDependenciesResponse =
        await dataWorker.dispatch('UIFlows/getUIFlowDependencies', trackId, uiFlowId, trackRequired);
      if (dependencies.miniApp) {
        setMiniApp(dependencies.miniApp);
      }
      for (const view of dependencies.views) {
        setView(view);
      }
      if (dependencies.uiFlow) {
        setUiFlow({ uiFlow: dependencies.uiFlow });
      }
      if (dependencies.track) {
        tracksStore.setTracks([dependencies.track]);
      }
    }
  }

  return {
    addOrUpdateMiniAppViewTheme,
    updateViewAndSupportingModels,
    addTables,
    miniApps,
    tables,
    tasks,
    themes,
    conditionalOverrides,
    uiFlowsByAppId,
    uiFlows,
    selectedGridRecords,
    selectedGridAttributes,
    tasksInitialised,
    taskTablesInitialised,
    miniAppViewsInitialised,
    miniAppViewsInitialisedForApp,
    miniAppsInitialised,
    tenantMiniAppsInitialised,
    activeTask,
    allMiniAppsById,
    getAllTasks,
    tablesByEnvironment,
    allTasksTables,
    miniAppsForActiveTrack,
    miniAppsAddedToActiveTrackExcludingTasks,
    miniAppsForActiveTrackExcludingTasks,
    interactableAppsForActiveTrack,
    getTasksMiniAppForTrack,
    tenantLevelAppsById,
    tenantApps,
    referencedTenantApps,
    availableAppTemplatesToCopy,
    appsWithTracks,
    tracksPerApp,
    tenantAppsVisibleToUser,
    getTaskTablesForActiveTrack,
    getTablesForActiveTrack,
    getTasksForActiveTrack,
    getDevelopmentTablesForTenantApps,
    allMiniAppViews,
    viewsForTenantApps,
    viewsForActiveTrack,
    getSortedTasksForActiveTrack,
    uiFlowsForActiveTrack,
    gridViewFormattingEnabled,
    themesInitialisedForMiniApp,
    conditionalOverridesInitialisedForMiniApp,
    setMiniApps,
    setTaskTables,
    setMiniAppViews,
    setTasks,
    setView,
    setTasksInitialised,
    setTaskTablesInitialised,
    setMiniAppViewsInitialised,
    setMiniAppViewsInitialisedForApp,
    setMiniAppsInitialised,
    setTenantMiniAppsInitialised,
    setSelectedGridRecords,
    setSelectedGridAttributes,
    setUiFlows,
    addTask,
    refreshTaskRulesetAttributes,
    restoreTask,
    updateTask,
    updateTasks,
    deleteTask,
    addApp,
    updateApp,
    deleteApp,
    addTable,
    updateTable,
    deleteTable,
    renameProperty,
    addView,
    updateView,
    deleteView,
    waitForInProgressUpdates,
    setMiniAppsForTrack,
    setTaskTablesForTrack,
    setMiniAppViewsForTrack,
    setTasksForTrack,
    saveUiFlow,
    registerInterestInActiveTrackAppUIFlows,
    registerInterestInMiniAppUIFlows,
    deleteUiFlow,
    requestAppExport,
    requestTableDataExport,
    importApp,
    importDataToTable,
    updateTableAttribute,
    requestAllTasksForTracks,
    getCustomViewDetails,
    getTablesAsGuest,
    getTasksAsGuest,
    getMiniAppAsGuest,
    getThemesAsGuest,
    setThemes,
    setThemesInitialisedForMiniApp,
    setConditionalOverridesDetails,
    setConditionalOverridesInitialisedForMiniApp,
    populateDependenciesForUIFlow,
    getAppAndSupportingModelsForGuest,
  };
});
