/**
 * These functions allow storing, reading and deleting of items from storage. Memory only storage will not survive a
 * browser refresh whereas if the memoryOnly flag is false or not set then localStorage will be used.
 */

const tokens: Record<string, string> = {};

function putAppAuthTokenInStorage(memoryOnly: boolean, key: string, value: string): void {
  if (memoryOnly) {
    tokens[key] = value;
  } else {
    localStorage.setItem(key, value);
  }
}

function getAppAuthTokenFromStorage(memoryOnly: boolean, key: string): string | null {
  if (memoryOnly) {
    const tokensVal = tokens[key];
    if (tokensVal === undefined) {
      return null;
    }
    return tokensVal;
  } else {
    return localStorage.getItem(key);
  }
}

function removeAppAuthTokenFromStorage(memoryOnly: boolean, key: string): void {
  if (memoryOnly) {
    delete tokens[key];
  } else {
    localStorage.removeItem(key);
  }
}

export {
  getAppAuthTokenFromStorage,
  putAppAuthTokenInStorage,
  removeAppAuthTokenFromStorage,
};
