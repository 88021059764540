export enum SearchLocation {
  ALL = 'ALL',
  TRACKS = 'TRACKS',
  CHATS = 'CHATS',
  ENTRIES = 'ENTRIES',
  TASKS = 'TASKS',
  APPS = 'APPS',
  KNOWLEDGE = 'KNOWLEDGE',
}

export enum SearchDate {
  ANY = 'ANY',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_SEVEN_DAYS = 'LAST_SEVEN_DAYS',
  LAST_THIRTY_DAYS = 'LAST_THIRTY_DAYS',
  LAST_NINETY_DAYS = 'LAST_NINETY_DAYS',
  CUSTOM = 'CUSTOM',
}

export interface SemanticSearchQuery {
  location: SearchLocation;
  searchTerm?: string;
  tenantId?: string;
  trackId?: string;
  metadata?: {
    entryId?: string;
    title?: string;
    subtype?: string;
    createdDate?: string;
    authorId?: string;
  };
  sortBy?: string;
  sortOrder?: string;
  page?: number;
  rows?: number;
  dateQuery: SearchDate,
  d1?: number,
  d2?: number,
  tz?: string,
}
