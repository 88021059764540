import { isEqual } from 'lodash-es';
import { computed, ref, watch, watchEffect } from 'vue';

import { useRoute, useRouter } from '@/composables/router/VueRouter';
import Integrations from '@/data/config/Integrations';
import { Track } from '@/data/datatypes/Track';
import { FullUserDetails } from '@/data/datatypes/UserDetails';
import { analyticsLog } from '@/data/log/AnalyticsLog';
import { MiniApp } from '@/data/tasks/MiniApp';
import RouteNames from '@/router/RouteNames';
import { useDeployedAppsStore } from '@/stores/release/DeployedApps';
import { useTasksStore } from '@/stores/Tasks';
import { useTracksStore } from '@/stores/Tracks';
import { useUserStore } from '@/stores/User';

type AnalyticsEventCorePayload = {
  url: string;
  analyticsSiteId: number;
  referrerUrl?: string;
  appId?: string;
  userId?: string;
  pageTitle?: string;
}

/**
 * Set the duration of how long a page must be opened before it will be logged in the Analytics.
 * This is to stop quick route transitions and duplicates from appearing in the stats.
 */
const VALIDATION_DELAY_MSECS = 250;

export default function () {
  const router = useRouter();
  const tasksStore = useTasksStore();
  const userStore = useUserStore();
  const deployedAppsStore = useDeployedAppsStore();
  const tracksStore = useTracksStore();
  const route = useRoute();

  const analyticsUserInfo = ref({ userId: '', userEmail: '', forceNewVisit: false });

  const currentUserDetails = computed<FullUserDetails | null>(() => userStore.currentUserDetails);

  const activeTrack = computed<Track | undefined>(() => tracksStore.activeTrack);

  /**
   * Used to track the last generated analytics event (and avoid duplicates).
   *
   * We use a normal variable as we don't want Vue to react to its changes.
   */
  let lastEvent: AnalyticsEventCorePayload | null = null;

  /**
   * Id of the timeout used to delay sending the analytics event.
   *
   * This stops us from sending quick route transitions and duplicates to the server.
   * It also makes sense from an analytics point of view as any page that does not stay open for
   * at least MINIMUM_OPEN_DURATION_MSECS won't be logged.
   *
   * We use a normal variable as we don't want Vue to react to its changes.
   */
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const unwatchUserId = watch(() => currentUserDetails.value?.id, (newId, oldId) => {
    if (newId === oldId) {
      return;
    }
    if (!newId) {
      // Expectation is that we get the ID first. So if it becomes null then everything must be cleared.
      analyticsUserInfo.value.userId = '';
      analyticsUserInfo.value.userEmail = '';
    } else {
      analyticsUserInfo.value.userId = newId;
    }
  }, { immediate: true });

  const unwatchUserEmail = watch(() => currentUserDetails.value?.email, (newEmail, oldEmail) => {
    if (newEmail === oldEmail) {
      return;
    }
    analyticsUserInfo.value.userEmail = newEmail ?? '';
    analyticsUserInfo.value.forceNewVisit = true;
  }, { immediate: true });

  // Using watchEffect is more reliable than using a router.afterEach hook.
  // This is because it fits better in the Vue lifecycle and the various variables will be
  // in a more consistent state. The router afterEach hook is called early and causes things to initialise
  // in our general Vue code... So trying to use states at that point if often error prone.
  const unwatchAnalytics = watchEffect(() => {
    if (!Integrations.ANALYTICS_SERVER) {
      // Analytics server is not set => No tracking.
      // And this won't change unless the page is reloaded. So it is fine to return now
      // even on the first pass (meaning no variables will be tracked by this watchEffect).
      analyticsLog.debug('Analytics server not set => No tracking');
      return;
    }

    const path = route.fullPath ? router.resolve(route.fullPath).href : undefined;
    const url = path ? window.location.origin + path : undefined;
    const titleFromMetaInfo = route.meta?.title;
    const title = document.title || titleFromMetaInfo;
    const pageTitle = title || path;
    const environmentId = activeTrack.value?.environmentId;
    const appId = (route.params.tenantAppId ?? undefined) as string | undefined;
    const deployedApp = deployedAppsStore.availableDeployedApps.find(app => app.environmentId === environmentId);
    const appFromReferencedTenant = tasksStore.referencedTenantApps.find((app: MiniApp) => app.id === appId);
    const userId = analyticsUserInfo.value.userId;
    const userEmail = analyticsUserInfo.value.userEmail;

    // In this iteration we use the email as the identifier but it could be the email, or a hash of the email
    // or a hash of the analytics session identifier (i.e. linked to session but not traceable back to user)
    // And this could be set depending of the tenant config and their need for privacy / control.
    // If we have no identifier (guest user) Matomo will set its own user UUID.
    const userIdentifier = userEmail ?? userId;

    let analyticsSiteId: number | undefined;

    // The following currently works wether the user is logged-in or a guest.
    // We just need to identify if the app is a deployed or dev one...
    // This would not work in a router.afterEach hook (as that one typically triggers before
    // these are fully initialised / updated).
    // And it is likely this code here will need updating once #11608 is fixed.
    if (deployedApp) {
      // Deployed app scenario... (meaning the siteId is set only when analytics are enabled)
      analyticsSiteId = deployedApp.analyticsSiteId;
    } else {
      if (appFromReferencedTenant) {
        // Dev app (draft) scenario => We also need to check if the analytics have been enabled.
        analyticsSiteId = appFromReferencedTenant.analyticsEnabled ? appFromReferencedTenant.matomoSiteId : undefined;
      } else {
        // Not an app => No analytics => analyticsSiteId stays undefined.
        // For now do nothing...
        // In the future we could decide to log these too (against the default siteId, i.e. 1) but it would have
        // to be acceptable to customers and the server load would have to account for it.
      }
    }

    analyticsLog.debug('Analytics watchEffect triggered for siteId: ' + analyticsSiteId + ' and url: ' + url);

    if (analyticsSiteId && url && !(routeOnExclusionList(route.name))) {
      const evtPayload: AnalyticsEventCorePayload = {
        url: url,
        referrerUrl: document.referrer,
        appId: appId,
        userId: userIdentifier,
        pageTitle: pageTitle,
        analyticsSiteId: analyticsSiteId,
      };

      if (!isEqual(lastEvent, evtPayload)) {
        lastEvent = evtPayload;
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
          analyticsLog.debug('Cancelled timer ' + timeoutId);
        }
        timeoutId = setTimeout(() => {
          if (lastEvent !== null) {
            sendAnalyticsEvent(lastEvent);
            lastEvent = null;
          } else {
            analyticsLog.debug('Event has been forced reset to null. Not sending', evtPayload);
          }
          // Because we always clear the previous timeout (if there was one) we only have one timeout ever running
          // (because we are running Javascript, this would not be true in true multi-threaded envs, i.e. Java)...
          // So, once we end, it is safe to clear the timeoutId variable (which makes for clearer logs).
          timeoutId = null;
        }, VALIDATION_DELAY_MSECS);
        analyticsLog.debug('Scheduling the sending of analytics event to ' +
          evtPayload.analyticsSiteId + ' by timer ' + timeoutId);
      } else {
        // Current event is a duplicate of the previous one, which we are about to send to the
        // analytics server. So ignore it (but don't stop the ongoing notification either).
        analyticsLog.debug('Analytics event is a duplicate. Not sending', evtPayload);
      }
    } else {
      // The matomoId is undefined (analytics are disabled) or route name is on exclusion list
      // => Do NOT send an analytics event
      //
      // Setting the lastEvent to null will also stop any intermediary route/state from being reported
      // to the analytics subsystem.
      // Example: When clicking on a deployed app, the router will go via an intermediary URL that was
      //   causing an intermediary event (on the dev matomoId!) to be generated whilst the final deployment
      //   had analytics disabled...
      //   Setting the lastEvent to null stops this from happening (as this stops the previous event,
      //   currently scheduled by the timer, from being sent)...
      analyticsLog.debug('Silenced analytics event (Disabled for app or route name on exclusion list)');
      lastEvent = null;
    }
  });

  function sendAnalyticsEvent(payload: AnalyticsEventCorePayload): void {
    if (analyticsUserInfo.value.forceNewVisit) {
      window._paq.push(['appendToTrackingUrl', 'new_visit=1']); // forces a new visit
    }

    window._paq.push(['setSiteId', payload.analyticsSiteId]);
    window._paq.push(['setCustomUrl', payload.url]);

    if (payload.referrerUrl) {
      window._paq.push(['setReferrerUrl', payload.referrerUrl]);
    }

    if (payload.pageTitle) {
      window._paq.push(['setDocumentTitle', payload.pageTitle]);
    }

    if (payload.userId) {
      window._paq.push(['setUserId', payload.userId]);
    }

    window._paq.push(['trackPageView']);
    analyticsLog.debug('Sent tracking event to siteId: ' + payload.analyticsSiteId + ' about url: ' + payload.url);

    if (analyticsUserInfo.value.forceNewVisit) {
      window._paq.push(['appendToTrackingUrl', '']); // do not force a new visit anymore
      analyticsUserInfo.value.forceNewVisit = false;
    }
  }

  function routeOnExclusionList(routeName?: string | null): boolean {
    const excludedTargets = [
      RouteNames.APP_STUDIO_ROUTE_NAME,
      RouteNames.APP_STUDIO_CUSTOM_VIEW_ROUTE_NAME,
      RouteNames.APP_STUDIO_DATA_SOURCE_ROUTE_NAME,
      RouteNames.APP_STUDIO_WORKFLOWS_ROUTE_NAME,
      RouteNames.APP_STUDIO_RULESETS_ROUTE_NAME,
      RouteNames.APP_STUDIO_ANALYTICS_ROUTE_NAME,
      RouteNames.APP_STUDIO_SETTINGS_ROUTE_NAME,
      RouteNames.APP_STUDIO_EDIT_APP_TABLES_ROUTE_NAME,
      RouteNames.APP_STUDIO_UI_FLOWS_ROUTE_NAME,
      RouteNames.APP_STUDIO_CREATE_UI_FLOW_ROUTE_NAME,
      RouteNames.APP_STUDIO_EDIT_UI_FLOW_ROUTE_NAME,
      RouteNames.APP_STUDIO_WORKSPACE_LABELS_ROUTE_NAME,
      RouteNames.APP_STUDIO_CONTEXT_CARDS_ROUTE_NAME,
      RouteNames.APP_STUDIO_EDIT_CONTEXT_CARD_ROUTE_NAME,
      RouteNames.APP_STUDIO_CREATE_CONTEXT_CARD_ROUTE_NAME,
      RouteNames.APP_STUDIO_VERSIONS_ROUTE_NAME,
      RouteNames.EDIT_APP_ROUTE_NAME,
      RouteNames.EDIT_APP_DETAILS_ROUTE_NAME,
      RouteNames.EDIT_APP_PERMISSIONS_ROUTE_NAME,
      RouteNames.EDIT_APP_WORKSPACE_LABELS_ROUTE_NAME,
      RouteNames.EDIT_APP_TABLES_ROUTE_NAME,
      RouteNames.EDIT_APP_VIEWS_ROUTE_NAME,
      RouteNames.EDIT_APP_UI_FLOWS_ROUTE_NAME,
      RouteNames.CREATE_UI_FLOW_ROUTE_NAME,
      RouteNames.EDIT_UI_FLOW_ROUTE_NAME,
      RouteNames.EDIT_APP_DATA_SOURCES_ROUTE_NAME,
      RouteNames.EDIT_APP_WORKFLOWS_ROUTE_NAME,
      RouteNames.APP_RULESETS_LIST_ROUTE_NAME,
      RouteNames.EDIT_APP_TRACK_TEMPLATES_ROUTE_NAME,
      RouteNames.APP_CONTEXT_CARDS_LIST_ROUTE,
      RouteNames.EDIT_APP_CUSTOM_VIEW_ROUTE_NAME,
      RouteNames.TRACK_EDIT_APP_ROUTE_NAME,
      RouteNames.TRACK_EDIT_APP_DETAILS_ROUTE_NAME,
      RouteNames.TRACK_EDIT_APP_TABLES_ROUTE_NAME,
      RouteNames.TRACK_EDIT_APP_VIEWS_ROUTE_NAME,
      RouteNames.TRACK_EDIT_APP_WORKFLOWS_ROUTE_NAME,
      RouteNames.TRACK_EDIT_APP_DATA_SOURCES_ROUTE_NAME
    ];

    return !!routeName && excludedTargets.includes(routeName);
  }

  function unwatch() {
    unwatchUserId();
    unwatchUserEmail();
    unwatchAnalytics();
  }

  return {
    unwatch
  };
}
