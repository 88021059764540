import { FocusableElement, tabbable } from 'tabbable';

export function isEditableElementSelected(): boolean {
  const activeElement = document.activeElement;
  if (activeElement) {
    if (['input', 'textarea'].includes(activeElement.nodeName.toLowerCase()) ||
        (activeElement.nodeName.toLowerCase() === 'div' && (activeElement as HTMLDivElement).isContentEditable)) {
      return true;
    }
    if (activeElement.shadowRoot) {
      const shadowTargetName = activeElement.shadowRoot.activeElement?.tagName.toLowerCase() ?? '';
      if (['input', 'textarea'].includes(shadowTargetName) ||
          (shadowTargetName === 'div' &&
            (activeElement.shadowRoot.activeElement as HTMLDivElement).isContentEditable)) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Create a focus trap for tabbing within a container.
 *
 * @param container Target container element
 * @param event Keyboard tab event
 */
export function trapFocus(container: Element | null, event: KeyboardEvent) {
  if (!container || event.key !== 'Tab') {
    return;
  }

  const focusElements: FocusableElement[] = tabbable(container);
  if (focusElements.length === 0) {
    return;
  }

  const lastIndex = focusElements.length - 1;
  const firstFocusableElement = focusElements[0];
  const lastFocusableElement = focusElements[lastIndex];

  // Check if tabbing from element outside container
  const target: HTMLElement = event.target as HTMLElement;
  if (!container.contains(target)) {
    // Ensure tabbing goes back inside container to the first/last element as appropriate
    if (event.shiftKey) {
      event.preventDefault();
      lastFocusableElement.focus();
    } else {
      event.preventDefault();
      firstFocusableElement.focus();
    }
  } else {
    // Tabbing from element inside container
    if (target === firstFocusableElement && event.shiftKey) {
      // Reverse tabbing from first element - move focus to last element
      event.preventDefault();
      lastFocusableElement.focus();
    } else if (target === lastFocusableElement && !event.shiftKey) {
      // Forward tabbing from last element - move focus to first element
      event.preventDefault();
      firstFocusableElement.focus();
    }
  }
}
