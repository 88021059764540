import log from 'loglevel';

import { Attribute, AttributeType } from '@/data/tasks/Attribute';
import { evaluateConditionalFormula } from '@/data/tasks/Formula';

type UnaryComparisonFunction = (propertyValue?: string) => boolean;
type BinaryComparisonFunction = (propertyValue: string | undefined, compareValue: string) => boolean;
type TernaryComparisonFunction =
  (propertyValue: string | undefined, compareValue1: string, compareValue2: string) => boolean;
export type FormulaConditionArgs = {
  recordProperties: Record<string, string>,
  schema: Attribute[],
}
type FormulaFunction = (formula: string, formulaConditionArgs: FormulaConditionArgs) => boolean;

// App studio expression results are precalculated, but we still need various things in here to keep everything happy:
type PrecalculatedResultFunction = (result: boolean | undefined) => boolean;

const DateGreaterThan: BinaryComparisonFunction = (propertyValue, compareValue) => {
  if (propertyValue === undefined || compareValue === undefined) {
    return false;
  }
  const propDate = new Date(propertyValue);
  const compareDate = new Date(compareValue);
  return propDate > compareDate;
};

const DateIsBetween: TernaryComparisonFunction = (propertyValue, compareValue1, compareValue2) => {
  if (propertyValue === undefined || compareValue1 === undefined || compareValue2 === undefined) {
    return false;
  }
  const propDate = new Date(propertyValue);
  const afterDate = new Date(compareValue1);
  const beforeDate = new Date(compareValue2);
  return (propDate >= afterDate) && (propDate <= beforeDate);
};

const DateLessThan: BinaryComparisonFunction = (propertyValue, compareValue) => {
  if (propertyValue === undefined || compareValue === undefined) {
    return false;
  }

  const propDate = new Date(propertyValue);
  const compareDate = new Date(compareValue);
  return propDate < compareDate;
};

const LessThanOrEqualTo: BinaryComparisonFunction = (propertyValue, compareValue) => {
  if (propertyValue === undefined || compareValue === undefined) {
    return false;
  }
  return Number(propertyValue) <= Number(compareValue);
};

const LessThan: BinaryComparisonFunction = (propertyValue, compareValue) => {
  if (propertyValue === undefined || compareValue === undefined) {
    return false;
  }
  return Number(propertyValue) < Number(compareValue);
};

const GreaterThanOrEqualTo: BinaryComparisonFunction = (propertyValue, compareValue) => {
  if ([propertyValue, compareValue].some(val => val === undefined)) {
    return false;
  }
  return Number(propertyValue) >= Number(compareValue);
};

const GreaterThan: BinaryComparisonFunction = (propertyValue, compareValue) => {
  if (propertyValue === undefined || compareValue === undefined) {
    return false;
  }
  return Number(propertyValue) > Number(compareValue);
};

const EqualTo: BinaryComparisonFunction = (propertyValue, compareValue) => {
  if (propertyValue === undefined || compareValue === undefined) {
    return false;
  }
  return Number(propertyValue) === Number(compareValue);
};

const IsBetween: TernaryComparisonFunction = (propertyValue, compareValue1, compareValue2) => {
  if (propertyValue === undefined || compareValue1 === undefined || compareValue2 === undefined) {
    return false;
  }
  return ((propertyValue >= compareValue1) && (propertyValue <= compareValue2));
};

const IsEmpty: UnaryComparisonFunction = (propertyValue) => {
  return propertyValue === undefined || propertyValue === null || propertyValue.trim() === '';
};

const NotEmpty: UnaryComparisonFunction = (propertyValue) => {
  return !IsEmpty(propertyValue);
};

const TextContains: BinaryComparisonFunction = (propertyValue, compareValue) => {
  if (propertyValue === undefined || compareValue === undefined) {
    return false;
  }
  return propertyValue.toLowerCase().includes(compareValue.toLowerCase());
};

const TextStartsWith: BinaryComparisonFunction = (propertyValue, compareValue) => {
  if (propertyValue === undefined || compareValue === undefined) {
    return false;
  }
  return propertyValue.toLowerCase().startsWith(compareValue.toLowerCase());
};

const TextEqualTo: BinaryComparisonFunction = (propertyValue, compareValue) => {
  if (propertyValue === undefined || compareValue === undefined) {
    return false;
  }
  return propertyValue.toLowerCase() === compareValue.toLowerCase();
};

const EvaluateFormula: FormulaFunction = (formula: string, formulaConditionArgs: FormulaConditionArgs) => {
  const { schema, recordProperties } = formulaConditionArgs;
  const result = evaluateConditionalFormula(formula, schema, recordProperties);
  if (result === undefined) {
    return false;
  }

  // We should only have boolean (i.e. checkbox) results for formula conditions
  return result.type === AttributeType.CHECKBOX && result.value === 'true';
};

const PrecalculatedEvaluation: PrecalculatedResultFunction = (result: boolean | undefined) => {
  return result ?? false;
};

export interface UnaryCondition {
  // eslint-disable-next-line no-use-before-define
  name: ConditionName,
  type: 'unary',
}

export interface BinaryCondition {
  // eslint-disable-next-line no-use-before-define
  name: ConditionName,
  type: 'binary',
  compareValue1: string
}

export interface TernaryCondition {
  // eslint-disable-next-line no-use-before-define
  name: ConditionName,
  type: 'ternary',
  compareValue1: string,
  compareValue2: string,
}

export interface FormulaCondition {
  // eslint-disable-next-line no-use-before-define
  name: ConditionName,
  type: 'formula',
  formula: string,
}

export interface ExpressionCondition {
  // eslint-disable-next-line no-use-before-define
  name: ConditionName,
  type: 'expression',
  expression: string,
}

export type ConditionType = UnaryCondition |
  BinaryCondition |
  TernaryCondition |
  FormulaCondition |
  ExpressionCondition;

export type ConditionArity = ConditionType['type'];

// This conditional type is just so that we need only one map for names -> comparison functions, while still keeping the
// map type safe.
type ComparisonFunction<T extends ConditionArity> = T extends 'formula'
  ? FormulaFunction
  : T extends 'expression'
    ? PrecalculatedResultFunction
    : T extends 'ternary'
      ? TernaryComparisonFunction
      : T extends 'binary'
        ? BinaryComparisonFunction
        : T extends 'unary'
          ? UnaryComparisonFunction
          : never;

type Condition<T extends ConditionArity> = {
  type: T,
  displayName: string,
  compare: ComparisonFunction<T>
}

// A helper type for use in the conditions map. We can't use generics on static objects, but wrapping the
// condition config data in this function gives us some more type safety in the map (e.g. not being able to assign an
// incorrect comparison function type
const condition = <T extends ConditionArity>(condition: Condition<T>): Condition<T> => condition;

export const conditionalChecks = {
  equalTo: condition({ type: 'binary', displayName: 'Is equal to', compare: EqualTo }),
  greaterThan: condition({ type: 'binary', displayName: 'Is greater than', compare: GreaterThan }),
  greaterThanOrEqualTo: condition({
    type: 'binary',
    displayName: 'Is greater than or equal to',
    compare: GreaterThanOrEqualTo
  }),
  lessThan: condition({ type: 'binary', displayName: 'Is less than', compare: LessThan }),
  lessThanOrEqualTo: condition({ type: 'binary', displayName: 'Is less than or equal to', compare: LessThanOrEqualTo }),
  notEmpty: condition({ type: 'unary', displayName: 'Is not empty', compare: NotEmpty }),
  isEmpty: condition({ type: 'unary', displayName: 'Is empty', compare: IsEmpty }),
  textContains: condition({ type: 'binary', displayName: 'Text contains', compare: TextContains }),
  textStartsWith: condition({ type: 'binary', displayName: 'Text starts with', compare: TextStartsWith }),
  textEqualTo: condition({ type: 'binary', displayName: 'Text equal to', compare: TextEqualTo }),
  dateGreaterThan: condition({ type: 'binary', displayName: 'Date is after', compare: DateGreaterThan }),
  dateLessThan: condition({ type: 'binary', displayName: 'Date is before', compare: DateLessThan }),
  isBetween: condition({ type: 'ternary', displayName: 'Is between', compare: IsBetween }),
  dateIsBetween: condition({ type: 'ternary', displayName: 'Date is between', compare: DateIsBetween }),
  unconditional: condition({ type: 'unary', displayName: 'Always', compare: () => true }),
  formula: condition({ type: 'formula', displayName: 'Custom formula', compare: EvaluateFormula }),
  expression: condition({ type: 'expression', displayName: 'Expression', compare: PrecalculatedEvaluation }),
} as const;

export type ConditionName = keyof typeof conditionalChecks;

function isTernaryCondition(condition: ConditionType): condition is TernaryCondition {
  return condition.type === 'ternary';
}

function isBinaryCondition(condition: ConditionType): condition is BinaryCondition {
  return condition.type === 'binary';
}

function isUnaryCondition(condition: ConditionType): condition is UnaryCondition {
  return condition.type === 'unary';
}

function isFormulaCondition(condition: ConditionType): condition is FormulaCondition {
  return condition.type === 'formula';
}

function isExpressionCondition(condition: ConditionType): condition is ExpressionCondition {
  return condition.type === 'expression';
}

export function checkCondition(
  config: ConditionType,
  propertyValue: string | undefined,
  formulaConditionArgs: FormulaConditionArgs,
  precalculatedResult?: boolean): boolean {
  const condition = conditionalChecks[config.name];
  if (condition.type === 'unary' && isUnaryCondition(config)) {
    return condition.compare(propertyValue);
  } else if (condition.type === 'binary' && isBinaryCondition(config)) {
    return condition.compare(propertyValue, config.compareValue1);
  } else if (condition.type === 'ternary' && isTernaryCondition(config)) {
    return condition.compare(propertyValue, config.compareValue1, config.compareValue2);
  } else if (condition.type === 'formula' && isFormulaCondition(config)) {
    return condition.compare(config.formula, formulaConditionArgs);
  } else if (condition.type === 'expression' && isExpressionCondition(config)) {
    return condition.compare(precalculatedResult);
  }

  const errorMsg = `Unrecognised formatting condition: ${JSON.stringify(condition)} with config:
  ${JSON.stringify(config)}`;
  log.error(errorMsg);
  throw new Error(errorMsg);
}

const dateConditions: ConditionName[] = [
  'dateLessThan', 'dateIsBetween', 'dateGreaterThan'
];

const textConditions: ConditionName[] = [
  'textContains', 'textEqualTo', 'textStartsWith'
];

// Define the conditions that are allowed for the various attribute types.
// NB when creating a new formatting rule for an attribute, the first element in this ConditionName array will be
//    used as the initial value. At the moment we want it to be 'always' for every attribute, so ensure 'unconditional'
//    is the first element
export const allowedConditionsForAttributes: Record<AttributeType, ConditionName[]> = {
  [AttributeType.LONG_TEXT]: ['unconditional', 'notEmpty', 'isEmpty', ...textConditions, 'formula'],
  [AttributeType.TEXT]: ['unconditional', 'notEmpty', 'isEmpty', ...textConditions, 'formula'],
  // PASSWORD and AUTOCOMPLETE are currently only used in app studio forms, so won't appear in conditional
  // formatting contexts, but we have to include them here to complete the return type
  [AttributeType.PASSWORD]: [],
  [AttributeType.AUTOCOMPLETE]: [],
  [AttributeType.DATE]: ['unconditional', 'notEmpty', 'isEmpty', ...dateConditions, 'formula'],
  [AttributeType.NUMBER]: ['unconditional', 'notEmpty', 'isEmpty', 'lessThan', 'lessThanOrEqualTo',
    'greaterThan', 'greaterThanOrEqualTo', 'isBetween', 'equalTo', 'formula'],
  [AttributeType.SELECT]: ['unconditional', 'textEqualTo', 'formula'],
  [AttributeType.LAST_UPDATED]: ['unconditional', ...dateConditions],
  [AttributeType.CREATED_DATE]: ['unconditional', ...dateConditions],
  [AttributeType.CHECKBOX]: ['unconditional'],
  [AttributeType.ARTICLE]: ['unconditional', 'formula'],
  [AttributeType.CONTENT]: ['unconditional', 'formula'],
  [AttributeType.FORMULA]: ['unconditional', 'formula'],
  [AttributeType.IMAGE]: ['unconditional', 'formula'],
  [AttributeType.RECORD]: ['unconditional', 'formula'],
  [AttributeType.TIME]: ['unconditional', 'formula'],
  [AttributeType.PERCENT]: ['unconditional', 'formula'],
  [AttributeType.TRACK]: ['unconditional', 'formula'],
  [AttributeType.URL]: ['unconditional', 'formula'],
  [AttributeType.USER]: ['unconditional', 'formula'],
  [AttributeType.CREATED_BY]: ['unconditional', 'formula'],
  [AttributeType.LAST_UPDATED_BY]: ['unconditional', 'formula'],
  [AttributeType.PLACEHOLDER]: [],
  [AttributeType.TYPE]: [],
  [AttributeType.TIME_UNIT]: [],
  [AttributeType.RULESET]: ['unconditional', 'formula'],
  [AttributeType.ACTION]: ['unconditional', 'formula'],
  [AttributeType.LINE_BREAK]: [],
  [AttributeType.DESCRIPTION]: [],
  [AttributeType.INFO_BOX]: [],
  [AttributeType.SEPARATOR_LINE]: [],
  [AttributeType.LIST_TABLE]: [],
  [AttributeType.FORM_BUTTON]: [],
  [AttributeType.FILE_UPLOAD]: [],
};
