import { BotInfo, BrowserInfo, detect, NodeInfo, OperatingSystem, ReactNativeInfo, SearchBotDeviceInfo }
  from 'detect-browser';

class BrowserDetection {
  // The maximum width we consider to be "mobile" (648px)
  private static readonly MOBILE_WIDTH_MAX_THRESHOLD = '40.5rem';

  private static browser: BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | null;
  constructor() {
    BrowserDetection.browser = detect();
  }

  private isMobileOS(): boolean {
    if (this.isIos()) {
      return true;
    }
    const os: OperatingSystem | NodeJS.Platform | null = BrowserDetection.browser?.os ?? null;
    if (!os) {
      return false;
    }
    return ['iOS', 'Android OS', 'BlackBerry OS', 'Windows Mobile', 'Amazon OS'].includes(os);
  }

  private isMobileWidth(): boolean {
    return window.matchMedia(`screen and (max-width:${BrowserDetection.MOBILE_WIDTH_MAX_THRESHOLD})`).matches;
  }

  isMobileOrTablet(): boolean {
    if (this.isIpadApp()) {
      return true;
    }
    if (this.isIphoneApp()) {
      return true;
    }
    return this.isMobileOS();
  }

  isMobile(): boolean {
    if (this.isIpadApp()) {
      return false;
    }
    if (this.isIphoneApp()) {
      return true;
    }
    return this.isMobileOrTablet() && this.isMobileWidth();
  }

  isTablet(): boolean {
    if (this.isIpadApp()) {
      return true;
    }
    if (this.isIphoneApp()) {
      return false;
    }
    return this.isMobileOrTablet() && !this.isMobileWidth();
  }

  isIphoneApp(): boolean {
    const uaString = navigator.userAgent.toLowerCase();
    return uaString.includes('cafex-ios-app-iphone');
  }

  isIpadApp(): boolean {
    const uaString = navigator.userAgent.toLowerCase();
    return uaString.includes('cafex-ios-app-ipad');
  }

  isIosApp(): boolean {
    return this.isIphoneApp() || this.isIpadApp();
  }

  isIos(): boolean {
    // we are using 'maxTouchPoints' here, as well as the typical method,
    // because iPadOS 13+ returns a user agent string that is identical
    // to MacOS Safari. Only mobile Safari has multiple touch points.
    return (BrowserDetection.browser?.os === 'iOS') ||
      (this.isSafari() && navigator.maxTouchPoints > 1);
  }

  isAndroid(): boolean {
    return BrowserDetection.browser?.os === 'Android OS';
  }

  isSafari(): boolean {
    // browser name is 'ios' for Safari on iPhone (Chrome etc on iPhone have different names, eg, 'crios')
    return BrowserDetection.browser?.name === 'safari' || BrowserDetection.browser?.name === 'ios';
  }

  isIe(): boolean {
    return BrowserDetection.browser?.name === 'ie';
  }

  isChrome(): boolean {
    return BrowserDetection.browser?.name === 'chrome';
  }

  isFirefox(): boolean {
    return BrowserDetection.browser?.name === 'firefox';
  }

  isSafariIos(): boolean {
    // we are using 'maxTouchPoints' here because the browser-detect lib can't distinguish
    // iPadOS safari from MacOS Safari (iPadOS 13+ deliberately makes them the same user agent).
    // Only iOS has multiple touch points.
    return this.isSafari() && (navigator.maxTouchPoints > 1 || this.isIos());
  }

  isElectron(): boolean {
    const uaString = navigator.userAgent.toLowerCase();
    return uaString.includes(' electron/');
  }

  isEdge(): boolean {
    return BrowserDetection.browser?.name === 'edge-chromium';
  }

  isEdgeLegacy(): boolean {
    return BrowserDetection.browser?.name === 'edge';
  }

  getBrowserVersion(): number | undefined {
    const vString: string | null | undefined = BrowserDetection.browser?.version;
    if (!vString) {
      return undefined;
    }
    const parsed: RegExpMatchArray | null = vString.match(/[^0-9]*([0-9]+)\./);
    return parsed ? parseInt(parsed[1], 10) : 0;
  }

  isSupportedBrowser(): boolean {
    let unsupported = false;

    if ((this.isIosApp()) || this.isIpadApp() || this.isIphoneApp()) {
      return true;
    }

    unsupported ||= this.isIe();
    unsupported ||= this.isIos() && !this.isSafari();
    unsupported ||= this.isChrome() && (this.getBrowserVersion() ?? 0) < 64;
    unsupported ||= this.isSafari() && (this.getBrowserVersion() ?? 0) < 12;
    unsupported ||= this.isFirefox() && (this.getBrowserVersion() ?? 0) < 58;
    // Legacy version should always be <= 18. Add 79 just in case Edge revert their UA to the old form
    unsupported ||= this.isEdgeLegacy() && (this.getBrowserVersion() ?? 0) < 79;
    unsupported ||= this.isEdge() && (this.getBrowserVersion() ?? 0) < 79;

    return !unsupported;
  }

  isMacOS(): boolean {
    return navigator.userAgent.indexOf('Mac') !== -1;
  }

  getDeviceMemory(): string | undefined {
    if (navigator && (navigator as NavigatorWithConnectionAndDeviceMemory).deviceMemory) {
      return (navigator as NavigatorWithConnectionAndDeviceMemory).deviceMemory;
    }
  }

  getNetworkInfo(): Record<string, unknown> {
    return this.networkInfo;
  }

  get browserInfo(): Record<string, unknown> {
    return {
      userAgent: navigator.userAgent,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      screenWidth: window.screen?.width,
      screenHeight: window.screen?.height,
      screenOrientation: window.screen?.orientation?.type,
      isMobileOrTablet: this.isMobileOrTablet(),
      isMobile: this.isMobile(),
      isTablet: this.isTablet(),
      isIphoneApp: this.isIphoneApp(),
      isIpadApp: this.isIpadApp(),
      isIosApp: this.isIosApp(),
      isIos: this.isIos(),
      isAndroid: this.isAndroid(),
      isSafari: this.isSafari(),
      isSafariIos: this.isSafariIos(),
      isChrome: this.isChrome(),
      isFirefox: this.isFirefox(),
      isElectron: this.isElectron(),
      isEdge: this.isEdge(),
      isEdgeLegacy: this.isEdgeLegacy(),
      getBrowserVersion: this.getBrowserVersion(),
      isSupportedBrowser: this.isSupportedBrowser(),
    };
  }

  get networkInfo(): Record<string, unknown> {
    return (navigator as NavigatorWithConnectionAndDeviceMemory).connection;
  }
}

interface NavigatorWithConnectionAndDeviceMemory extends Navigator {
  connection: Record<string, unknown>;
  deviceMemory?: string;
}

const browserDetection: BrowserDetection = new BrowserDetection();

export default browserDetection as BrowserDetection;
