import { Track, TrackWebhook } from '@/data/datatypes/Track';

export interface DialInNumber {
  label: string;
  mobileQuickDial: string;
  number: string;
}

export interface TransferPayload {
  transferTo: string;
}

export interface TransferTrackPayload {
  transferTo: string;
  trackId: string;
}

export interface WorkflowAddPayload {
  track: Track;
  workflow: TrackWebhook;
}

export interface WorkflowDeletePayload {
  track: Track;
  workflowId: string;
}

export interface WorkflowInvokePayload {
  track: Track;
  trackWebhook: TrackWebhook | undefined;
  text: string;
}

export enum NotificationsState {
  NONE,
  GENERAL,
  DIRECT,
}
