import { Ref } from 'vue';

import { StreamListener } from '@/custom_typings/cafexmeetings/meetings-api';

export interface InitMeetingsPayload {
  trackId: string;
  meetingUrl: string;
  meetingId: string;
  externalUser: string;
  audioOnly: boolean;
}

export interface MeetingLocation {
  top: number;
  height: number;
  left: number;
  width: number;
}

export interface InvitePayload {
  trackId: string;
  pstnNumbers?: string[];
  emailAddresses?: string[];
  emailMessage?: string;
}

export interface RecordingPayload {
  trackId: string;
  recording: boolean;
}

export interface NodeDetail {
  id: string;
  pstn: boolean;
  element: HTMLElement;
  sendRate?: number;
  videoWidth?: number;
  videoHeight?: number;
}

export interface MeetingGuestLoggedInDetails {
  userId: string;
  clearGuestStatus: boolean;
}

export class StreamListenerImpl implements StreamListener {
  nodeDetails: Ref<Record<string, NodeDetail>>;

  constructor(nodeDetails: Ref<Record<string, NodeDetail>>) {
    // Note assignment of the reference, not the value
    this.nodeDetails = nodeDetails;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public localStreamEstablished(nodeId: string, element: HTMLElement): void {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public activeSpeakerStreamEstablished(element: HTMLElement): void {
  }

  public nodeStreamConnected(nodeId: string, element: HTMLElement, pstn: boolean): void {
    this.nodeDetails.value[nodeId] = {
      id: nodeId,
      element: element,
      pstn: pstn,
      sendRate: 0,
    };
  }

  public nodeStreamDisconnected(nodeId: string): void {
    delete this.nodeDetails.value[nodeId];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public galleryLayoutUpdate(nodeIds: string[]): void {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public nodeRateUpdate(nodeId: string, kbitsPerSecond: number): void {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public publishRateUpdate(kbitsPerSecond: number): void {
  }
}
