import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';
import { Location } from 'vue-router';

import BrowserDetection from '@/data/BrowserDetection';
import { SearchLocation } from '@/data/datatypes/SemanticSearchQuery';
import { FlowConfig } from '@/data/tasks/FlowConfig';
import RouteNames from '@/router/RouteNames';
import pinia from '@/stores';
import { usePushNotificationStore } from '@/stores/PushNotification';
import { DEFAULT_COLLABORATION_APP_ID } from '@/stores/Tasks';
import { MeetingContentTab } from '@/stores/UIState.types';

import { useRouteStore } from './Route';
import { useTracksStore } from './Tracks';

export function getRouteForTrack(trackId: string, owningMiniAppId: string | undefined): Location {
  const tracksStore = useTracksStore();
  const track = tracksStore.tracks[trackId];
  if (track?.environmentId && track.owningMiniAppId) {
    return {
      name: RouteNames.APP_ENVIRONMENT_TRACK_VIEW_NAME,
      params: {
        trackId: trackId,
        tenantAppId: track.owningMiniAppId,
        environmentId: track.environmentId
      }
    };
  }
  const tenantAppId = owningMiniAppId || DEFAULT_COLLABORATION_APP_ID;
  return { name: RouteNames.TRACK_DEFAULT_ROUTE_NAME, params: { trackId, tenantAppId } };
}

export const useUIStateStore = defineStore('UIState', () => {
  const ADDITIONAL_CONTENT_VISIBLE_KEY = 'us_acv';
  const ADDITIONAL_MEETING_CONTENT_VISIBLE_KEY = 'us_amcv';
  const ADDITIONAL_MEETING_CONTENT_TAB_KEY = 'us_amct';
  const LAST_VIEWED_WORKSPACE_KEY = 'us_lvw';
  const LAST_VIEWED_CHAT_KEY = 'us_lvc';
  const CHAT_FORMATTING_EXPANDED_KEY = 'us_cfe';

  const showAdditionalContent: Ref<boolean> = ref(true);
  const flow: Ref<FlowConfig | null> = ref(null);
  const appGuestView: Ref<boolean> = ref(false);
  const showAdditionalMeetingContent: Ref<boolean> = ref(false);
  const selectedMeetingContentTab: Ref<MeetingContentTab> = ref(MeetingContentTab.PARTICIPANTS);
  const privacyMode: Ref<boolean> = ref(false);
  const mobileSideMenuOpen: Ref<boolean> = ref(false);
  const expandedTrackLabels: Ref<Record<string, Record<string, boolean>>> = ref({});
  const expandedTracks: Ref<Record<string, boolean>> = ref({});
  const lastViewedWorkspaceId: Ref<string | null | undefined> = ref(undefined);
  const lastViewedChatId: Ref<string | null | undefined> = ref(undefined);
  const chatFormattingExpanded: Ref<boolean> = ref(true);
  const searchLocation: Ref<SearchLocation> = ref(SearchLocation.ALL);

  const showEmbedded: Ref<boolean> = ref(true);
  const preventOrientationBlock: Ref<boolean> = ref(false);

  const tabVisible: Ref<boolean> = ref(true);

  const activePopupMenuId: Ref<string> = ref('');

  const setActivePopupMenuId = (id: string): void => {
    activePopupMenuId.value = id;
  };

  const popupMenuIsVisible: Ref<boolean> = ref(false);

  const setPopupMenuIsVisible = (newState: boolean): void => {
    popupMenuIsVisible.value = newState;
  };

  const activeOverlays: Ref<string[]> = ref([]);
  // At least one overlay is opened/active
  const overlayIsActive = computed(() => activeOverlays.value.length > 0);

  const toggleActiveOverlays = (overlayId: string): void => {
    // Add overlay by passed uuid if not present (mount), remove if already present(unmount)
    if (isOverlayActive(overlayId)) {
      activeOverlays.value = activeOverlays.value.filter(id => id !== overlayId);
    } else {
      activeOverlays.value = [...activeOverlays.value, overlayId];
    }
  };

  const isOverlayActive = (overlayId: string | undefined): boolean => {
    if (overlayId === undefined) {
      return false;
    }
    return activeOverlays.value.includes(overlayId);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const allCodeEditorSchemas: Ref<{ schema: any; uri: string; fileMatch?: string[] }[]> = ref([]);

  const initialIDBLoadComplete: Ref<boolean> = ref(false);

  const sideMenuOpen: ComputedRef<boolean> = computed(() => {
    return mobileSideMenuOpen.value;
  });

  const searchMenuOverlayLocation: ComputedRef<SearchLocation> = computed(() => {
    return searchLocation.value;
  });

  const currentUiFlowMode: ComputedRef<string> = computed(() => {
    return flow.value?.mode ?? '';
  });

  const hideHeaderBar: ComputedRef<boolean> = computed(() => {
    const routeStore = useRouteStore(pinia);
    if (routeStore.route?.name === RouteNames.APP_ENVIRONMENT_TRACK_VIEW_FULL_SCREEN_NAME) {
      return true;
    }
    if (currentUiFlowMode.value === 'STANDALONE_OPTIONAL') {
      return !showEmbedded.value;
    }
    return appGuestView.value || ['STANDALONE', 'ISOLATED'].includes(currentUiFlowMode.value);
  });

  const hideSupplementaryMenu: ComputedRef<boolean> = computed(() => {
    if (flow.value?.mode === 'STANDALONE_OPTIONAL') {
      return !showEmbedded.value;
    }
    return appGuestView.value || ['STANDALONE', 'ISOLATED'].includes(currentUiFlowMode.value);
  });

  const hideTrackHeader: ComputedRef<boolean> = computed(() => {
    return appGuestView.value || ['STANDALONE', 'ISOLATED', 'STANDALONE_OPTIONAL'].includes(currentUiFlowMode.value);
  });

  function setCurrentFlow(flowToSet?: FlowConfig | null): void {
    flow.value = flowToSet ?? null;
  }

  function setAppGuestView(isAppGuestView: boolean): void {
    appGuestView.value = isAppGuestView;
  }

  function setMobileSideMenuOpen(value: boolean): void {
    mobileSideMenuOpen.value = value;
  }

  function toggleMobileSideMenuOpen(): void {
    mobileSideMenuOpen.value = !mobileSideMenuOpen.value;
  }

  function setShowEmbedded(newVal: boolean): void {
    showEmbedded.value = newVal;
  }

  function setAdditionalContentVisible(newVal: boolean): void {
    showAdditionalContent.value = newVal;
    localStorage.setItem(ADDITIONAL_CONTENT_VISIBLE_KEY, JSON.stringify(newVal));
  }

  function setSearchMenuOverlayLocation(location: SearchLocation): void {
    searchLocation.value = location;
  }

  function setAdditionalMeetingContentVisible(newVal: boolean): void {
    showAdditionalMeetingContent.value = newVal;
    localStorage.setItem(ADDITIONAL_MEETING_CONTENT_VISIBLE_KEY, JSON.stringify(newVal));
  }

  function setSelectedMeetingContentTab(newVal: MeetingContentTab): void {
    selectedMeetingContentTab.value = newVal;
    localStorage.setItem(ADDITIONAL_MEETING_CONTENT_TAB_KEY, JSON.stringify(newVal));
  }

  function setPrivacyMode(newVal: boolean): void {
    privacyMode.value = newVal;
  }

  function toggleExpandedTrackLabel(details: { labelId: string; labelValue: string }): void {
    let labelValues = expandedTrackLabels.value[details.labelId];
    if (!labelValues) {
      labelValues = {};
      Vue.set(expandedTrackLabels.value, details.labelId, labelValues);
    }
    // TODO: Do we want to persist this in local storage?
    Vue.set(expandedTrackLabels.value[details.labelId], details.labelValue, !labelValues[details.labelValue]);
  }

  function toggleExpandedTrack(trackId: string): void {
    const currentlyExpanded = expandedTracks.value[trackId];
    Vue.set(expandedTracks.value, trackId, !currentlyExpanded);
  }

  function setLastWorkspaceId(newVal: string): void {
    lastViewedWorkspaceId.value = newVal;
    localStorage.setItem(LAST_VIEWED_WORKSPACE_KEY, JSON.stringify(newVal));
  }

  function setLastChatId(newVal: string): void {
    lastViewedChatId.value = newVal;
    localStorage.setItem(LAST_VIEWED_CHAT_KEY, JSON.stringify(newVal));
  }

  function setChatFormattingExpanded(newVal: boolean): void {
    chatFormattingExpanded.value = newVal;
    localStorage.setItem(CHAT_FORMATTING_EXPANDED_KEY, JSON.stringify(newVal));
  }

  function setPreventOrientationBlock(value: boolean): void {
    preventOrientationBlock.value = value;
  }

  function setTabVisible(newVal: boolean): void {
    tabVisible.value = newVal;
  }

  function setInitialIDBLoadComplete(isComplete: boolean): void {
    initialIDBLoadComplete.value = isComplete;
  }

  async function loadSettings(): Promise<void> {
    try {
      const pushNotificationStore = usePushNotificationStore();
      pushNotificationStore.loadSettings();
    } catch (error) {
      // Catch so it doesn't risk the rest of the UI load (shouldn't because it's async, but better not risk it)
    }

    showAdditionalContent.value = getLocalStorageBoolean(ADDITIONAL_CONTENT_VISIBLE_KEY);
    // On mobile we always default to the additional content panel being closed on start.
    showAdditionalMeetingContent.value = !BrowserDetection.isMobile() &&
      getLocalStorageBoolean(ADDITIONAL_MEETING_CONTENT_VISIBLE_KEY, false);
    selectedMeetingContentTab.value =
      getLocalStorageString(ADDITIONAL_MEETING_CONTENT_TAB_KEY) as MeetingContentTab ?? MeetingContentTab.CHAT;
    lastViewedWorkspaceId.value = getLocalStorageString(LAST_VIEWED_WORKSPACE_KEY) ?? null;
    lastViewedChatId.value = getLocalStorageString(LAST_VIEWED_CHAT_KEY) ?? null;
    chatFormattingExpanded.value = getLocalStorageBoolean(CHAT_FORMATTING_EXPANDED_KEY);
  }

  function getLocalStorageString(key: string): string | null {
    const valueStr: string | null = localStorage.getItem(key);
    let value: string | null = null;
    if (valueStr !== null && valueStr !== undefined && valueStr !== '') {
      value = JSON.parse(valueStr);
    }
    return value;
  }

  function getLocalStorageBoolean(key: string, defaultValue: boolean = true): boolean {
    const valueStr: string | null = localStorage.getItem(key);
    let value: boolean = defaultValue;
    if (valueStr !== null && valueStr !== undefined && valueStr !== '') {
      value = JSON.parse(valueStr);
    }
    return value;
  }

  return {
    showAdditionalContent,
    showAdditionalMeetingContent,
    selectedMeetingContentTab,
    privacyMode,
    expandedTrackLabels,
    expandedTracks,
    lastViewedWorkspaceId,
    chatFormattingExpanded,
    showEmbedded,
    preventOrientationBlock,
    tabVisible,
    sideMenuOpen,
    searchMenuOverlayLocation,
    hideHeaderBar,
    hideSupplementaryMenu,
    hideTrackHeader,
    activePopupMenuId,
    setActivePopupMenuId,
    popupMenuIsVisible,
    setPopupMenuIsVisible,
    isOverlayActive,
    overlayIsActive,
    toggleActiveOverlays,
    allCodeEditorSchemas,
    initialIDBLoadComplete,
    setCurrentFlow,
    setMobileSideMenuOpen,
    toggleMobileSideMenuOpen,
    setShowEmbedded,
    setAdditionalContentVisible,
    setSearchMenuOverlayLocation,
    setAdditionalMeetingContentVisible,
    setSelectedMeetingContentTab,
    setPrivacyMode,
    toggleExpandedTrackLabel,
    toggleExpandedTrack,
    setLastWorkspaceId,
    setLastChatId,
    setChatFormattingExpanded,
    setAppGuestView,
    setPreventOrientationBlock,
    setTabVisible,
    loadSettings,
    setInitialIDBLoadComplete
  };
});
