export enum AudioDetectedState {
  NO_AUDIO = 0,
  AUDIO_DETECTED = 1,
  UNKNOWN = 2,
}

export enum JoinState {
  JOINED = 'JOINED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  WAITING_ROOM = 'WAITING_ROOM',
  INITIAL = 'INITIAL',
}

export interface LayoutImage {
  width: number;
  height: number;
  x: number;
  y: number;
  member: number;
  memberId: string;
  alternate: number;
  alternateId: string;
}

export interface LayoutsState {
  syncColor: string;

  layout1: LayoutImage[];
  layout2: LayoutImage[];
  layout3: LayoutImage[];
  layout4: LayoutImage[];
  layout5: LayoutImage[];
}

export enum NetworkCondition {
  GOOD_NETWORK = 'GOOD_NETWORK',
  BAD_NETWORK = 'BAD_NETWORK',
  VERY_BAD_NETWORK = 'VERY_BAD_NETWORK',
  RECOVERED_NETWORK = 'RECOVERED_NETWORK',
}

export enum ParticipantType {
  CLIENT = 'CLIENT',
  PSTN = 'PSTN',
  SIP = 'SIP',
}

export enum MeetingLockType {
  UNLOCKED = 'UNLOCKED',
  NO_GUESTS = 'NO_GUESTS',
  WAITING_ROOM_FOR_GUESTS = 'WAITING_ROOM_FOR_GUESTS',
  WAITING_ROOM = 'WAITING_ROOM',
  LOCKED = 'LOCKED',
}

export enum MuteState {
  enabled,
  muted,
  forceMuted,
}

export enum MuteType {
  AUDIO = 'audio',
  VIDEO = 'video',
}

export enum OngoingMeetingState {
  NO_MEETING,
  IN_PRE_MEET,
  IN_MEETING,
}

export enum Layouts {
  ACTIVE_SPEAKER = '1x1',
  GRID = 'group:grid',
  FILM_STRIP = 'group:5top',
  GRID_MOBILE_PORTRAIT = '2x2mobile',
  GRID_MOBILE_LANDSCAPE = '2x2',
}

export const LAYOUT_ID_MAPPING: string[] = [
  '',
  Layouts.ACTIVE_SPEAKER,
  Layouts.GRID_MOBILE_LANDSCAPE,
  Layouts.GRID_MOBILE_PORTRAIT,
  Layouts.FILM_STRIP,
  Layouts.GRID
];

export interface Participant {
  nodeId: string;
  visible: boolean;
  displayName: string;
  username: string;
  audioMuted: boolean;
  videoMuted: boolean;
  poorNetwork: boolean;
  networkCondition?: NetworkCondition;
  deviceType: ParticipantType;
}

export interface MediaOption {
  deviceId: string;
  deviceName: string;
  selected: boolean;
}

export enum LocalMediaState {
  LOCAL_MEDIA_OK = 0,
  LOCAL_MEDIA_FAILED = 1,
  LOCAL_AUDIO_FAILED = 2,
  LOCAL_VIDEO_FAILED = 3,
}

export enum RemoteMediaState {
  REMOTE_MEDIA_OK = 0,
  REMOTE_MEDIA_FAILED = 1,
  NETWORK_ERROR = 2,
}

interface MediaState {
  localMediaState: LocalMediaState;
  remoteMediaState: RemoteMediaState;
}

export interface MeetingsState {
  activeSpeaker: Participant;
  trueActiveSpeaker: Participant;
  currentlyRecording: boolean;
  localNodeId: string;
  sharingNodeId: string;
  mediaReceived: boolean;
  videoDevices: MediaOption[];
  audioInputDevices: MediaOption[];
  audioOutputDevices: MediaOption[];
  audioDetected: AudioDetectedState;
  volume: number;
  mediaState: MediaState;
  joinState: JoinState;
  layouts: LayoutsState;
  layoutIndex: number;
  awaitingCameraRequest: boolean;
  awaitingMicrophoneRequest: boolean;
}

export interface ParticipantState {
  participants: Participant[];
}

export interface StreamListener {
  localStreamEstablished(nodeId: string, element: HTMLElement): void;
  activeSpeakerStreamEstablished(element: HTMLElement): void;
  nodeStreamConnected(nodeId: string, element: HTMLElement, pstn: boolean): void;
  nodeStreamDisconnected(nodeId: string): void;
  galleryLayoutUpdate(nodeIds: string[]): void;
  nodeRateUpdate(nodeId: string, kbitsPerSecond: number): void;
  publishRateUpdate(kbitsPerSecond: number): void;
}

export interface DialInDetails {
  name: string;
  id: string;
  dialString: string;
}

type StateType = MeetingsState | ParticipantState;
type StateCallback<T extends StateType> = (state: T) => void;
type VolumeCallback = (volume: number) => void;
type ForceMuteCallback = (muteType: MuteType) => void;
type RequestUnmuteCallback = (muteType: MuteType) => void;
type ForceKickCallback = () => void;

export interface MeetingsInterface {
  attachActiveSpeakerVideo: (div: HTMLDivElement) => Promise<void>;
  attachParticipantCanvas: (div: HTMLDivElement, participantNodeId: string) => Promise<void>;
  attachMiniActiveSpeakerVideo: (div: HTMLDivElement) => Promise<void>;
  attachSelfVideo: (div: HTMLDivElement) => Promise<void>;
  requestUnmute: (nodeID: string, muteType: MuteType) => void;
  registerForceMuteCallback: (callback: ForceMuteCallback) => Promise<void>;
  registerRequestUnmuteCallback: (callback: RequestUnmuteCallback) => Promise<void>;
  toggleLocalAudio: () => boolean;
  toggleLocalVideo: () => boolean;
  setLocalAudio: (on: boolean) => void;
  setLocalVideo: (on: boolean) => void;
  initMeeting: (versionedPath: string, meetingId: string, externalData: string) => Promise<void>;
  joinMeeting: (layout?: string) => void;
  leaveMeeting: () => void;
  setMicrophone: (deviceId: string, deviceName: string) => void;
  setCamera: (deviceId: string, deviceName: string) => void;
  setOutput: (deviceId: string, deviceName: string) => void;
  setVolume: (volume: number) => void;
  testAudio: (audioFile?: string) => Promise<void>;
  screenShareSupported: () => boolean;
  startScreenShare: () => void;
  endScreenShare: () => void;
  updateDisplayName: (displayName: string) => void;
  getRecordingDuration: () => number;
  registerForceKickCallback: (callback: ForceKickCallback) => Promise<void>;
  registerParticipantCallback: (callback: StateCallback<ParticipantState>) => void;
  deregisterParticipantCallback: (callback: StateCallback<ParticipantState>) => void;
  registerMeetingsCallback: (callback: StateCallback<MeetingsState>) => void;
  deregisterMeetingsCallback: (callback: StateCallback<MeetingsState>) => void;
  getDialInNumbers: () => DialInDetails[];
  dialOut: (numberToDial: number) => void;
  createSoundMeter: (callback: VolumeCallback) => void;
  destroySoundMeter: () => void;
  shouldIgnoreLimitedMode: (ignore: boolean) => void;
  setClientSideVideoOverlay: (clientSideOverlay: boolean) => void;
  attemptToFixAudioInput(): Promise<void>;

  updateLogo(logoPath: string): void;
  updateBanner(banner: string): void;
  updateLayout(layout: string): void;
  updatePinnedParticipant(pinnedParticipantNodeId: string): void;

  setStreamListener(streamListener: StreamListener): void;
}

// tslint:disable-next-line:max-classes-per-file
declare class MeetingsConfig {
  public JOIN_PARTICIPANT_CONFERENCE: boolean;
  public STOP_VIDEO_ON_SCREENSHARE: boolean;
  public USE_PASSTHROUGH_TOPOLOGY: boolean;
  public USE_PASSTHROUGH_SCREENSHARE: boolean;
  public FORCE_MUTE_VIDEO_AT_START: boolean;
}

export interface MeetingsGetterInterface {
  getInstance: () => MeetingsInterface;
}

declare global {
  let MeetingsApi: MeetingsGetterInterface;
  let MeetingsConfig: MeetingsConfig;
}
