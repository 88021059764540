import log from 'loglevel';
import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import { ViewableEntry } from '@/components/files/files.types';
import { AddApproval, Approval, DeleteApproval, GetApprovals, UpdateApproval } from '@/data/datatypes/Approvals';
import { CapabilityResponse } from '@/data/datatypes/CapabilityResponse';
import OpenGraph from '@/data/datatypes/chat/OpenGraph';
import { EmojiReactionPayload } from '@/data/datatypes/EmojiReactionPayload';
import { HistoryRecord } from '@/data/datatypes/HistoryRecord';
import { TrackOnlineStatus } from '@/data/datatypes/online/TrackOnlineStatus';
import {
  EntryDetails,
  EntryType,
  isEntryPublishable,
  TrackEntry,
  TrackEntryDetails,
} from '@/data/datatypes/TrackEntry';
import UserToken from '@/data/datatypes/UserToken';
import { isDownloadableApp } from '@/data/helpers/EntriesHelper';
import {
  BinaryFileUploadPayload,
  FileUploadResult,
  setupFileUploadCalls,
  triggerFileDownload,
} from '@/data/helpers/FileHelper';
import DataWorker from '@/data/storage/DataWorker';
import { useChatMessagesStore } from '@/stores/ChatMessages';
import pinia from '@/stores/index';
import { useRouteStore } from '@/stores/Route';
import { asRecord, setOrPatchObject } from '@/stores/StoreHelper';
import {
  CreateRoomPayload,
  DownloadItem,
  EntriesByTrackId,
  EntryDetailsPayload,
  EntryVersion,
  FoldersAddPayload,
  GetEntryAsGuestPayload,
  InnerEntryDetailsPayload,
  LinkUploadPayload,
  MoveEntryPayload,
  PushEntryPayload,
  RestoreVersionPayload,
  UpdateOnlineStatusPayload,
  UpdateTitlePayload,
} from '@/stores/TrackEntries.types';
import { useTrackOnlineStatusesStore } from '@/stores/TrackOnlineStatuses';
import { useTracksStore } from '@/stores/Tracks';
import { useUserStore } from '@/stores/User';

export const useTrackEntriesStore = defineStore('TrackEntries', () => {
  const tracksStore = useTracksStore(pinia);
  const userStore = useUserStore(pinia);

  const entries: Ref<EntriesByTrackId> = ref({});

  /** If set, this will be the {@link TrackEntry (with a `type` of
   * {@link EntryType.file or @link EntryType.appItem or @link EntryType.link })
   * that is displayed in the file view overlay  */
  const currentlyDisplayedFile: Ref<ViewableEntry | null> = ref(null);
  // The name of the menu to pre-open when viewing a file, to be used in conjunction with currentlyDisplayedFile above.
  const fileMenuToOpen: Ref<string | null> = ref(null);
  const currentlyUploadingRevision: Ref<ViewableEntry | null> = ref(null);
  const entriesInitialised: Ref<Record<string, boolean>> = ref({});
  const returnToPreviousRoute: Ref<boolean> = ref(false);
  const guestMeetingRecordEntry: Ref<TrackEntry | null> = ref(null);

  const entriesByTrack: ComputedRef<EntriesByTrackId> = computed(() => {
    return entries.value;
  });

  const activeEntryId: ComputedRef<string | null> = computed(() => {
    const routeStore = useRouteStore(pinia);
    return routeStore.route?.params.entryId ?? null;
  });

  const entriesByParent: ComputedRef<TrackEntry[] | null> = computed(() => {
    const trackId: string | null = tracksStore.activeTrackId;
    if (!trackId) {
      return null;
    }
    const parentEntryId: string | null = activeEntryId.value;
    const all: TrackEntry[] = (entries.value[trackId] && Object.values(entries.value[trackId]));
    if (!parentEntryId) {
      return null;
    }
    if (all) {
      for (let i = 0; i < all.length; i++) {
        if (all[i].parentId !== parentEntryId) {
          all.splice(i, 1);
          i--;
        }
      }
      return all;
    } else {
      return null;
    }
  });

  const activeEntry: ComputedRef<TrackEntry | undefined> = computed(() => {
    if (activeEntryId.value) {
      const activeTrackId: string | null = tracksStore.activeTrackId;
      if (activeTrackId && entries.value[activeTrackId]) {
        return entries.value[activeTrackId][activeEntryId.value];
      }
    }
    return undefined;
  });

  const defaultMeetingEntryId: ComputedRef<string | undefined> = computed(() => {
    const activeTrackId: string | null = tracksStore.activeTrackId;
    if (!activeTrackId) {
      return;
    }

    // Guest? Get it from the guest token
    const isGuest: boolean = userStore.isGuestUser || userStore.isGuestMember(activeTrackId);
    if (isGuest) {
      const guestToken: UserToken | undefined = userStore.guestTokenForTrack(activeTrackId);
      return guestToken?.meid;
    }

    return defaultMeetingEntry.value?.id;
  });

  const defaultMeetingEntry: ComputedRef<TrackEntry | undefined> = computed(() => {
    return entriesForActiveTrack.value.find((entry: TrackEntry) => {
      return entry.defaultEntry && entry.type === EntryType.meeting;
    });
  });

  const entriesForTrack: ComputedRef<(trackId: string) => TrackEntry[]> = computed(() => {
    return (trackId: string) => {
      return entries.value[trackId] ? Object.values(entries.value[trackId]) : [];
    };
  });

  const entriesForActiveTrack: ComputedRef<TrackEntry[]> = computed(() => {
    const trackId: string | null = tracksStore.activeTrackId;
    if (trackId && entries.value[trackId]) {
      return Object.values(entries.value[trackId]);
    } else {
      return [];
    }
  });

  const entry: ComputedRef<(trackId: string, trackEntryId: string) => TrackEntry | undefined> = computed(() => {
    return (trackId: string, trackEntryId: string) => {
      if (entries.value[trackId] && entries.value[trackId][trackEntryId]) {
        return entries.value[trackId][trackEntryId];
      }
    };
  });

  const breakoutRoomsByTrack: ComputedRef<Record<string, TrackEntry[]>> = computed(() => {
    const filteredTrackEntries: Record<string, TrackEntry[]> = {};
    Object.keys(entries.value).forEach((trackId: string) => {
      const trackEntries: TrackEntry[] = Object.values(entries.value[trackId]).filter((entry: TrackEntry) => {
        return entry.type === EntryType.private;
      }) ?? [];
      filteredTrackEntries[trackId] = trackEntries;
    });
    return filteredTrackEntries;
  });

  const publishRoomsByTrack: ComputedRef<Record<string, TrackEntry[]>> = computed(() => {
    const filteredTrackEntries: Record<string, TrackEntry[]> = {};
    Object.keys(entries.value).forEach((trackId: string) => {
      const trackEntries: TrackEntry[] = Object.values(entries.value[trackId]).filter((entry: TrackEntry) => {
        return entry.type === EntryType.publish_room;
      }) ?? [];
      filteredTrackEntries[trackId] = trackEntries;
    });
    return filteredTrackEntries;
  });

  const breakoutRoomsForActiveTrack: ComputedRef<TrackEntry[]> = computed(() => {
    const onlyBreakoutRooms = (entry: TrackEntry) => {
      return entry.type === EntryType.private;
    };

    return entriesForActiveTrack.value.filter(onlyBreakoutRooms);
  });

  const publishRoomsForActiveTrack: ComputedRef<TrackEntry[]> = computed(() => {
    const onlyPublishRooms = (entry: TrackEntry) => {
      return entry.type === EntryType.publish_room;
    };

    return entriesForActiveTrack.value.filter(onlyPublishRooms);
  });

  function setEntry(entry: TrackEntry): void {
    let trackEntries: { [entryId: string]: TrackEntry } = entries.value[entry.trackId];
    if (!trackEntries) {
      trackEntries = {};
      Vue.set(entries.value, entry.trackId, trackEntries);
    }
    // The server set the title to the string 'Subject' initially, so we need to work around that
    if (entry.type === EntryType.email && !entry.parentId && entry.title === 'Subject') {
      const subject: string = Object.values(trackEntries).filter((entry2: TrackEntry) => {
        return entry2.parentId === entry.id;
      }).sort((a: TrackEntry, b: TrackEntry) => {
        return (a.created ?? 0) - (b.created ?? 0);
      })[0]?.title ?? 'Email';
      entry.title = subject;
    }
    setOrPatchObject(trackEntries, entry.id, asRecord(entry));
  }

  function removeEntry(entry: TrackEntry): void {
    if (entries.value[entry.trackId] && entries.value[entry.trackId][entry.id]) {
      Vue.delete(entries.value[entry.trackId], entry.id);
    }
  }

  function setParentId(payload: MoveEntryPayload): void {
    if (!entries.value[payload.trackId] || !entries.value[payload.trackId][payload.childId]) {
      return;
    }
    if ((payload.childId !== payload.parentId) && (payload.childId)) {
      Vue.set(entries.value[payload.trackId][payload.childId], 'parentId', payload.parentId);
    }
  }

  function setCurrentlyDisplayedFile(file: ViewableEntry | null): void {
    currentlyDisplayedFile.value = file;
  }

  function setCurrentlyUploadingRevision(file: ViewableEntry | null): void {
    currentlyUploadingRevision.value = file;
  }

  /**
   * Triggers the requested side menu to open.
   *
   * This is only a trigger to open. Once a value has been set, it will naturally go back to null once the change
   * has been applied. This is to ensure the setting does not become a default (as otherwise, until unset,
   * said side panel would constantly stay open, even when changing files).
   *
   * This means setting the null value cannot be used to make the panel disappear
   * ('none' should be used for that purpose).
   *
   * @param menuName The name of the side menu to open.
   *    Current values are 'history', 'comments', 'approvals', 'none'.
   */
  function setFileMenuToOpen(menuName: string | null): void {
    fileMenuToOpen.value = menuName;
  }

  function setGuestMeetingRecordEntry(meetingRecordEntry: TrackEntry | null): void {
    guestMeetingRecordEntry.value = meetingRecordEntry;
  }

  function clearEntries(): void {
    entries.value = {};
  }

  function setEntriesInitialised(details: { trackId: string; initialised: boolean }): void {
    Vue.set(entriesInitialised.value, details.trackId, details.initialised);
  }

  function setReturnToPreviousRoute(value: boolean): void {
    returnToPreviousRoute.value = value;
  }

  /**
   * Copies a {@link TrackEntry} from its parent track, into a Publish Room
   *
   * @param publishRoomId The ID of the `TrackEntry` representing the Publish Room. Note that the Publish Room must
   * belong to the entry's parent `Track` or this operation will fail
   * @param entry The `TrackEntry` which is being copied to the Publish Room. Only a {@link TrackEntry} with a `type` of
   * {@link EntryType.file} is valid
   */
  async function pushEntryToPublishRoom({ publishRoomId, entry }: PushEntryPayload): Promise<TrackEntry> {
    if (!isEntryPublishable(entry)) {
      log.warn(`TrackEntries/pushEntryToPublishRoom attempted to push entry with type: ${entry.type}`);
      throw new Error(`TrackEntry with type ${entry.type} cannot be pushed to a publish room`);
    }
    return await DataWorker.instance()
      .dispatch('TrackEntries/pushEntryToPublishRoom', entry.trackId, publishRoomId, entry.id);
  }

  function setEntries(details: { entries: TrackEntry[]; fullRefresh: boolean; setOGPData: boolean }): void {
    if (details.fullRefresh) {
      clearEntries();
    }
    for (const entry of details.entries) {
      setEntry(entry);
      if (details.setOGPData) {
        setOpenGraphDataIfRequired(entry);
      }
    }
    // TODO: notifications about any new entries
  }

  function setEntriesForTrack(details: { trackId: string; entries: TrackEntry[]; setOGPData: boolean }): void {
    const existingEntriesForTrack: Record<string, TrackEntry> = entries.value[details.trackId] || {};
    for (const entry of Object.values(existingEntriesForTrack)) {
      if (!details.entries.find((current: TrackEntry) => current.id === entry.id)) {
        removeEntry(entry);
      }
    }
    setEntries({
      entries: details.entries,
      fullRefresh: false,
      setOGPData: details.setOGPData
    });
  }

  async function requestCapability(details: { trackId: string; entryId: string; capability: string }): Promise<void> {
    const response: CapabilityResponse = await DataWorker.instance().dispatch('TrackEntries/requestCapability',
      details.trackId, details.entryId, details.capability);
    setEntry(response.entry);
  }

  async function uploadFiles(payload: BinaryFileUploadPayload): Promise<void> {
    // Assume that the upload is for the active track:
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      const uploadResults: FileUploadResult[] = [];
      const workerArgResolver = (file: File, fileId: string | undefined) => {
        return [payload.trackId, file.name, payload.parentId, payload.chatMessageId, fileId];
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const uploadPromises: Array<Promise<any>> = await setupFileUploadCalls(payload, uploadResults,
        'TrackEntries/createBinaryEntry', workerArgResolver);
      await Promise.all(uploadPromises);
      if (payload.onFinishedCallback) {
        payload.onFinishedCallback(uploadResults);
      }
    } catch (error) {
      log.error(`Failed to upload files: ${error}`);
    }
  }

  async function updateFiles(payload: BinaryFileUploadPayload): Promise<void> {
    // Assume that the upload is for the active track:
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      const uploadResults: FileUploadResult[] = [];
      const workerArgResolver = (file: File, fileId: string | undefined) => {
        return [payload.trackId, file.name, payload.parentId, payload.chatMessageId, fileId];
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const uploadPromises: Array<Promise<any>> = await setupFileUploadCalls(payload, uploadResults,
        'TrackEntries/updateBinaryEntry', workerArgResolver);
      await Promise.all(uploadPromises);
      if (payload.onFinishedCallback) {
        payload.onFinishedCallback(uploadResults);
      }
    } catch (error) {
      log.error(`Failed to update files: ${error}`);
    }
  }

  async function uploadLinks(payload: LinkUploadPayload): Promise<void> {
    // Assume that the upload is for the active track:
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      for (const url of payload.linkUrls) {
        const entryToCreate: TrackEntryDetails = {
          trackId: payload.trackId,
          type: EntryType.link,
          url: url,
          parentId: payload.parentId,
        };
        await createEntry(entryToCreate);
      }
    } catch (error) {
      log.error(`Failed to upload links: ${error}`);
    }
  }

  async function createEntry(entryToCreate: TrackEntryDetails): Promise<TrackEntry | undefined> {
    // Assume that the entry is for the active track:
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      const createdEntry = await DataWorker.instance().dispatch('TrackEntries/createEntry', entryToCreate);
      // TODO: notification about the new entry
      setEntry(createdEntry);
      setOpenGraphDataIfRequired(createdEntry);
      return createdEntry;
    } catch (error) {
      log.error(`Failed to add track entry: ${error}`);
    }
  }

  async function getVersions(trackEntry: TrackEntry): Promise<EntryVersion[] | undefined> {
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      return await DataWorker.instance().dispatch('TrackEntries/getVersionHistory', trackEntry);
    } catch (error) {
      log.error(`Failed to get versions: ${error}`);
    }
  }

  async function restoreEntryVersion(restoreVersionPayload: RestoreVersionPayload): Promise<void> {
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      const restored: TrackEntry = await DataWorker.instance().dispatch('TrackEntries/restoreVersion',
        restoreVersionPayload);
      if (restored) {
        setEntry(restored);
      }
    } catch (error) {
      log.error(`Failed to restore version: ${error}`);
    }
  }

  async function setOpenGraphDataIfRequired(entry: TrackEntry): Promise<void> {
    const chatMessagesStore = useChatMessagesStore(pinia);
    if (entry.type === EntryType.link && entry.url && entry.openGraphItems == null) {
      const ogpData: OpenGraph = await chatMessagesStore.getOpenGraphData({ url: entry.url, trackId: entry.trackId });
      // The entry parameter might not necessarily be the exact object that we have in our state.
      const entryToUpdate: TrackEntry = entries.value[entry.trackId][entry.id];
      if (entryToUpdate) {
        Vue.set(entryToUpdate, 'openGraphItems', [ogpData]);
      }
    }
  }

  async function addFolders(payload: FoldersAddPayload): Promise<void> {
    // Assume that the upload is for the active track:
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      for (const name of payload.folderNames) {
        const entryToCreate: TrackEntryDetails = {
          trackId: payload.trackId,
          type: EntryType.folder,
          title: name,
          parentId: payload.parentId,
        };
        await createEntry(entryToCreate);
      }
    } catch (error) {
      log.error(`Failed to add folders: ${error}`);
    }
  }

  async function createBreakoutRoom(payload: CreateRoomPayload): Promise<void> {
    // Assume that the upload is for the active track:
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      const entryToCreate: TrackEntryDetails = {
        trackId: payload.trackId,
        type: EntryType.private,
        title: payload.name,
      };
      await createEntry(entryToCreate);
      // refresh tracks since there should be a new one for the breakout room
      await DataWorker.instance().dispatch('Tracks/refreshTracks');
    } catch (error) {
      log.error(`Failed to create room: ${error}`);
    }
  }

  async function getEntryAsGuest(payload: GetEntryAsGuestPayload): Promise<TrackEntry | null> {
    const { trackId, entryId, guestToken } = payload;
    return await DataWorker.instance().dispatch('TrackEntries/getEntryAsGuest', trackId, entryId, guestToken);
  }

  async function moveEntry(payload: MoveEntryPayload): Promise<void> {
    // Assume that the entry belongs to the active track:
    if (userStore.isGuestOfActiveTrack) {
      return;
    }

    try {
      // check that the entry being moved a parent of the entry it's being moved into
      let targetEntry: TrackEntry = entries.value[payload.trackId][payload.parentId ?? ''];

      while (targetEntry) {
        if (targetEntry.parentId === payload.childId) {
          log.error('Attempted to move entry inside itself');
          return;
        }

        targetEntry = entries.value[payload.trackId][targetEntry.parentId ?? ''];
      }

      if (payload.childId !== payload.parentId) {
        setParentId(payload);

        const entry: TrackEntry = {
          trackId: payload.trackId,
          id: payload.childId,
          parentId: (payload.parentId ? payload.parentId : '0'),
        };
        const updated: TrackEntry = await DataWorker.instance().dispatch('TrackEntries/updateEntry',
          payload.trackId, entry);
        setEntry(updated);
      }
    } catch (error) {
      log.error(`Failed to move entry: ${error}`);
    }
  }

  async function updateTitle(payload: UpdateTitlePayload): Promise<void> {
    // Assume that the entry belongs to the active track:
    if (userStore.isGuestOfActiveTrack) {
      return;
    }

    try {
      const entry: TrackEntry = {
        trackId: payload.trackId,
        id: payload.entryId,
        title: payload.title,
      };
      const updated: TrackEntry = await DataWorker.instance().dispatch('TrackEntries/updateEntry',
        payload.trackId, entry);
      setEntry(updated);
    } catch (error) {
      log.error(`Failed to update entry title: ${error}`);
    }
  }

  async function deleteEntry(entry: TrackEntry): Promise<void> {
    // Assume that the entry belongs to the active track:
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    if (!entries.value[entry.trackId]) {
      return;
    }
    try {
      await DataWorker.instance().dispatch('TrackEntries/deleteEntry', entry);
      removeEntry(entry);
    } catch (error) {
      log.error(`Failed to delete Track entry: ${error}`);
    }
  }

  async function trackEntryDetails(payload: EntryDetailsPayload): Promise<EntryDetails[]> {
    try {
      return await DataWorker.instance()
        .dispatch('TrackEntries/getTrackEntryDetails', payload.trackId, payload.entryId) ?? [];
    } catch (error) {
      log.error(`Failed to get inner track entry details: ${error}`);
    }
    return [];
  }

  async function innerTrackEntryDetails(payload: InnerEntryDetailsPayload): Promise<EntryDetails[]> {
    try {
      return await DataWorker.instance().dispatch('TrackEntries/getInnerTrackEntryDetails',
        payload.trackId, payload.entryId, payload.innerTrackId, payload.innerTrackEntryId) ?? [];
    } catch (error) {
      log.error(`Failed to get inner track entry details: ${error}`);
    }
    return [];
  }

  async function entryHistory(entry: TrackEntry): Promise<HistoryRecord[] | undefined> {
    try {
      log.debug('Getting entry history for' + entry.id);
      return await DataWorker.instance().dispatch('TrackEntries/getEntryHistory', entry?.trackId, entry?.id);
    } catch (error) {
      log.error(`Failed to get history: ${error}`);
    }
  }

  async function getApprovalsForEntry(getApprovals: GetApprovals): Promise<Approval[] | undefined> {
    try {
      return await DataWorker.instance().dispatch('TrackEntries/getApprovals', getApprovals);
    } catch (error) {
      log.error(`Failed to get approvals: ${error}`);
    }
  }

  async function updateApprovalsForEntry(updateApproval: UpdateApproval): Promise<Approval[] | undefined> {
    try {
      return await DataWorker.instance().dispatch('TrackEntries/updateApproval', updateApproval);
    } catch (error) {
      log.error(`Failed to update approvals: ${error}`);
    }
  }

  async function addApprovalForEntry(addApproval: AddApproval): Promise<Approval | undefined> {
    try {
      return await DataWorker.instance().dispatch('TrackEntries/addApproval', addApproval);
    } catch (error) {
      log.error(`Failed to add approval: ${error}`);
    }
  }

  async function deleteApprovalForEntry(deleteApproval: DeleteApproval): Promise<void> {
    try {
      await DataWorker.instance().dispatch('TrackEntries/deleteApproval', deleteApproval);
    } catch (error) {
      log.error(`Failed to delete approval: ${error}`);
    }
  }

  function copyLink(trackEntry: TrackEntry): string {
    let url = location.origin + '/t/' + trackEntry.trackId + '/e/' + trackEntry.id;
    // note don't let people copy google links directly - open invites can be too easy to share
    if ((trackEntry) && (`${trackEntry.type}` === 'link') &&
      (!`${trackEntry.subType}`.startsWith('google')) && trackEntry.url) {
      url = trackEntry.url;
    }
    return url;
  }

  async function updateOnlineStatus(updateOnlineStatusPayload: UpdateOnlineStatusPayload): Promise<void> {
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      const updatedOnlineStatus: TrackOnlineStatus = await DataWorker.instance().dispatch(
        'OnlineStatus/updateOnlineStatus', Date.now(), updateOnlineStatusPayload.trackId,
        updateOnlineStatusPayload.entryId);

      const trackOnlineStatusesStore = useTrackOnlineStatusesStore(pinia);
      trackOnlineStatusesStore.setTrackOnlineStatuses({ trackOnlineStatus: [updatedOnlineStatus], fullRefresh: false });
    } catch (error) {
      log.error(`Failed to add track entry: ${error}`);
    }
  }

  async function requestDownload(entry: TrackEntry): Promise<void> {
    let downloadUrl = '';
    const entryToUseForAppItemDownload = entry;
    if (isDownloadableApp(entry)) {
      const downloadItem: DownloadItem = {
        appId: entryToUseForAppItemDownload.subType || '',
        entryId: entryToUseForAppItemDownload.id,
        trackId: entryToUseForAppItemDownload.trackId,
        // eslint-disable-next-line
        itemId: entryToUseForAppItemDownload.state ? (entryToUseForAppItemDownload.state as any).appItemId : undefined,
      };
      await DataWorker.instance().dispatch('TrackEntries/getAppDownloadUrl', downloadItem).then((appDownloadUrl) => {
        downloadUrl = appDownloadUrl;
      });
    } else {
      downloadUrl = await DataWorker.instance().dispatch('TrackEntries/getBinaryUrl', entry.trackId, entry.id);
    }

    triggerFileDownload(downloadUrl);
  }

  async function toggleEntryReaction(payload: EmojiReactionPayload): Promise<void> {
    try {
      const updatedEntry: TrackEntry | undefined = await DataWorker.instance().dispatch(
        'TrackEntries/toggleEntryReaction', payload);
      if (updatedEntry) {
        setEntry(updatedEntry);
      }
    } catch (error) {
      log.error(error);
    }
  }

  async function getApprovedEntryIds(trackId: string): Promise<string[]> {
    try {
      return await DataWorker.instance().dispatch('TrackEntries/getApprovedEntryIds', trackId);
    } catch (error) {
      log.error(`Failed to get approved entry IDs: ${error}`);
    }
    return [];
  }

  async function refreshEntries(): Promise<void> {
    if (userStore.isGuestUser) {
      return;
    }
    await DataWorker.instance().dispatch('TrackEntries/refreshEntries');
  }

  async function retagEntry(entry: TrackEntry): Promise<void> {
    if (userStore.isGuestOfActiveTrack) {
      return;
    }

    try {
      await DataWorker.instance().dispatch('TrackEntries/retagEntry', entry.trackId, entry);
    } catch (error) {
      log.error(`Failed to retag entry: ${error}`);
    }
  }

  return {
    entries,
    currentlyDisplayedFile,
    fileMenuToOpen,
    currentlyUploadingRevision,
    entriesInitialised,
    returnToPreviousRoute,
    guestMeetingRecordEntry,
    entriesByTrack,
    activeEntryId,
    entriesByParent,
    activeEntry,
    defaultMeetingEntryId,
    defaultMeetingEntry,
    entriesForTrack,
    entriesForActiveTrack,
    entry,
    breakoutRoomsByTrack,
    publishRoomsByTrack,
    breakoutRoomsForActiveTrack,
    publishRoomsForActiveTrack,
    setEntry,
    setCurrentlyDisplayedFile,
    setCurrentlyUploadingRevision,
    setFileMenuToOpen,
    setGuestMeetingRecordEntry,
    setEntriesInitialised,
    setReturnToPreviousRoute,
    pushEntryToPublishRoom,
    setEntries,
    setEntriesForTrack,
    requestCapability,
    uploadFiles,
    updateFiles,
    uploadLinks,
    getVersions,
    restoreEntryVersion,
    addFolders,
    createBreakoutRoom,
    getEntryAsGuest,
    moveEntry,
    updateTitle,
    deleteEntry,
    trackEntryDetails,
    innerTrackEntryDetails,
    entryHistory,
    getApprovalsForEntry,
    updateApprovalsForEntry,
    addApprovalForEntry,
    deleteApprovalForEntry,
    copyLink,
    updateOnlineStatus,
    requestDownload,
    toggleEntryReaction,
    getApprovedEntryIds,
    refreshEntries,
    retagEntry
  };
});
