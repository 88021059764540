// A data source represents a location where a series of records
// can be read or updated. The data sources are configured at the
// the tenant level and optionally parameterised using the labels
// from the workspace.

import log from 'loglevel';

import { DataSetAttribute } from '@/data/tasks/customviews/DataSetConfig';
import { cloneTask, Task } from '@/data/tasks/Task';

export enum DataSourceType {
  REST = 'REST'
}

export interface DataSourceResult {
  records?: Task[];
  success: boolean;
  errorMessage?: string;
  page: number;
  totalPageCount: number;
  pageSize: number;
  pagedResults: boolean;
  date: number;
  parameters: Record<string, string>;
  name?: string;
}

export interface AppAuthResult {
  tokenId: string;
  created: number;
  expiry: number;
  trackLevel: boolean;
  success: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseMinusToken?: any;
  duration: number,
  status: number;
  statusLine: string;
}

export interface DataSourceConfig {
  // The labels that are required to be populated for a data source
  // to be able to retrieve its records
  required: string[];
  // Indicate is the data source should be reset when an element
  // is created - useful where the POST is a search and creates
  // a set of new records in return
  resetOnCreate: boolean;
}

export interface RestDataSourceOperationUrls {
  // URL for POST/Create operation
  create: string;
  // URL for GET/Read operation
  read: string;
  // URL for PUT/Update operation
  update: string;
  // URL for DELETE/Delete operation
  delete: string;
}

export interface RestHeaders {
  // The header that will be used to determine page index if any
  page: string;
  // The header that will be used to determine the total number of pages if any
  totalPageCount: string;
  // The header that will be used to determine the size of each page
  // (the number of records return in a single request) if any
  pageSize: string;
}

export interface DataSourceConfigParam {
  name: string;
  value?: string;
  /** Not included in API v2 config schema */
  fromType?: string;
  /** Not included in API v2 config schema */
  from?: string;
  /** @deprecated. Not included in API v2 config schema */
  fromLabel?: string;
}
export interface RestDataSourceUrlParameter extends DataSourceConfigParam {
  /** @deprecated - I think completely unused. Not included in API v2 config schema */
  inputLabel?: string;
  /** @deprecated - I think completely unused. Not included in API v2 config schema */
  options?: Record<string, string>;
  allowedValues?: string[];
  addToQueryString: 'AUTO' | 'NEVER' | 'NON_NULL' | 'NON_EMPTY' | 'ALWAYS';
}

export type PayloadDataType = 'text' | 'number' | 'date' | 'boolean' | 'object' | 'array' | 'unknown';

export interface PayloadField extends DataSourceConfigParam {
  type: PayloadDataType,
}

export interface RestPayloadDefinition {
  fields: PayloadField[];
}

export interface RestDataSourceHeaderAuthConfiguration {
  type: 'AUTH_HEADERS',
  bearer?: string;
  params?: Record<string, string>,
  requestHeaders: Array<{ name: string, value: string }>,
}

export interface RestDataSourceBasicAuthConfiguration {
  type: 'BASIC',
  params?: Record<string, string>,
  username: string,
  password: string,
}

export interface RestDataSourceOAuthConfiguration {
  type: 'OAUTH',
  params?: Record<string, string>,
  tokenUrl: string,
  clientId: string,
  clientSecret: string,
  scope?: string,
}

export interface RestDataSourceGainwellAuthConfiguration {
  type: 'GAINWELL_CLAIMS',
  params: Record<string, string>;
}

export type RestDataSourceAuthConfiguration = RestDataSourceHeaderAuthConfiguration | RestDataSourceOAuthConfiguration
  | RestDataSourceGainwellAuthConfiguration | RestDataSourceBasicAuthConfiguration;

export interface SubstitutionParameter {
  name: string;
}

export interface AppAuthConfig {
  tokenPath?: string,
  expiryPath?: string,
  defaultTokenExpiryMillis?: number,
  sso?: boolean,
  ssoUrlParameter?: string,
  ssoOutputAttribute?: string,
  tokenRefresh?: boolean,
  refreshTokenPath?: string,
}

export interface RestDataSourceConfig extends DataSourceConfig {
  // The url that should be read to retrieve the records
  url: string;
  // An explicit definition of the parameters that will be used as URL substitution parameters and/or query parameters
  urlParameters: RestDataSourceUrlParameter[];
  // The definition of the payload to send for relevant requests
  payload: RestPayloadDefinition;
  // The headers that will be copied into the data source result if configured
  headers: RestHeaders;
  auth?: RestDataSourceAuthConfiguration;
  // True if the return from the read is expected to be a list of records
  // rather than a single record
  /** @deprecated */
  list: boolean;
  // True if the data returned should be anonymised
  /** @deprecated */
  anonymiseData: boolean;
  // The property in the returned record that should be considered the identifier
  // for the record
  /** @deprecated */
  idProperty: string;
  // Extra headers to be sent in the request
  requestHeaders: DataSourceConfigParam[];
  // The HTTP Method
  method: string;
  // Specific configiuration for GW Data Sources. Details url path params that
  // are not defined as url params
  /** @deprecated */
  substitutionParameters: SubstitutionParameter[];
  // The timeout in seconds to apply to the request. 1-60, default 30.
  timeout: number;
  // If true this data source is used for app auth
  appAuth: boolean;
  appAuthConfig: AppAuthConfig;
  requireAppAuth: boolean;
}

export interface DataSource {
  id?: string;
  name: string;
  config: string;
  schema: string;
  errorSchema?: Array<{
    status: number[] | 'default',
    schema: string,
  }>,
  tenantId: string;
  type: DataSourceType;
  group: string;
  miniAppId?: string;
  dataSourceVersion?: number;
}

export type DataSourceOverWire = Omit<DataSource, 'errorSchema'> & { errorSchema?: string };

export interface DataSourceImportRequest {
  toCreate: Array<DataSource>;
  toUpdate: Array<DataSource>;
}

export interface DataSourceImportResponse {
  created: Array<DataSource>;
  updated: Array<DataSource>;
}

export function findDataSourceTargetAttribute(segments: string[],
  attributes: DataSetAttribute[]): DataSetAttribute | undefined {
  let target: DataSetAttribute | undefined;
  for (const segment of segments) {
    target = attributes.find(a => a.name === segment);
    if (!target || !target.children) {
      return undefined;
    }
    attributes = target.children;
  }

  return target;
}

export function cloneParameters(parameters: Record<string, string>) : Record<string, string> {
  const parametersToReturn: Record<string, string> = {};
  for (const attribute of Object.keys(parameters)) {
    const paramValue = Object.assign({}, parameters[attribute]);
    parametersToReturn[attribute] = paramValue;
  }
  return parametersToReturn;
}

export function cloneDataSourceResult(dataSourceResult: DataSourceResult): DataSourceResult {
  let records: Task[] | undefined;
  const success = dataSourceResult.success;
  const errorMessage = dataSourceResult.errorMessage;
  const page = dataSourceResult.page;
  const totalPageCount = dataSourceResult.totalPageCount;
  const pageSize = dataSourceResult.pageSize;
  const pagedResults = dataSourceResult.pagedResults;
  const date = dataSourceResult.date;
  const parameters: Record<string, string> = cloneParameters(dataSourceResult.parameters);
  const name = dataSourceResult.name;

  if (dataSourceResult.records) {
    records = [];
    for (const record of dataSourceResult.records) {
      records.push(cloneTask(record));
    }
  }

  return {
    records,
    success,
    errorMessage,
    page,
    totalPageCount,
    pageSize,
    pagedResults,
    date,
    parameters,
    name
  };
}

export function convertDataSourceForWire(dataSource: DataSource): DataSourceOverWire {
  let errorSchemaForWire: string | undefined;
  const toCopy: DataSource = { ...dataSource };
  if (dataSource.errorSchema) {
    // If the request can't be serialised then let it fail as otherwise it might delete existing config
    errorSchemaForWire = JSON.stringify(dataSource.errorSchema);
    delete toCopy.errorSchema;
  }

  const forWire: DataSourceOverWire = toCopy as DataSourceOverWire;
  forWire.errorSchema = errorSchemaForWire;
  return forWire;
}

export function convertDataSourceFromWire(dataSource: DataSourceOverWire): DataSource {
  let errorSchema: Array<{ status: number[] | 'default', schema: string }> | undefined;
  const toCopy: DataSourceOverWire = { ...dataSource };
  if (dataSource.errorSchema) {
    // If we can't parse the config then it's probably better to continue with just the success schema than prevent
    // the data source working at all.
    try {
      errorSchema = JSON.parse(dataSource.errorSchema);
    } catch (err) {
      log.error('Failed to read error schema', err);
    }
    delete toCopy.errorSchema;
  }
  const fromWire: DataSource = toCopy as DataSource;
  fromWire.errorSchema = errorSchema;
  return fromWire;
}

// N.B. This is the V1 data source config schema that is used in Galileo. You probably want to edit the V2 version
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DS_CONFIG_SCHEMA: any = {
  $id: 'ds-config',
  $schema: 'ds-config',
  type: 'object',
  required: ['url'],
  additionalProperties: false,
  properties: {
    method: {
      type: 'string',
      pattern: '^(GET|POST|PUT|PATCH|DELETE)$',
      description: 'The type of the request i.e. GET, POST, PUT or DELETE',
    },
    url: {
      type: 'string',
      description: 'The URL to be used for accessing resources, i.e. GET. ' +
        'Use %_% syntax to reference parameters',
    },
    urlParameters: {
      type: 'array',
      description: 'The list of parameters to be passed in the URL',
      items: {
        type: 'object',
        required: ['name'],
        properties: {
          name: {
            type: 'string',
            description: 'The name to be used for the parameter in the URL. ' +
              'This will be used for referencing when fromType and from are omitted',
          },
          allowedValues: {
            type: 'array',
            description: 'A list of valid options for this property',
            items: {
              type: 'string',
            },
          },
          fromType: {
            type: 'string',
            pattern: '^(label|environment)$',
            description: 'The name of the workspace label or environment variable the value should be read from',
          },
          from: {
            type: 'string',
            description: 'The name of parameter, label or variable to retrieve the value from',
          },
          inputLabel: {
            type: 'string',
            description: 'The label to provide as an input in the interface builder',
          },
          options: {
            type: 'object',
            description: 'Other options to be passed to the URL parameter handler',
          },
        },
        additionalProperties: false,
      }
    },
    payload: {
      type: 'object',
      required: ['fields'],
      properties: {
        contentType: {
          type: 'string',
          pattern: '^(application/json)|(application/x-text)|(text/plain)|(application/x-www-form-urlencoded)$',
          description: 'The Content-Type of the request payload'
        },
        fields: {
          type: 'array',
          description: 'The list of data fields to send in the request payload',
          items: {
            type: 'object',
            required: ['name'],
            properties: {
              name: {
                type: 'string',
                description: 'The name of the field in the request payload',
              },
              type: {
                type: 'string',
                pattern: '^(text|number|date|boolean|object|array|unknown)$',
                description: 'The type of data that the field contains'
              },
              fromType: {
                type: 'string',
                pattern: '^(label|environment)$',
                description: 'The name of the workspace label or environment variable the value should be read from',
              },
              from: {
                type: 'string',
                description: 'The name of parameter, label or variable to retrieve the value from',
              },
            },
            additionalProperties: false,
          }
        }
      }
    },
    operations: {
      type: 'object',
      description: 'Operations that can be performed on this endpoint',
      properties: {
        create: {
          type: 'string',
          description: 'The URL to be used for creation of resources, i.e. POST. ' +
            'Use %_% syntax to reference parameters',
        },
        read: {
          type: 'string',
          description: 'The URL to be used for reading of resources, i.e. GET. ' +
            'Use %_% syntax to reference parameters',
        },
        update: {
          type: 'string',
          description: 'The URL to be used for updating of resources, i.e. PUT. ' +
            'Use %_% syntax to reference parameters',
        },
        delete: {
          type: 'string',
          description: 'The URL to be used for deleting of resources, i.e. DELETE. ' +
            'Use %_% syntax to reference parameters',
        },
      },
      additionalProperties: false,
    },
    headers: {
      type: 'object',
      description: 'Description of headers that should be interpreted',
      properties: {
        page: {
          type: 'string',
          description: 'The response header that will contain the page number',
        },
        totalPageCount: {
          type: 'string',
          description: 'The response header that will contain the total number of pages available',
        },
        pageSize: {
          type: 'string',
          description: 'The response header that will contain the page size',
        },
      },
      additionalProperties: false,
    },
    requestHeaders: {
      type: 'array',
      description: 'An optional list of additional headers to send along with the request',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
            description: 'The name of the header'
          },
          fromType: {
            type: 'string',
            pattern: '^(label|environment)$',
            description: 'The name of the workspace label or environment variable the value should be read from',
          },
          from: {
            type: 'string',
            description: 'The name of parameter, label or variable to retrieve the value from',
          },
          value: { type: 'string', description: 'The value to set on the header' },
        }
      }
    },
    auth: {
      type: 'object',
      required: ['type'],
      description: 'Configuration for the authentication on the endpoint',
      properties: {
        // We support GAINWELL_CLAIMS, but we can't expose that through the UI
        // TODO: We should handle the dynamic typing of these, based on the 'type' field
        type: {
          type: 'string',
          description: 'The type of authentication scheme that the endpoint accepts',
          pattern: '^(AUTH_HEADERS|OAUTH|AWS4|BASIC)$',
        },
        requestHeaders: {
          type: 'array',
          description: 'An optional list of additional headers to send along with the request',
          items: {
            type: 'object',
            properties: {
              name: { type: 'string', description: 'The name of the header' },
              value: { type: 'string', description: 'The value to set on the header' },
            }
          }
        },
        params: {
          type: 'object',
          description:
            'Parameters to the authenticator, for AUTH_HEADERS a list of headers including ' +
            'the unique header "bearer" that will be used as a bearer token',
        },
        // OAuth fields
        tokenUrl: { type: 'string', description: 'The URL of the token server in an OAUTH flow' },
        clientId: { type: 'string', description: 'The client ID to provide to the token server in an OAUTH flow' },
        clientSecret: {
          type: 'string',
          description: 'The client secret to provide to the token server in an OAUTH flow',
        },
        headerName: {
          type: 'string',
          description: 'The name of the header to hold the auth token, if not using the \'Authorization\' header'
        },
        headerPrefix: {
          type: 'string',
          description: 'A prefix to prepend to the auth header if necessary. ' +
            'When headerName is omitted, this defaults to \'Bearer\''
        },
        scope: { type: 'string', description: 'The scope to provide to the token server in an OAUTH flow' },
        // Header auth fields
        bearer: {
          type: 'string',
          description: 'The authentication header bearer token for use with the AUTH_HEADERS type',
        },
        // AWS4 auth fields
        accessKey: { type: 'string', description: 'The AWS access key' },
        secretKey: { type: 'string', description: 'The AWS secret key' },
        service: { type: 'string', description: 'The AWS service being accessed' },
        region: { type: 'string', description: 'The AWS region being accessed' },
        // Basic Auth fields
        username: { type: 'string', description: 'The username for use with Basic authentication' },
        password: { type: 'string', description: 'The password for use with Basic authentication' },
      },
      additionalProperties: false,
    },
    required: {
      type: 'array',
      description: 'The list of URL parameters that are required - outside of those in the URL',
      items: {
        type: 'string'
      }
    },
    list: { type: 'boolean', description: 'Indicates whether the endpoint returns a list of items or not' },
    anonymiseData: {
      type: 'boolean',
      description: 'Indicates whether the data returned should be mangled to remove identifiers',
    },
    idProperty: {
      type: 'string',
      description: 'The name a property in the result that can be used to identify records',
    },
  }
};

// See JSON Schema: https://json-schema.org/understanding-json-schema
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DS_CONFIG_SCHEMA_V2: any = {
  $id: 'ds-config-v2',
  $schema: 'ds-config-v2',
  type: 'object',
  required: ['url'],
  additionalProperties: false,
  properties: {
    method: {
      type: 'string',
      enum: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
      description: `The HTTP method to use for the request.
One of GET, POST, PUT, PATCH or DELETE`,
    },
    url: {
      type: 'string',
      description: `The URL to be used for accessing resources.
Use {{_}} syntax to reference parameters in the URL`,
    },
    timeout: {
      type: 'number',
      description: `A timeout, in seconds, to apply to the request.
Min: 1, Max: 60, Default: 30`,
      minimum: 1,
      maximum: 60,
    },
    urlParameters: {
      type: 'array',
      description: `The list of parameters to be used in the URL.
If a parameter isn't referenced with a placeholder in the URL then it will be included as a query parameter`,
      items: {
        type: 'object',
        required: ['name'],
        properties: {
          name: {
            type: 'string',
            description: 'The name to be used for the parameter in the URL',
          },
          addToQueryString: {
            type: 'string',
            description: `Determines the behaviour of adding the URL parameter to the query string as a query parameter.

AUTO
----
The parameter will be used primarily for token replacement. If no token is present with that name then it will be ` +
'appended to the URL as a query parameter if the value is not empty. If the parameter is explicitly referenced in ' +
'the token replacement syntax then this is the equivalent of NEVER. If the parameter is not explicitly referenced, ' +
`then this is the equivalent of NON_EMPTY.

NEVER
-----
The parameter will be used solely for token replacement and not automatically included as an additional query ` +
`parameter.

NON_NULL
--------
The parameter will be used for token replacement plus also automatically included as an additional query parameter ` +
'as long as a non-null value is provided for the parameter. Note that the token replacement will fail if it is ' +
`referenced and a null value is provided.

NON_EMPTY
---------
The parameter will be used for token replacement plus also automatically included as an additional query  parameter ` +
`as long as a value of at least one character in length is provided for the parameter.

ALWAYS
------
The parameter will be used for token replacement plus also automatically included as an additional query parameter.

The default value is 'AUTO'`,
            enum: ['AUTO', 'NEVER', 'NON_NULL', 'NON_EMPTY', 'ALWAYS'],
          },
          allowedValues: {
            type: 'array',
            description: 'A list of valid options for this property',
            items: {
              type: 'string',
            },
          },
        },
        additionalProperties: false,
      }
    },
    // TODO: Would be good to make this conditional on the method, but monaco doesn't appear to support that type of
    // conditional yet: https://json-schema.org/understanding-json-schema/reference/conditionals
    payload: {
      type: 'object',
      description: `The payload to send in the request.
This only applies to PUT, POST and PATCH requests`,
      required: ['fields'],
      properties: {
        contentType: {
          type: 'string',
          enum: ['application/json', 'application/x-text', 'text/plain', 'application/x-www-form-urlencoded'],
          description: 'The Content-Type of the request payload'
        },
        fields: {
          type: 'array',
          description: 'The list of data fields to send in the request payload',
          items: {
            type: 'object',
            required: ['name'],
            properties: {
              name: {
                type: 'string',
                description: 'The name of the field in the request payload',
              },
              type: {
                type: 'string',
                enum: ['text', 'number', 'date', 'boolean', 'object', 'array', 'unknown'],
                description: 'The type of data that the field contains'
              },
            },
            additionalProperties: false,
          }
        }
      }
    },
    requestHeaders: {
      type: 'array',
      description: 'An optional list of additional headers to send along with the request',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
            description: 'The name of the header'
          },
          value: {
            type: 'string',
            description: 'The value to set on the header, if it isn\'t determined by the parameters',
          },
        }
      }
    },
    auth: {
      type: 'object',
      required: ['type'],
      description: 'Configuration for the authentication on the endpoint',
      properties: {
        // TODO: We should handle the dynamic typing of these, based on the 'type' field
        // Monaco doesn't appear to support conditionals yet:
        // https://json-schema.org/understanding-json-schema/reference/conditionals
        type: {
          type: 'string',
          description: 'The type of authentication scheme that the endpoint accepts',
          enum: ['AUTH_HEADERS', 'OAUTH', 'AWS4', 'BASIC'],
        },
        requestHeaders: {
          type: 'array',
          description: 'An optional list of additional headers to send along with the request',
          items: {
            type: 'object',
            properties: {
              name: { type: 'string', description: 'The name of the header' },
              value: { type: 'string', description: 'The value to set on the header' },
            }
          }
        },
        // OAuth fields
        tokenUrl: { type: 'string', description: 'The URL of the token server in an OAUTH flow' },
        clientId: { type: 'string', description: 'The client ID to provide to the token server in an OAUTH flow' },
        clientSecret: {
          type: 'string',
          description: 'The client secret to provide to the token server in an OAUTH flow',
        },
        headerName: {
          type: 'string',
          description: 'The name of the header to hold the auth token, if not using the \'Authorization\' header'
        },
        headerPrefix: {
          type: 'string',
          description: `A prefix to prepend to the auth header if necessary.
When headerName is omitted, this defaults to 'Bearer '`,
        },
        scope: { type: 'string', description: 'The scope to provide to the token server in an OAUTH flow' },
        // Header auth fields
        bearer: {
          type: 'string',
          description: 'The authentication header bearer token for use with the AUTH_HEADERS type',
        },
        // AWS4 auth fields
        accessKey: { type: 'string', description: 'The AWS access key' },
        secretKey: { type: 'string', description: 'The AWS secret key' },
        service: { type: 'string', description: 'The AWS service being accessed' },
        region: { type: 'string', description: 'The AWS region being accessed' },
        // Basic Auth fields
        username: { type: 'string', description: 'The username for use with Basic authentication' },
        password: { type: 'string', description: 'The password for use with Basic authentication' },
      },
      additionalProperties: false,
    },
    cache: {
      type: 'object',
      description: 'Optional configuration to cache the response to GET requests for a duration of time',
      properties: {
        type: { type: 'string', enum: ['GLOBAL', 'PER_WORKSPACE'] },
        expirySeconds: {
          type: 'number',
          description: `The number of seconds to hold the response in the cache.
Min: 60 (1 minute), Max: 86400 (1 day), Default: 3600 (1 hour)`,
          minimum: 60,
          maximum: 86400,
        },
        ignoreEmptyResponse: {
          type: 'boolean',
          description: `Indicates whether empty responses shoud be cached or ignored.
If true then an empty response, empty object response or empty array response won't be cached.
Default: false`
        },
      },
    },
    appAuth: {
      type: 'boolean',
      description: 'If this data source is used for app-level authentication.',
    },
    requireAppAuth: {
      type: 'boolean',
      description: 'An optional boolean to say if a valid app auth session is required in order to evaluate this ' +
        'data source. Default: false',
    },
    appAuthConfig: {
      type: 'object',
      properties: {
        tokenPath: {
          type: 'string',
          description: 'The path in the auth response object where the auth token is. Empty means the whole response ' +
            'is the token. Note the token will be removed from the data source response data.',
        },
        expiryPath: {
          type: 'string',
          description: 'The path in the auth response object where the expiry time is.',
        },
        defaultTokenExpiryMillis: {
          type: 'number',
          description: 'The default value for the number of milliseconds in the future the token issued from a ' +
            'successful auth request should be valid for. This is used if an expiry cannot be found using ' +
            'the expiryPath or if this default is shorter than the time calculated from the expiryPath.',
        },
        sso: {
          type: 'boolean',
          description: 'Indicates if this app auth datasource should be triggered as an single sign on (SSO). ' +
            'If this is true then the `ssoUrlParameter` and  `ssoOutputAttribute` values need to be set too.',
        },
        tokenRefresh: {
          type: 'boolean',
          description: 'Indicates if this app auth datasource is used for refreshing tokens.',
        },
        refreshTokenPath: {
          type: 'string',
          description: 'The path in the auth response object where the refresh token is. Empty means the whole ' +
            'response is the token. Note the refresh token will be removed from the data source response data.',
        },
        refreshTokenOutput: {
          type: 'string',
          description: 'The name of the parameter in the request where the refresh token will be set. This must be a ' +
            'payload parameter.',
        },
        ssoUrlParameter: {
          type: 'string',
          description: 'The name of the URL parameter where the SSO token will be passed.',
        },
        ssoOutputAttribute: {
          type: 'string',
          description: 'The name of the parameter in the request where the SSO token will be set. This can be a ' +
            'URL parameter or a payload parameter.',
        },
      },
    },
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DS_ATTRIBUTES_SCHEMA: any = {
  $id: 'ds-schema',
  $schema: 'ds-schema',
  type: 'object',
  definitions: {
    AttributeDefinition: {
      type: 'object',
      required: ['name', 'type'],
      additionalProperties: false,
      properties: {
        name: {
          type: 'string',
          description: 'The label to display in the interface builder for this attribute'
        },
        type: { type: 'string', pattern: '^(text|number|object)$' },
        children: {
          type: 'array',
          items: {
            $ref: '#/definitions/AttributeDefinition'
          },
          description: 'Attributes in the object',
        },
        arrayLevels: {
          type: 'number',
          description: 'Specifies whether this is an array, and if so, whether it has any nested sub-arrays'
        }
      }
    }
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string'
    },
    children: {
      type: 'array',
      items: {
        $ref: '#/definitions/AttributeDefinition'
      }
    },
    arrayLevels: {
      type: 'integer'
    }
  },
  required: ['type', 'children']
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DS_ATTRIBUTES_SCHEMA_LEGACY: any = {
  $id: 'ds-schema-legacy',
  $schema: 'ds-schema-legacy',
  type: 'array',
  definitions: {
    AttributeDefinition: {
      type: 'object',
      required: ['name', 'type'],
      additionalProperties: false,
      properties: {
        name: {
          type: 'string',
          description: 'The label to display in the interface builder for this attribute',
        },
        type: { type: 'string', pattern: '^(text|number|object|array)$' },
        children: {
          type: 'array',
          items: {
            $ref: '#/definitions/AttributeDefinition'
          },
          description: 'Attributes in the object',
        },
        arrayLevels: {
          type: 'number',
          description: 'Specifies whether this is an array, and if so, whether it has any nested sub-arrays'
        }
      }
    }
  },
  items: {
    $ref: '#/definitions/AttributeDefinition'
  }
};
