import { initialiseName } from '@/data/helpers/InitialsHelper';
import { removeDiacritics } from '@/data/helpers/SearchHelper';

import { LimitedUserDetails } from '../datatypes/UserDetails';
import { ExtraAuthContext } from '../datatypes/UserToken';

function sortByDisplayName(a?: LimitedUserDetails, b?: LimitedUserDetails): number {
  const aName = removeDiacritics(a?.displayName || '').toLowerCase();
  const bName = removeDiacritics(b?.displayName || '').toLowerCase();
  if (aName < bName) {
    return -1;
  } else if (aName > bName) {
    return 1;
  } else {
    return 0;
  }
}

function extractInitialsFromEmail(text: string): string {
  // Shouldn't be used for display names
  const name: string = text.split('@')[0];
  return initialiseName(name);
}

/**
 * Automatically simplify and normalise the attribute names of extra context
 * passed by systems like SAML into something that can easily be expressed in
 * a mustache.
 *
 * => These are often URLs, so take the last part (after the last / symbol)
 *    and then leave any A-Z, a-z, 0-9 characters as well as the - and _ symbols.
 *    But remove anything else...
 */
function normaliseAttributeName(name: string): string | null {
  const segments = name.split('/').filter(element => (element !== null && element.length > 0));
  const regex = /[^a-zA-Z0-9_-]/g;
  if (segments.length <= 0) {
    // String was only made of '/' so all parts (which were the empty string) have been filtered out...
    return null;
  }
  const result = segments[segments.length - 1].replaceAll(regex, '');
  if (result.length === 0) {
    return null;
  }
  return result;
}

/**
 * Normalise the extra auth context (SAML assertions for example) by keeping the value part
 * but changing the key to something that can easily be expressed as a mustache.
 *
 * If the simplification leads to conflicts then the corresponding entry is removed altogether.
 *
 * Long term apps would define which auth provider is allowed and how the extra attributes
 * would be mapped to mustaches... Or at least would be able to define overrides when
 * there are conflicts... But for now we just made it automatic.
 */
function normaliseExtraAuthContext(extraAuthContext?: ExtraAuthContext): ExtraAuthContext {
  if (!extraAuthContext) {
    return {};
  }
  const result: ExtraAuthContext = {};
  const conflictingAttributes: string[] = [];

  for (const key in extraAuthContext) {
    const shortKey = normaliseAttributeName(key);
    if ((shortKey === null)) {
      continue;
    }
    const conflicts = result[shortKey] !== undefined;
    if (conflicts) {
      conflictingAttributes.push(shortKey);
    } else {
      result[shortKey] = extraAuthContext[key];
    }
  }

  for (const conflictingKey of conflictingAttributes) {
    delete result[conflictingKey];
  }

  return result;
}

export {
  extractInitialsFromEmail,
  normaliseExtraAuthContext,
  sortByDisplayName,
};
