import log from 'loglevel';

import { AppAuthResult } from '@/data/config/DataSource';
import { Track } from '@/data/datatypes/Track';
import DataWorker from '@/data/storage/DataWorker';
import {
  getAppAuthTokenFromStorage, putAppAuthTokenInStorage,
  removeAppAuthTokenFromStorage
} from '@/data/tasks/customviews/AppAuthTokenStorage';
import { CustomViewUIConfig, Page } from '@/data/tasks/customviews/CustomViewUIConfig';
import { isNullish } from '@/util/util';

interface AppAuthToken {
  success: boolean;
  tokenId?: string;
  expiry?: number;
  created?: number;
}

const appAuthPageTargets: Record<string, string> = {};

async function isValidAppAuthTokenPresent(memoryOnly: boolean, appId: string, environmentId: string,
  trackId?: string): Promise<boolean> {
  const existingAppAuthToken = getAppAuthTokenFromStorage(memoryOnly,
    getAppAuthTokenLocalStorageKey(appId, environmentId, trackId));
  if (existingAppAuthToken) {
    try {
      const appAuthResult = JSON.parse(existingAppAuthToken) as AppAuthResult;
      if (appAuthResult && appAuthResult.success && appAuthResult.expiry) {
        const adjustedLocalTime = await getAdjustedLocalTime();
        if (adjustedLocalTime <= appAuthResult.expiry) {
          return true;
        } else {
          removeAppAuthTokenFromStorage(memoryOnly, getAppAuthTokenLocalStorageKey(appId, environmentId, trackId));
        }
      }
    } catch (error) {
      log.error('Failed to parse app auth token to check validity');
      log.error(error);
    }
  }
  return false;
}

async function getAppAuthTokenExpiry(memoryOnly: boolean, appId: string, environmentId: string,
  trackIdToUse: string | undefined):
  Promise<number> {
  const validAppAuthPresent = await isValidAppAuthTokenPresent(memoryOnly, appId, environmentId, trackIdToUse);
  if (validAppAuthPresent) {
    const token = getAppAuthToken(memoryOnly, appId, environmentId, trackIdToUse);
    if (token && token.expiry) {
      return token.expiry;
    }
  }
  return 0;
}

async function getAdjustedLocalTime(): Promise<number> {
  let clientServerTimeOffsetMillis = 0;
  const workerClientServerOffsetMillis =
    await DataWorker.instance().dispatch('OnlineStatus/getClientServerTimeOffset');
  if (workerClientServerOffsetMillis && Number(workerClientServerOffsetMillis)) {
    clientServerTimeOffsetMillis = Number(workerClientServerOffsetMillis);
  }
  return new Date().getTime() - clientServerTimeOffsetMillis;
}

function getAppAuthToken(memoryOnly: boolean, appId: string, environmentId: string, trackId?: string):
  AppAuthToken | undefined {
  const existingAppAuthToken = getAppAuthTokenFromStorage(memoryOnly,
    getAppAuthTokenLocalStorageKey(appId, environmentId, trackId));
  if (existingAppAuthToken) {
    try {
      const token: AppAuthToken = JSON.parse(existingAppAuthToken);
      if (token) {
        return token;
      }
    } catch (error) {
      log.error('Failed to parse app auth token');
      log.error(error);
    }
  }
}

function getAppAuthTokenId(memoryOnly: boolean, appId: string, environmentId: string, trackId?: string):
  string | undefined {
  const existingAppAuthToken = getAppAuthTokenFromStorage(memoryOnly,
    getAppAuthTokenLocalStorageKey(appId, environmentId, trackId));
  if (existingAppAuthToken) {
    try {
      const token: AppAuthToken = JSON.parse(existingAppAuthToken);
      if (token && token.tokenId) {
        return token.tokenId;
      }
    } catch (error) {
      log.error('Failed to parse app auth token');
      log.error(error);
    }
  }
}

function isPageExcludedFromAuth(page: Page): boolean {
  const config = page.appAuthPageConfig;
  if (config) {
    return config.excludedFromAuth;
  }
  return false;
}

function getLoginPageIndex(uiModel: CustomViewUIConfig): number | undefined {
  if (uiModel) {
    for (let i = 0; i < uiModel.pages.length; i++) {
      if (uiModel.pages[i].pageType === 'login') {
        return i;
      }
    }
  }
}

function getPageIndexFromId(uiModel: CustomViewUIConfig, id: string): number | undefined {
  if (uiModel) {
    for (let i = 0; i < uiModel.pages.length; i++) {
      if (uiModel.pages[i].id === id) {
        return i;
      }
    }
  }
}

function getAppAuthTokenLocalStorageKey(appId: string, environmentId: string, trackId?: string): string {
  if (trackId) {
    return `appAuthToken-${appId}-${environmentId}-${trackId}`;
  }
  return `appAuthToken-${appId}-${environmentId}`;
}

function getAppAuthTargetPageIdKey(appId: string, environmentId: string, trackId?: string): string {
  if (trackId) {
    return `${appId}-${environmentId}-${trackId}`;
  }
  return `${appId}-${environmentId}`;
}

function storeAppAuthTargetPageId(appId: string, id: string, environmentId: string, trackId?: string) {
  appAuthPageTargets[getAppAuthTargetPageIdKey(appId, environmentId, trackId)] = id;
}

function getAppAuthTargetPageId(appId: string, environmentId: string, trackId?: string) : string | null {
  const storedVal = appAuthPageTargets[getAppAuthTargetPageIdKey(appId, environmentId, trackId)];
  return storedVal || null;
}

function removeAppAuthTargetPageId(appId: string, environmentId: string, trackId?: string) {
  delete appAuthPageTargets[getAppAuthTargetPageIdKey(appId, environmentId, trackId)];
}

function removeAppAuthToken(memoryOnly: boolean, appId: string, environmentId: string, trackId?: string): void {
  removeAppAuthTokenFromStorage(memoryOnly, getAppAuthTokenLocalStorageKey(appId, environmentId, trackId));
}

function storeAppAuthToken(memoryOnly: boolean, appId: string, environmentId: string, trackId: string,
  appAuthResult: AppAuthResult): void {
  const trackIdToUse = appAuthResult.trackLevel ? trackId : undefined;
  putAppAuthTokenInStorage(memoryOnly, getAppAuthTokenLocalStorageKey(appId, environmentId, trackIdToUse),
    JSON.stringify(appAuthResult));
}

function registerAppActivity(appId: string, trackId?: string): void {
  localStorage.setItem(getAppActivityLocalStorageKey(appId, trackId), new Date().getTime().toString());
}

function getAppActivityLocalStorageKey(appId: string, trackId?: string): string {
  if (isNullish(trackId)) {
    return `lastAppActivity-${appId}`;
  } else {
    return `lastAppActivity-${appId}-${trackId}`;
  }
}

function getLastAppActivityTime(appId: string, trackId?: string): number {
  const lastActivity = localStorage.getItem(getAppActivityLocalStorageKey(appId, trackId));
  if (isNullish(lastActivity)) {
    return 0;
  }
  return Number.parseInt(lastActivity) ?? 0;
}

function getEnvironmentIdToUseForAppAuth(track: Track) : string {
  if (track && track.environmentId) {
    return track.environmentId;
  }
  return 'draft';
}

export {
  getAdjustedLocalTime,
  getAppAuthTargetPageId,
  getAppAuthToken,
  getAppAuthTokenExpiry,
  getAppAuthTokenId,
  getEnvironmentIdToUseForAppAuth,
  getLastAppActivityTime,
  getLoginPageIndex,
  getPageIndexFromId,
  isPageExcludedFromAuth,
  isValidAppAuthTokenPresent,
  registerAppActivity,
  removeAppAuthTargetPageId,
  removeAppAuthToken,
  storeAppAuthTargetPageId,
  storeAppAuthToken,
};
